/* eslint-disable no-unused-vars */
import {
  firebase,
  auth,
  db,
  createUserWithEmailAndPassword,
} from '../@crema/services/auth/firebase/firebase';

import {useFirestoreConnect} from 'react-redux-firebase';
import axios from 'axios';
import {getAuth} from 'firebase/auth';

const COLLECTION = {
  USERS: 'users',
};

const usersRef = db.collection(COLLECTION.USERS);

export const usersCollection = {
  getStaffDataAndListener: () => {
    useFirestoreConnect([
      {
        collection: COLLECTION.USERS,
        storeAs: 'staffs',
        where: ['userType', 'in', ['SUPERADMIN', 'ADMIN']],
      },
    ]);
  },
  onGetStaffList: (type, name, currentPage) => {
    if (name == 'all') {
      useFirestoreConnect([
        {
          collection: COLLECTION.USERS,
          storeAs: 'staffs',
          where: ['userType', 'in', ['SUPERADMIN', 'ADMIN']],
        },
      ]);
    } else {
      useFirestoreConnect([
        {
          collection: COLLECTION.USERS,
          storeAs: 'staffs',
          where: ['userType', '==', name.toUpperCase()],
        },
      ]);
    }
  },
  deleteStaff: async (uid) => {
    // try {
    //   await usersRef.doc(uid).delete();
    // } catch (e) {
    //   throw new Error('Error to delete the staff.');
    // }
    const baseURL =
      process.env.REACT_APP_firebase_function_endpoint + '/bspApi/delete-staff';
    const auth = await getAuth();
    if (!auth.currentUser.accessToken) {
      throw new Error('Invalid user accessToken');
    }
    const requestData = {
      uid: uid,
    };

    const options = {
      headers: {
        Authorization: 'Bearer ' + auth.currentUser.accessToken,
        'Content-Type': 'application/json',
      },
    };

    return await axios.post(baseURL, requestData, options);
  },
  addStaff: async (userData) => {
    const baseURL =
      process.env.REACT_APP_firebase_function_endpoint + '/bspApi/create-staff';
    const auth = await getAuth();
    if (!auth.currentUser.accessToken) {
      throw new Error('Invalid user accessToken');
    }

    const requestData = {
      user: userData,
    };

    const options = {
      headers: {
        Authorization: 'Bearer ' + auth.currentUser.accessToken,
        'Content-Type': 'application/json',
      },
    };

    return await axios.post(baseURL, requestData, options);
  },
  updateStaff: async (uid, updateData) => {
    await usersRef.doc(uid).update({
      firstName: updateData.firstName,
      lastName: updateData.lastName,
      phoneNumber: updateData.phoneNumber,
      initials:
        updateData.firstName[0].toUpperCase() +
        updateData.lastName[0].toUpperCase(),
      userType: updateData.userType,
    });
  },
  updateUserBillingDetails: async (uid, billingDetails) => {
    await usersRef.doc(uid).update({
      billingDetails: billingDetails,
    });
  },
  updateUserProfile: async (uid, updateData, old) => {
    try {
      await usersRef.doc(uid).update({
        firstName: updateData.firstName,
        lastName: updateData.lastName,
        displayName: updateData.displayName,
        gender: updateData.gender,
        grade: updateData.grade,
        phoneNumber: updateData.phoneNumber,
      });

      const historyData = {
        discription: 'Update profile',
        oldProfile: {
          firstName: updateData.firstName,
          lastName: updateData.lastName,
          displayName: updateData.displayName,
          gender: updateData.gender,
          grade: updateData.grade,
          phoneNumber: updateData.phoneNumber,
        },
        createdAt: new Date(),
      };

      await usersRef.doc(uid).collection('history').add(historyData);
    } catch (e) {
      return new Error('Error: Update profile');
    }
  },
  updateUserReferralEmail: async (uid, updateData, old) => {
    const userEmail = await usersRef
      .doc(uid)
      .get()
      .then((user) => {
        const userInfo = user.data();
        return userInfo.email ? userInfo.email : ``;
      });

    if (updateData.referralEmail === userEmail)
      return new Error(`Recommender email cannot input your email.`);

    try {
      await usersRef.doc(uid).update({
        referralEmail: updateData.referralEmail,
      });

      const historyData = {
        discription: 'Update Referral Email',
        oldReferralEmail: {
          referralEmail: updateData.referralEmail,
        },
        createdAt: new Date(),
      };

      await usersRef.doc(uid).collection('history').add(historyData);
    } catch (e) {
      return new Error('Error: Update profile');
    }

    return `Success to update your profile.`;
  },
  updateUserIP: async () => {
    const auth = await getAuth();

    let userInfo = (await usersRef.doc(auth.currentUser.uid).get()).data();

    if (userInfo && !userInfo.ip) {
      const responseData = await fetch('https://ipapi.co/json/');
      const ipData = await responseData.json();
      userInfo.ip = ipData.ip;
      userInfo.country = ipData.country;

      await usersRef.doc(auth.currentUser.uid).set(userInfo);
    }
  },
};
