import React from 'react';
// import ListItemAvatar from '@mui/material/ListItemAvatar';
import PropTypes from 'prop-types';
import {Box, ListItem, Typography} from '@mui/material';
import {Fonts} from 'shared/constants/AppEnums';
import {moneyFormat} from '../../../util/common';

const DiscountSum = (props) => {
  const {discount} = props;

  return (
    <ListItem
      sx={{
        padding: '8px 20px',
        display: 'flex',
        justifyContent: 'start-end',
      }}
      className='item-hover'
    >
      {/* <Box
        sx={{
          width: '70%',
          fontSize: 14,
          color: (theme) => theme.palette.text.secondary,
          display: 'flex', justifyContent: 'space-between',
        }}
      >
        <Typography>
          <Box
            component='span'
            sx={{
              fontSize: 16,
              fontWeight: Fonts.MEDIUM,
              mb: 0.5,
              color: (theme) => theme.palette.text.primary,
              mr: 1,
              display: 'inline-block',
            }}
          >
            Discount
          </Box>
        </Typography>
        <Box
          sx={{
            fontSize: 16,
            fontWeight: Fonts.MEDIUM,
          }}
        >
          {moneyFormat(Number(discount))}
        </Box>
      </Box> */}

      <Box
        sx={{
          pl: 5,
          pr: 5,
          width: '100%',
          fontSize: 14,
          color: (theme) => theme.palette.text.secondary,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          component='span'
          sx={{
            fontSize: 16,
            fontWeight: Fonts.MEDIUM,
            mb: 0.5,
            color: (theme) => theme.palette.text.primary,
            mr: 1,
            display: 'inline-block',
          }}
        >
          <Typography component='h3' variant='h3'>
            Additional discount
          </Typography>
        </Box>
        <Box
          sx={{
            fontSize: 16,
            fontWeight: Fonts.MEDIUM,
          }}
        >
          <Typography component='h3' variant='h3'>
            {moneyFormat(discount)}
          </Typography>
        </Box>
      </Box>
    </ListItem>
  );
};

export default DiscountSum;

DiscountSum.propTypes = {
  discount: PropTypes.number,
};
