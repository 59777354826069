export const moneyFormat = (value) => {
  if (value == 0) {
    return `$ 0.00`;
  } else if (value == undefined) {
    return `$ 0.00`;
  } else {
    return `$ ${value.toFixed(2)}`;
  }
};

export const moneyFormat2 = (value) => {
  if (value == 0) {
    return `$ 0.00`;
  } else if (value == undefined) {
    return `$ 0.00`;
  } else {
    const result = value * 0.001;
    return `$${result.toFixed(2)}`;
  }
};

export const moneyFormat3 = (value) => {
  if (value == 0) {
    return `$ 0.00`;
  } else if (value == undefined) {
    return `$ 0.00`;
  } else {
    return `$${parseFloat(value).toFixed(2)}`;
  }
};
