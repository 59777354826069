import React from 'react';
import {useThemeContext} from '../../../../utility/AppContextProvider/ThemeContextProvider';
import {Box} from '@mui/material';
// import Logo from '../../../../../assets/images/BSP-logo-red.png';
import {ReactComponent as Logo} from '../../../../../assets/images/BSP_Logo_navy.svg';
import Link from '@mui/material/Link';

const AppLogo = () => {
  const {theme} = useThemeContext();

  return (
    <Box
      sx={{
        height: {xs: 56, sm: 70},
        padding: 2.5,
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
          height: {xs: 40, sm: 45},
        },
      }}
      className='app-logo'
    >
      {/* <img src={Logo} style={{height: '77px'}} /> */}
      <Link href='/' underline='none'>
        <Logo fill={theme.palette.primary.main} />
      </Link>
    </Box>
  );
};

export default AppLogo;
