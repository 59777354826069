/* eslint-disable no-unused-vars */
import React from 'react';
import {RoutePermittedRole} from 'shared/constants/AppConst';

const Home = React.lazy(() => import('./Home'));
const MyOrders = React.lazy(() => import('./MyOrders'));
const Products = React.lazy(() => import('./Products'));
const Tests = React.lazy(() => import('./Tests'));
const Instruction = React.lazy(() => import('./Tests/Instruction'));
const Analysis = React.lazy(() => import('./Tests/Analysis'));
const Checkout = React.lazy(() => import('./Checkout'));
const OrderComplete = React.lazy(() => import('./Checkout/OrderComplete'));
const Practices = React.lazy(() => import('../student/Practice'));

export const memberConfig = [
  {
    permittedRole: [
      RoutePermittedRole.MEMBER,
      RoutePermittedRole.ADMIN,
      RoutePermittedRole.SUPERADMIN,
    ],
    path: '/home',
    element: <Home />,
  },
  {
    permittedRole: [
      RoutePermittedRole.MEMBER,
      RoutePermittedRole.ADMIN,
      RoutePermittedRole.SUPERADMIN,
    ],
    path: '/my-orders',
    element: <MyOrders />,
  },
  {
    permittedRole: [
      RoutePermittedRole.MEMBER,
      RoutePermittedRole.ADMIN,
      RoutePermittedRole.SUPERADMIN,
    ],
    path: '/products',
    element: <Products />,
  },
  {
    permittedRole: [
      RoutePermittedRole.MEMBER,
      RoutePermittedRole.ADMIN,
      RoutePermittedRole.SUPERADMIN,
    ],
    path: '/tests',
    element: <Tests />,
  },
  {
    permittedRole: [
      RoutePermittedRole.MEMBER,
      RoutePermittedRole.ADMIN,
      RoutePermittedRole.SUPERADMIN,
    ],
    path: '/instruction',
    element: <Instruction />,
  },
  {
    permittedRole: [
      RoutePermittedRole.MEMBER,
      RoutePermittedRole.ADMIN,
      RoutePermittedRole.SUPERADMIN,
    ],
    path: '/checkout',
    element: <Checkout />,
  },
  {
    permittedRole: [
      RoutePermittedRole.MEMBER,
      RoutePermittedRole.ADMIN,
      RoutePermittedRole.SUPERADMIN,
    ],
    path: '/checkout/order-complete',
    element: <OrderComplete />,
  },
  {
    permittedRole: [
      RoutePermittedRole.MEMBER,
      RoutePermittedRole.ADMIN,
      RoutePermittedRole.SUPERADMIN,
    ],
    path: '/analysis',
    element: <Analysis />,
  },
  {
    permittedRole: [
      RoutePermittedRole.MEMBER,
      RoutePermittedRole.ADMIN,
      RoutePermittedRole.SUPERADMIN,
    ],
    path: '/practices',
    element: <Practices />,
  },
];
