export const QuestionCorrectLetter = [
  {key: 'A', value: 'A', text: 'A'},
  {key: 'B', value: 'B', text: 'B'},
  {key: 'C', value: 'C', text: 'C'},
  {key: 'D', value: 'D', text: 'D'},
  {key: 'E', value: 'E', text: 'E'},
];

export const QuestionDifficulties = [
  {key: 'Easy', value: 'Easy', text: 'Easy'},
  {key: 'Medium', value: 'Medium', text: 'Medium'},
  {key: 'Hard', value: 'Hard', text: 'Hard'},
];

export const QuestionLevels = [
  {key: 'Upper', value: 'Upper', text: 'Upper'},
  {key: 'Middle', value: 'Middle', text: 'Middle'},
  {key: 'Lower', value: 'Lower', text: 'Lower'},
];

export const QuestionSections = [
  {key: 'Quantitative #1', value: 'Quantitative #1', text: 'Quantitative #1'},
  {key: 'Quantitative #2', value: 'Quantitative #2', text: 'Quantitative #2'},
  {key: 'Reading', value: 'Reading', text: 'Reading'},
  {key: 'Verbal', value: 'Verbal', text: 'Verbal'},
];

export const QuestionPassageTypes = [
  {key: 'Information', value: 'Information', text: 'Information'},
  {key: 'Narrative', value: 'Narrative', text: 'Narrative'},
  {key: 'Poetry', value: 'Poetry', text: 'Poetry'},
  {key: 'Essay', value: 'Essay', text: 'Essay'},
];

export const QuestionTopics = [
  {
    key: 'Algebra',
    value: 'Algebra',
    text: 'Algebra',
    section: 'Quantitative #1',
  },
  {
    key: 'Geometry',
    value: 'Geometry',
    text: 'Geometry',
    section: 'Quantitative #1',
  },
  {
    key: 'Logical Thinking',
    value: 'Logical Thinking',
    text: 'Logical Thinking',
    section: 'Quantitative #1',
  },
  {
    key: 'Algebra',
    value: 'Algebra',
    text: 'Algebra',
    section: 'Quantitative #2',
  },
  {
    key: 'Data Analysis',
    value: 'Data Analysis',
    text: 'Data Analysis',
    section: 'Quantitative #2',
  },
  {
    key: 'Define Category',
    value: 'Define Category',
    text: 'Define Category',
    section: 'Quantitative #2',
  },
  {
    key: 'Geometry',
    value: 'Geometry',
    text: 'Geometry',
    section: 'Quantitative #2',
  },
  {
    key: 'Number Concepts',
    value: 'Number Concepts',
    text: 'Number Concepts',
    section: 'Quantitative #2',
  },
  {
    key: 'Animal',
    value: 'Animal',
    text: 'Animal',
    section: 'Reading',
  },
  {
    key: 'Art/Archaeology',
    value: 'Art/Archaeology',
    text: 'Art/Archaeology',
    section: 'Reading',
  },
  {
    key: 'Biology',
    value: 'Biology',
    text: 'Biology',
    section: 'Reading',
  },
  {
    key: 'Education',
    value: 'Education',
    text: 'Education',
    section: 'Reading',
  },
  {
    key: 'Fantasy',
    value: 'Fantasy',
    text: 'Fantasy',
    section: 'Reading',
  },
  {
    key: 'Geography',
    value: 'Geography',
    text: 'Geography',
    section: 'Reading',
  },
  {
    key: 'Literature',
    value: 'Literature',
    text: 'Literature',
    section: 'Reading',
  },
  {
    key: 'Science',
    value: 'Science',
    text: 'Science',
    section: 'Reading',
  },
];

export const QuestionSubTopics = [
  {
    key: 'Statistics',
    value: 'Statistics',
    text: 'Statistics',
    topic: 'Algebra',
  },
  {
    key: 'Short Story',
    value: 'Short Story',
    text: 'Short Story',
    topic: 'Literature',
  },
  {
    key: 'Choice/Life',
    value: 'Choice/Life',
    text: 'Choice/Life',
    topic: 'Literature',
  },
];

export const QuestionTypes = [
  {
    key: 'Main Idea',
    value: 'Main Idea',
    text: 'Main Idea',
  },
  {
    key: 'Vocaburary',
    value: 'Vocaburary',
    text: 'Vocaburary',
  },
  {
    key: 'Inference',
    value: 'Inference',
    text: 'Inference',
  },
];

export const QUANTITATIVE1 = 'Quantitative #1';
export const QUANTITATIVE2 = 'Quantitative #2';
export const READING = 'Reading';
export const VERBAL = 'Verbal';
export const WRITING = 'Writing';
export const OVERVIEW = 'Overview';
