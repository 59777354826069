import axios from 'axios';
import {getAuth} from 'firebase/auth';

/**
 * @param {*} messageData
 * from: "no-reply <no-reply@bestssatprep.com>",
 * to: "support@bestssatprep.com",
 * subject: "BEST SSAT PREP",
 * text: "Testing Email from BEST SSAT PREP!",
 */
export const sendEmailHandler = async (messageData) => {
  const baseURL =
    process.env.REACT_APP_firebase_function_endpoint + '/bspApi/send-email';
  const auth = await getAuth();
  if (!auth.currentUser.accessToken) {
    throw new Error('Invalid user accessToken');
  }

  const options = {
    headers: {
      Authorization: 'Bearer ' + auth.currentUser.accessToken,
      'Content-Type': 'application/json',
    },
  };

  return await axios.post(baseURL, messageData, options);
};
