import React from 'react';
import {Navigate} from 'react-router-dom';
import {RoutePermittedRole} from 'shared/constants/AppConst';

import Account from './MyProfile';

const Staff = React.lazy(() => import('./Staff'));

export const accountPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.MEMBER,
    path: '/my-profile',
    element: <Account />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: ['/account/staff/:name'],
    element: <Staff />,
  },
  {
    path: '/account/staff',
    element: <Navigate to='/account/staff/all' />,
  },
];
