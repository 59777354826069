/* eslint-disable no-unused-vars */
/*eslint no-unused-vars: "error"*/
import {BiAlignLeft} from 'react-icons/bi';
import {HiOutlineAcademicCap, HiOutlineChartSquareBar} from 'react-icons/hi';
import {FiMap, FiUsers} from 'react-icons/fi';
import {
  MdOutlineAnalytics,
  MdOutlineContactPhone,
  MdOutlineContactSupport,
  MdOutlineDns,
  MdOutlineManageAccounts,
} from 'react-icons/md';

import {
  BsBriefcase,
  BsCart4,
  BsChatDots,
  BsCurrencyBitcoin,
  BsQuestionDiamond,
} from 'react-icons/bs';

import {
  RiCustomerService2Line,
  RiDashboardLine,
  RiShieldUserLine,
  RiTodoLine,
} from 'react-icons/ri';

import {CgFeed} from 'react-icons/cg';
import {VscTable, VscTools} from 'react-icons/vsc';

import {RoutePermittedRole} from '../shared/constants/AppConst';

const routesConfig = [
  {
    id: 'home',
    title: 'Home',
    messageId: 'sidebar.home',
    type: 'group',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        messageId: 'sidebar.dashboard',
        type: 'item',
        icon: <MdOutlineAnalytics />,
        url: '/home',
      },
      {
        id: 'tests',
        title: 'Tests',
        messageId: 'sidebar.tests',
        type: 'item',
        icon: <RiDashboardLine />,
        url: '/tests',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
          RoutePermittedRole.MEMBER,
        ],
      },
      {
        id: 'analysis',
        title: 'Analysis',
        messageId: 'sidebar.analysis',
        type: 'item',
        icon: 'bar_chart',
        url: '/analysis',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
          RoutePermittedRole.MEMBER,
        ],
      },
      {
        id: 'products',
        title: 'Products',
        messageId: 'sidebar.products',
        type: 'item',
        icon: <RiTodoLine />,
        url: '/products',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
          RoutePermittedRole.MEMBER,
        ],
      },
      {
        id: 'practices',
        title: 'Practices',
        messageId: 'sidebar.practices',
        type: 'item',
        icon: <RiTodoLine />,
        url: '/practices',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
          RoutePermittedRole.MEMBER,
        ],
      },
    ],
  },
  {
    id: 'my-account',
    title: 'My Account',
    messageId: 'sidebar.account',
    type: 'group',
    children: [
      {
        id: 'my-orders',
        title: 'MyOrders',
        messageId: 'sidebar.my.purchases',
        type: 'item',
        icon: <BsCart4 />,
        url: '/my-orders',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
          RoutePermittedRole.MEMBER,
        ],
      },
      {
        id: 'my-profile',
        title: 'My Profile',
        messageId: 'sidebar.my.profile',
        type: 'item',
        icon: <RiShieldUserLine />,
        url: '/my-profile',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
          RoutePermittedRole.MEMBER,
        ],
      },
    ],
  },
];
export default routesConfig;
