export const authRole = {
  SUPERADMIN: ['MEMBER', 'ADMIN', 'SUPERADMIN', 'STUDENT'],
  ADMIN: ['MEMBER', 'ADMIN'],
  MEMBER: ['MEMBER'],
  STUDENT: ['STUDENT'],
};

export const RoutePermittedRole = {
  SUPERADMIN: 'SUPERADMIN',
  ADMIN: 'ADMIN',
  MEMBER: 'MEMBER',
  STUDENT: 'STUDENT',
};
export const defaultUser = {
  displayName: 'John Alex',
  email: 'demo@example.com',
  token: 'access-token',
  role: 'MEMBER',
  photoURL: '/assets/images/avatar/A11.jpg',
};

export const defaultGridConfig = {
  rowsPerPage: 100,
  pages: 100,
};

export const initialUrl = '/home'; // this url will open after login

export const RETAKE_LIMIT = 3;
