/* eslint-disable no-unused-vars */
import React from 'react';
import {useAuthUser} from '@crema/utility/AuthHooks';
import {Formik} from 'formik';
import * as yup from 'yup';
import PersonalInfoForm from './PersonalInfoForm';
import PropTypes from 'prop-types';
import {Box, Typography} from '@mui/material';
import {useSelector, useDispatch} from 'react-redux';
import {isLoaded, isEmpty} from 'react-redux-firebase';
import IntlMessages from '@crema/utility/IntlMessages';
import AppLoader from '@crema/core/AppLoader';
import {usersCollection} from '../../../../services/usersCollection';
import AppInfoView from '@crema/core/AppInfoView';
import {showMessage} from 'redux/actions';
import {Fonts} from 'shared/constants/AppEnums';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = yup.object({
  email: yup.string().email('Invalid email format').required('Required'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  phoneNumber: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  gender: yup.string().required('Gender is required'),
  grade: yup.string().required('Grade is required'),
});
const PersonalInfo = ({isDialogOpen, handleClose}) => {
  const {user} = useAuthUser();
  const profile = useSelector((state) => state.firebase.profile);
  const dispatch = useDispatch();

  if (!isLoaded(profile)) {
    return <AppLoader />;
  }

  return (
    <Box
      sx={{
        position: 'relative',
        maxWidth: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          component='h3'
          sx={{
            fontSize: 16,
            fontWeight: Fonts.BOLD,
            mb: {xs: 3, lg: 4},
          }}
        >
          <IntlMessages id='common.personalInfo' />
        </Typography>

        {isDialogOpen && (
          <IconButton
            aria-label='close'
            color='inherit'
            size='large'
            onClick={() => {
              handleClose();
            }}
          >
            <CloseIcon fontSize='inherit' />
          </IconButton>
        )}
      </Box>
      <Formik
        validateOnBlur={true}
        initialValues={{
          ...profile,
          photoURL: user.photoURL
            ? user.photoURL
            : '/assets/images/placeholder.jpg',
        }}
        validationSchema={validationSchema}
        onSubmit={async (data, {setSubmitting}) => {
          setSubmitting(true);
          // console.log('data: ', data);

          //TODO Api Call here to save user info
          try {
            await usersCollection.updateUserProfile(user.uid, data, profile);
            dispatch(showMessage('Success to update your profile.'));
          } catch (e) {
            console.log(e);
            dispatch(showMessage('Please, Enter all informations.'));
          }
          setSubmitting(false);
        }}
      >
        {({values, setFieldValue, errors}) => {
          return (
            <PersonalInfoForm
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
            />
          );
        }}
      </Formik>
      <AppInfoView />
    </Box>
  );
};

export default PersonalInfo;

PersonalInfo.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.string,
  isDialogOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};
