// eslint-disable-next-line no-undef
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// eslint-disable-next-line no-undef
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
// eslint-disable-next-line no-undef
export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const GOOGLE_RECAPTCHA_CLIENT_KEY =
  '6Lew8AMaAAAAAO2OOR4d56AdnhAXKUx99b6m-k1z';
export const PRE_AUTH_LOGO =
  'https://firebasestorage.googleapis.com/v0/b/best-ssat-prep.appspot.com/o/logo.png?alt=media&token=302287e9-7a17-46a0-bccc-ac067ad9c229';
export const LOGO =
  'https://firebasestorage.googleapis.com/v0/b/best-ssat-prep.appspot.com/o/logo-2.png?alt=media&token=3cbd66d5-6dce-4297-a714-e677e47d664a';

export const QUESTION_SAMPLE_IMAGE_VERBAL =
  'https://firebasestorage.googleapis.com/v0/b/best-ssat-prep.appspot.com/o/question-sample-verbal.PNG?alt=media&token=3f7978f9-7167-4336-aa65-1915584df758';
export const PLATFORM_INSTRUCTIONS_IMAGE_1 =
  'assets/images/practice/introduction/instruction-answer-sheet_2.jpg';
export const PLATFORM_INSTRUCTIONS_IMAGE_2 =
  'assets/images/practice/introduction/instruction-bubble-answer-sheet_2.jpg';
export const PLATFORM_INSTRUCTIONS_IMAGE_3 =
  'assets/images/practice/introduction/instruction-writing-section-pause-finish-button_2.jpg';
export const FREE_BLUR_IMAGE =
  'assets/images/analysis/free-test-overview-blur.png';

export const QUESTION_STATUSES = {
  UNATTEMPTED: 'Unattempted',
  MARKED: 'Marked',
  COMPLETED: 'Completed',
  ARCHIVED: 'Archived',
};

export const SECTION_STATUSES = {
  UNATTEMPTED: 'Unattempted',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  ARCHIVED: 'Archived',
};

export const TEST_STATUSES = {
  UNATTEMPTED: 'View and Start',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  ARCHIVED: 'Archived',
};

export const ASSESSMENT_STATUSES = {
  IN_PROGRESS: 'In Progress',
  PAUSED: 'Paused',
  PRE_FINISH: 'Pre Finish',
  TIME_UP: 'Time Up',
};

export const SUGGESTED_STATUSES = {
  UNATTEMPTED: 'Unattempted',
  MARKED: 'Marked',
  COMPLETED: 'Completed',
  ARCHIVED: 'Archived',
};

export const VALID_SECTION_PARAMS = {
  WRITING: 'writing',
  QUANTITATIVE_1: 'quantitative-1',
  READING: 'reading',
  VERBAL: 'verbal',
  QUANTITATIVE_2: 'quantitative-2',
};

export const VALID_SECTION_NAMES = {
  WRITING: 'Writing',
  QUANTITATIVE_1: 'Quantitative #1',
  READING: 'Reading',
  VERBAL: 'Verbal',
  QUANTITATIVE_2: 'Quantitative #2',
};

export const FILTER_KEYS = {
  NONE: 'none',
  CORRECTNESS: 'correctness',
  DIFFICULTY: 'difficulty',
  QUESTION_TYPE: 'questionType',
};

export const LEVELS = {
  MIDDLE: 'Middle',
  UPPER: 'Upper',
};
