import React from 'react';
import Box from '@mui/material/Box';
import {useLayoutContext} from '../../../../utility/AppContextProvider/LayoutContextProvider';
import Typography from '@mui/material/Typography';
import FooterWrapper from './FooterWrapper';
import Cart from '../../../../../pages/member/Cart';

const AppFixedFooter = () => {
  const {footer, footerType} = useLayoutContext();

  return (
    <>
      {footer && footerType === 'fixed' ? (
        <FooterWrapper className='footer fixed-footer'>
          <div className='footerContainer'>
            <Typography>Copy right @ BEST SSAT PREP 2022</Typography>
            <Box sx={{ml: 'auto'}}>
              <Cart />
            </Box>
          </div>
        </FooterWrapper>
      ) : null}
    </>
  );
};

export default AppFixedFooter;
