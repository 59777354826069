/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React, {useContext, useEffect, useRef} from 'react';
import {MathComponent} from 'mathjax-react';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import {
  MathJax,
  MathJaxContext,
  MathJaxBaseContext,
} from 'better-react-mathjax';
import Box from '@mui/material/Box';
import { AlignHorizontalLeft } from '@mui/icons-material';

const createTotalSearchRegex = (keyword, startSeparator, endSeparator) =>
  new RegExp(`${keyword}\\${startSeparator}.*?\\${endSeparator}`, 'g');
const createBetweenSearchRegex = (keyword, startSeparator, endSeparator) =>
  new RegExp(`(?<=${keyword}\\${startSeparator}).*?(?=\\${endSeparator})`);

const parseUnicodes = async (formula) => {
  const regexTotal = await createTotalSearchRegex('unicode', '(', ')');
  //const regexBetween = createBetweenSearchRegex('unicode', '(', ')');
  const replaceFlag = 'toReplace';

  const matchedText = [];
  let curText = formula;
  //let match = curText.match(regexBetween);
  // while (match) {
  //   matchedText.push(match[0]);
  //   curText = curText.substring(match.index);
  //   match = curText.match(regexBetween);
  // }

  let formulalist = formula.match(regexTotal);

  if (formulalist) {
    formulalist = formulalist.map((list) =>
      list.replaceAll('unicode(', '').replaceAll(')', ''),
    );
  }

  let newText = formula;
  newText = newText.replaceAll(regexTotal, `__${replaceFlag}__`);
  const matchedSplit = newText.split('__');
  let counter = 0;
  for (let i = 0; i < matchedSplit.length; i += 1) {
    if (matchedSplit[i] === replaceFlag) {
      matchedSplit[i] = `\\unicode{x${formulalist[counter++]}}`;
    }
  }

  return matchedSplit.join('');
};

const buildFormulas = async (formulas) => {
  for (let index = 0; index < formulas.length; index += 1) {
    let formula = formulas[index];
    formula = await parseUnicodes(formula);
    formulas[index] = formula;
  }
};

export const generateMathDisplay = async (text) => {
  const pattern = /math@(.*?)@/g;
  const matches = text.match(pattern);
  const unicodeText = changeStringUnicodeSymbols(text);

  const regexMathTotal = matches
    ? unicodeText
        .replaceAll('math@', '')
        .replaceAll('@', '')
        .replaceAll('\\c', '\\')
    : unicodeText;
  // let temp = `\int_0^1 x^2\ dx`;
  // return <MathComponent tex={String.raw`\frac{1}{2}`} />;
  // console.log(regexMathTotal);
  if (matches) {
    return (
      <MathComponent
        sx={{displayAlign: 'left', fontSize: '2em'}}
        settings={{fontSize: '3em'}}
        tex={String.raw`${regexMathTotal}`}
        display={false}
      />
    );
  } else {
    return unicodeText;
  }
  // return text;
};

// export const generateMathDisplay = async (text) => {
//   const regexMathTotal = await createTotalSearchRegex('math', '@', '@');
//   //const regexMathBetween = createBetweenSearchRegex('math', '@', '@');

//   const mathFormulas = [];
//   let curText = text;
//   //let match = curText.match(regexMathBetween);
//   //let rtnString = '';

//   // while (match) {
//   //   mathFormulas.push(match[0]);
//   //   curText = curText.substring(match.index);
//   //   match = curText.match(regexMathBetween);
//   // }

//   //buildFormulas(mathFormulas);

//   let mathlist = text?.match(regexMathTotal);
//   if (mathlist) await buildFormulas(mathlist);

//   let newText = text;
//   newText = newText?.replaceAll(regexMathTotal, '__toReplaceMath__');
//   const formulaSplit = newText?.split('_');

//   let idx = 0;
//   const returnFormula = [];
//   let formulaCounter = 0;

//   for await (const formulaItem of formulaSplit) {
//     let replaceItem;
//     if (formulaItem === 'toReplaceMath') {
//       replaceItem = (
//         <MathJax.Provider key={idx}>
//           <MathJax.Node
//             key={idx}
//             inline
//             formula={mathlist[formulaCounter++]
//               ?.replace('math@', '')
//               .replace('@', '')}
//           />
//         </MathJax.Provider>
//       );
//     } else {
//       replaceItem = formulaItem
//         .replaceAll('<p>', '')
//         .replaceAll('</p>', '')
//         .replaceAll('&amp;', '&')
//         .replaceAll('&gt;', '>')
//         .replaceAll('&lt;', '<');
//     }
//     idx++;
//     returnFormula.push(replaceItem);
//   }

//   // console.log('000000000000000000000000000000000');
//   // let returnFormula;
//   // returnFormula = await tempAsyncFunction(formulaSplit, mathlist);
//   // console.log(returnFormula);
//   // .then((test) => {
//   //   console.log('test', test);
//   //   returnFormula = test;
//   //   console.log(test);
//   // });
//   // console.log('999999999999999999999999999999999');
//   // let formulaCounter = 0;
//   // for (let i = 0; i < formulaSplit.length; i += 1) {
//   //   if (formulaSplit[i] === 'toReplaceMath') {
//   //     formulaSplit[i] = (
//   //       <MathJax.Provider key={i}>
//   //         <MathJax.Node
//   //           key={i}
//   //           inline
//   //           formula={mathlist[formulaCounter++]
//   //             .replace('math@', '')
//   //             .replace('@', '')}
//   //         />
//   //       </MathJax.Provider>
//   //     );
//   //   } else {
//   //     formulaSplit[i] = formulaSplit[i]
//   //       .replaceAll('<p>', '')
//   //       .replaceAll('</p>', '')
//   //       .replaceAll('&amp;', '&')
//   //       .replaceAll('&gt;', '>')
//   //       .replaceAll('&lt;', '<');
//   //   }
//   // }

//   // return formulaSplit;
//   // console.log(returnFormula, formulaSplit);
//   // console.log(formulaSplit.map(display));
//   return returnFormula;
// };

// const tempAsyncFunction = async (formulaSplit, mathlist) => {
//   let idx = 0;
//   const returnFormula = [];
//   let formulaCounter = 0;

//   for await (const formulaItem of formulaSplit) {
//     let replaceItem;
//     if (formulaItem === 'toReplaceMath') {
//       replaceItem = (
//         <MathJax.Provider key={idx}>
//           <MathJax.Node
//             key={idx}
//             inline
//             formula={mathlist[formulaCounter++]
//               .replace('math@', '')
//               .replace('@', '')}
//           />
//         </MathJax.Provider>
//       );
//     } else {
//       replaceItem = formulaItem
//         .replaceAll('<p>', '')
//         .replaceAll('</p>', '')
//         .replaceAll('&amp;', '&')
//         .replaceAll('&gt;', '>')
//         .replaceAll('&lt;', '<');
//     }
//     idx++;
//     returnFormula.push(replaceItem);
//   }

//   return returnFormula;
// };

const display = (node) => {
  if (typeof node !== 'object') {
    return parse(node);
  } else {
    return node;
  }
};

export const changeStringMathSymbols = (text) => {
  const pattern = /math@(.*?)@/g;
  const matches = text.match(pattern);

  return matches
    // ? text.replaceAll('math@', '`').replaceAll('@', '`').replaceAll('\\c', '')
    ? text.replaceAll('\\c', '')
    : text;
};

export const changeStringUnicodeSymbols = (text) => {
  let returnText = text;
  const pattern = /unicode\((.*?)\)/g;
  const matches = [...text.matchAll(pattern)];

  if (matches?.length > 0) {
    matches.map((match) => {
      // console.log(match, String.fromCharCode('0x' + match[1]));
      returnText = returnText.replaceAll(
        match[0],
        // `\\unicode{x${match[1]}}`,
        String.fromCharCode('0x' + match[1]),
      );
    });
  }

  return returnText;
};

const config = {
  loader: {load: ['input/asciimath']},
  asciimath: {
    displaystyle: true,
    delimiters: [['math@', '@']],
  },
};

export const ViewMathFormula = (text, width = '100%') => {
  // const mjContext = useContext(MathJaxBaseContext);
  const UnicodeSymbolsRemovedText = changeStringUnicodeSymbols(text);
  const mathSymbolRemovedText = changeStringMathSymbols(
    UnicodeSymbolsRemovedText,
  );

  return (
    <Box sx={{width: width}}>
      <MathJaxContext version={3} config={config}>
        {/* <MathJaxContext config={config}> */}
        {/* <MathJax inline hideUntilTypeset={'first'}>{parse(mathSymbolRemovedText)}</MathJax> */}
        <MathJax inline hideUntilTypeset={'first'}>
          {mathSymbolRemovedText}
        </MathJax>
      </MathJaxContext>
    </Box>
  );
};

export const BetterViewMathFormula = (text, width = '100%') => {
  return (
    <Box sx={{width: width}}>
      <MathJaxContext version={3} config={config}>
        <MathJax inline hideUntilTypeset={'first'}>
          {renderMathDisplay(text)}
        </MathJax>
      </MathJaxContext>
    </Box>
  );
};

export const renderMathDisplay = (unicodeQuestionText) => {
  const changedText = betterChangeStringUnicodeSymbols(unicodeQuestionText);
  const splitQuestionText = changedText.split('<br>');
  const returnArray = [];

  for (const questionText of splitQuestionText) {
    returnArray.push(questionText);
  }

  return returnArray.map((question, index) => (
    <>
      <MathJax inline={true} key={index} dynamic={true}>
        {parse(question)}
      </MathJax>
      {returnArray.length > 1 && index + 1 < returnArray.length ? <br /> : ''}
    </>
  ));
};

export const splitQuestion = async (unicodeQuestionText) => {
  const splitQuestionText = unicodeQuestionText.split('<br>');
  const returnArray = [];

  for await (const questionText of splitQuestionText) {
    returnArray.push(questionText);
  }

  returnArray.map((item, idx) => console.log(idx, item));
  return returnArray;
};

export const betterChangeStringUnicodeSymbols = (text) => {
  let returnText = text.replaceAll('\\c', '');
  const pattern = /unicode\((.*?)\)/g;
  const matches = [...text.matchAll(pattern)];

  if (matches?.length > 0) {
    matches.map((match) => {
      returnText = returnText.replaceAll(
        match[0],
        // `\\unicode{x${match[1]}}`,
        String.fromCharCode('0x' + match[1]),
      );
    });
  }

  return returnText;
};

ViewMathFormula.propTypes = {
  text: PropTypes.string,
  width: PropTypes.string,
};
