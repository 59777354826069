/* eslint-disable no-case-declarations */
import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_UPDATE_ITEM,
  CART_CLEAN_UP,
} from 'shared/constants/ActionTypes';

import _ from 'lodash';

const initialState = {
  cartItems: [],
};

// eslint-disable-next-line no-unused-vars
const checkItemExists = (items, data) => {
  // const found = items.some((item) => item.item_id === data.item_id);
  // return found;
  // 주석처리 : practice ID로 처리하도록 함
  return false;
};

const isTestInCart = (data, state) => {
  let isTestAddedCart = false;
  state.cartItems.forEach((item) => {
    let searchIdx = item.items.findIndex((e) => {
      const baseId = data.item_id.replace('suggest-', '');
      return e.test_id === baseId && e.type === 'practice';
    });

    if (searchIdx != -1) {
      isTestAddedCart = true;
    }
  });
  return isTestAddedCart;
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case CART_ADD_ITEM:
      if (checkItemExists(state.cartItems, action.payload)) {
        return state;
      } else {
        const itemCopy = _.cloneDeep(action.payload);

        if (
          itemCopy.item_id.includes('suggest') &&
          isTestInCart(itemCopy, state)
        ) {
          itemCopy.discount = 9.99;
        }

        return {
          ...state,
          cartItems: [...state.cartItems, itemCopy],
        };
      }

    case CART_UPDATE_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.map((item, index) =>
          index === action.payload.index ? action.payload.item : item,
        ),
      };
    case CART_REMOVE_ITEM:
      const stateCopy = _.cloneDeep(state);
      let removedItem = {};
      const removedState = stateCopy.cartItems.filter((item, index) => {
        if (index !== action.payload.index) {
          return item;
        } else {
          removedItem = item;
        }
      });

      if (removedItem.item_id.includes('practice')) {
        const removeSuggestItemId = removedItem.item_id.replace(
          'practice',
          'suggest',
        );

        const removedSuggestState = removedState.filter((item) => {
          if (item.item_id !== removeSuggestItemId) {
            return item;
          }
        });

        return {
          ...state,
          cartItems: removedSuggestState,
        };
      } else {
        return {
          ...state,
          cartItems: removedState,
        };
      }

    case CART_CLEAN_UP:
      return {
        ...state,
        cartItems: [],
      };

    default:
      return state;
  }
};

export default cartReducer;
