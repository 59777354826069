/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import {useFirestoreConnect} from 'react-redux-firebase';
import {getAuth} from 'firebase/auth';
import {db} from '../@crema/services/auth/firebase/firebase';
import {serverTimestamp} from 'firebase/firestore';
import firebase from 'firebase/compat/app';
import {generateMathDisplay} from 'util/math';
import {
  TEST_STATUSES,
  SECTION_STATUSES,
  ASSESSMENT_STATUSES,
  VALID_SECTION_NAMES,
} from 'util/constants';

import ReadingTestMapping from 'pages/member/Tests/Analysis/data/reading_test_mapping.json';
import QuantitativeTestMapping from 'pages/member/Tests/Analysis/data/quantitative_test_mapping.json';
import VerbalTestMapping from 'pages/member/Tests/Analysis/data/verbal_test_mapping.json';
import {DesktopAccessDisabledTwoTone} from '@mui/icons-material';
import {forEach} from 'lodash';
import {getAnalytics, logEvent} from 'firebase/analytics';
import {RETAKE_LIMIT} from 'shared/constants/AppConst.js';

const COLLECTION = {
  CONFIG: 'config',
  SECTIONS: 'sections',
  PRACTICES: 'student-practices',
  PRACTICESTEST: 'student-practices-test',
  PRACTICESSECTION: 'student-practices-test-section',
  PRACTICESQUESTION: 'student-practices-test-section-question',
  USERS: 'users',
  QUESTIONS: 'questions',
  SUGGESTED: 'suggested',
  TEMP: 'temp',
};

const configRef = db.collection(COLLECTION.CONFIG);
const practicesRef = db.collection(COLLECTION.PRACTICES);
const practicesTestRef = db.collection(COLLECTION.PRACTICESTEST);
const practicesSectionRef = db.collection(COLLECTION.PRACTICESSECTION);
const practicesQuestionRef = db.collection(COLLECTION.PRACTICESQUESTION);
const sectionsRef = db.collection(COLLECTION.SECTIONS);
const usersRef = db.collection(COLLECTION.USERS);
const questionsRef = db.collection(COLLECTION.QUESTIONS);
const suggestedRef = db.collection(COLLECTION.SUGGESTED);
const tempRef = db.collection(COLLECTION.TEMP);

const auth = getAuth();

const analytics = getAnalytics();

const getSumPracticeSectionTime = async (practiceInfo, section) => {
  const PRACTICE_SECTIONS = [
    'Writing',
    'WritingBreak',
    'Quantitative #1',
    'Quantitative#1Break',
    'Reading',
    'ReadingBreak',
    'Verbal',
    'VerbalBreak',
    'Quantitative #2',
    'Finish',
  ];
  const sectionIndex = PRACTICE_SECTIONS.findIndex((value) => value === section);
  let sumTimeSecond = 0;

  if(sectionIndex >= 0) sumTimeSecond = sumTimeSecond + practiceInfo.writingSectionTimeSecond;
  if(sectionIndex >= 1) sumTimeSecond = sumTimeSecond + practiceInfo.writingSectionBreakTimeSecond;
  if(sectionIndex >= 2) sumTimeSecond = sumTimeSecond + practiceInfo.Q1SectionTimeSecond;
  if(sectionIndex >= 3) sumTimeSecond = sumTimeSecond + practiceInfo.Q1SectionTimeBreakSecond;
  if(sectionIndex >= 4) sumTimeSecond = sumTimeSecond + practiceInfo.readingSectionTimeSecond;
  if(sectionIndex >= 5) sumTimeSecond = sumTimeSecond + practiceInfo.readingSectionBreakTimeSecond;
  if(sectionIndex >= 6) sumTimeSecond = sumTimeSecond + practiceInfo.verbalSectionTimeSecond;
  if(sectionIndex >= 7) sumTimeSecond = sumTimeSecond + practiceInfo.verbalSectionBreakTimeSecond;
  if(sectionIndex >= 8) sumTimeSecond = sumTimeSecond + practiceInfo.Q2SectionTimeSecond;
  if(sectionIndex >= 9) sumTimeSecond = sumTimeSecond + practiceInfo.Q2SectionTimeBreakSecond;

  return sumTimeSecond;
};

export const practicesCollection = {
  getTestsDataAndListener: () => {
    useFirestoreConnect([
      {
        collection: COLLECTION.PRACTICES,
        storeAs: COLLECTION.PRACTICES,
      },
    ]);
  },
  getPracticesInUser: async () => {
    useFirestoreConnect([
      {
        collection: COLLECTION.PRACTICES,
        storeAs: 'practices',
        where: [['studentEmail', '==', auth.currentUser.email]],
      },
    ]);
  },
  getFinishedPracticesInUser: async () => {
    useFirestoreConnect([
      {
        collection: COLLECTION.PRACTICES,
        storeAs: 'reportPractices',
        where: [
          ['studentEmail', '==', auth.currentUser.email],
          ['status', 'in', ['FINISHED']],
        ],
      },
    ]);
  },
  getCurrentSection: (practice, section) => {
    useFirestoreConnect([
      {
        collection: COLLECTION.PRACTICESSECTION,
        storeAs: 'currentSection',
        where: [
          ['studentPracticeId', '==', practice.id ? practice.id : ''],
          ['section', '==', section],
        ],
      },
    ]);
  },
  getQuestions: (practice) => {
    useFirestoreConnect([
      {
        collection: COLLECTION.PRACTICESSECTION,
        storeAs: 'sections',
        where: [['studentPracticeId', '==', practice.id ? practice.id : '']],
      },
      {
        collection: COLLECTION.PRACTICESQUESTION,
        storeAs: 'writings',
        where: [
          ['studentPracticeId', '==', practice.id ? practice.id : ''],
          ['section', '==', 'Writing'],
        ],
      },
      {
        collection: COLLECTION.PRACTICESQUESTION,
        storeAs: 'quantitative1s',
        where: [
          ['studentPracticeId', '==', practice.id ? practice.id : ''],
          ['section', '==', 'Quantitative #1'],
        ],
      },
    ]);
  },
  getWritingSection: async (practiceData, section) => {
    let returnList = [];
    const sectionList = await practicesSectionRef
      .where('studentPracticeId', '==', practiceData.id)
      .where('section', '==', section)
      .get();

    sectionList.forEach(async (section) => {
      await returnList.push(section.data());
    });

    return returnList;
  },
  getWritingSectionQuestion: async (practiceData, section) => {
    let returnList = [];
    const questionList = await practicesQuestionRef
      .where('studentPracticeId', '==', practiceData.id)
      .where('section', '==', section)
      .get();

    questionList.forEach(async (section) => {
      await returnList.push(section.data());
    });

    return returnList;
  },
  saveWritingSectionAnswer: async (
    practiceData,
    writedEssay,
    selectedEssay,
    writeQuestions,
  ) => {
    let practiceInfo = {...practiceData.practice};

    await practicesQuestionRef.doc(`${writeQuestions.baseId}`).update({
      selectedOption: selectedEssay,
      writedEssay: writedEssay,
      writedDatetime: serverTimestamp(),
    });

    practiceInfo.writedDatetime = serverTimestamp();
    //practiceInfo.writingSectionTimeSecond = 0;
    practiceInfo.writingSectionStatus = 'FINISHED';

    await practicesRef.doc(`${practiceData.id}`).update({
      practice: practiceInfo,
    });

    return practiceData.practice.writingSectionBreakTimeSecond;
  },
  getSectionQuestion: async (practiceData, section) => {
    let returnList = [];

    const questionList = await practicesQuestionRef
      .where('studentPracticeId', '==', practiceData.id)
      .where('section', '==', section)
      .get();

    for (const question of questionList.docs) {
      returnList.push(question.data());
    }
    // questionList.forEach(async (section) => {
    //   await returnList.push(section.data());
    // });

    return returnList;
  },
  getPracticeQuestions: async (practiceData) => {
    let returnList = [];
    const questionList = await practicesQuestionRef
      .where('studentPracticeId', '==', practiceData.id)
      .get();

    questionList.forEach(async (section) => {
      await returnList.push(section.data());
    });

    return returnList;
  },
  getServerTimestamp: async () => {
    let timestampNow;

    await configRef
      .doc('currentTimeStamp')
      .set({
        time: serverTimestamp(),
      })
      .then(async () => {
        await configRef
          .doc('currentTimeStamp')
          .get()
          .then((snapshot) => {
            const serverTime = snapshot.data();
            timestampNow = serverTime.time;
          });
      });
    return timestampNow;
  },
  getFinishSectionTime: async (studentPractice, section) => {
    let sectionFinishTime = 0;
    let sectionRemainTime = 0;

    let studentPracticeInfo = await practicesRef.doc(studentPractice.id).get();
    let practiceData = studentPracticeInfo.data();
    let practiceInfo = await practiceData.practice;

    const nowDate = new Date();
    const nowTimestamp = await firebase.firestore.Timestamp.fromDate(nowDate);
    const sumTimeSecond = await getSumPracticeSectionTime(practiceInfo, section);
    const attendedSecond = practiceInfo.isOneDayPractice === 'true' ? practiceInfo.practiceBeginTimestamp.seconds : practiceData.attendedDatetime.seconds;
    const totalSecond = sumTimeSecond + attendedSecond;
    const nowSecond = nowTimestamp.seconds + sumTimeSecond;
    sectionFinishTime = practiceData.status === 'ATTENDED' ? totalSecond : nowSecond;

    switch (section) {
      case 'Writing':
        sectionFinishTime =
          practiceData.status === 'ATTENDED' &&
          practiceInfo.writingRemainTimeSecond !== practiceInfo.writingSectionTimeSecond
            ? totalSecond
            : nowSecond;
        sectionRemainTime =
          practiceData.status === 'ATTENDED' &&
          practiceInfo.writingRemainTimeSecond
            ? practiceInfo.writingRemainTimeSecond
            : practiceInfo.writingSectionTimeSecond;

        break;
      case 'WritingBreak':
        sectionRemainTime =
          practiceData.status === 'ATTENDED' &&
          practiceInfo.writingBreakRemainTimeSecond
            ? practiceInfo.writingBreakRemainTimeSecond
            : practiceInfo.writingSectionBreakTimeSecond;
  
        break;
      case 'Quantitative #1':
        sectionRemainTime =
          practiceData.status === 'ATTENDED' && practiceInfo.Q1RemainTimeSecond
            ? practiceInfo.Q1RemainTimeSecond
            : practiceInfo.Q1SectionTimeSecond;
  
        break;
      case 'Quantitative#1Break':
        sectionRemainTime =
          practiceData.status === 'ATTENDED' &&
          practiceInfo.Q1BreakRemainTimeSecond
            ? practiceInfo.Q1BreakRemainTimeSecond
            : practiceInfo.Q1SectionTimeBreakSecond;

        break;
      case 'Verbal':
        sectionRemainTime =
          practiceData.status === 'ATTENDED' &&
          practiceInfo.verbalRemainTimeSecond
            ? practiceInfo.verbalRemainTimeSecond
            : practiceInfo.verbalSectionTimeSecond;
  
        break;
      case 'VerbalBreak':
        sectionRemainTime =
          practiceData.status === 'ATTENDED' &&
          practiceInfo.verbalBreakRemainTimeSecond
            ? practiceInfo.verbalBreakRemainTimeSecond
            : practiceInfo.verbalSectionBreakTimeSecond;

        break;
      case 'Reading':
        sectionRemainTime =
          practiceData.status === 'ATTENDED' &&
          practiceInfo.readingRemainTimeSecond
            ? practiceInfo.readingRemainTimeSecond
            : practiceInfo.readingSectionTimeSecond;
  
        break;
      case 'ReadingBreak':
        sectionRemainTime =
          practiceData.status === 'ATTENDED' &&
          practiceInfo.readingBreakRemainTimeSecond
            ? practiceInfo.readingBreakRemainTimeSecond
            : practiceInfo.readingSectionBreakTimeSecond;
  
        break;
      case 'Quantitative #2':
        sectionRemainTime =
          practiceData.status === 'ATTENDED' && practiceInfo.Q2RemainTimeSecond
            ? practiceInfo.Q2RemainTimeSecond
            : practiceInfo.Q2SectionTimeSecond;
              
        break;
      case 'Quantitative#2Break':
        sectionRemainTime =
          practiceData.status === 'ATTENDED' &&
          practiceInfo.Q2BreakRemainTimeSecond
            ? practiceInfo.Q2BreakRemainTimeSecond
            : practiceInfo.Q2SectionTimeBreakSecond;
  
        break;
    }

    return {sectionFinishTime, sectionRemainTime};
  },
  getRemainSectionTime: async (studentPractice, section) => {

    let sectionRemainTime = 0;

    let studentPracticeInfo = await practicesRef.doc(studentPractice.id).get();
    let practiceData = studentPracticeInfo.data();
    let practiceInfo = await practiceData.practice;

    switch (section) {
      case 'Writing':
        sectionRemainTime =
          practiceData.status === 'ATTENDED' &&
          practiceInfo.writingRemainTimeSecond
            ? practiceInfo.writingRemainTimeSecond
            : practiceInfo.writingSectionTimeSecond;
        break;
      case 'WritingBreak':
        sectionRemainTime =
          practiceData.status === 'ATTENDED' &&
          practiceInfo.writingBreakRemainTimeSecond
            ? practiceInfo.writingBreakRemainTimeSecond
            : practiceInfo.writingSectionBreakTimeSecond;
        break;
      case 'Quantitative #1':
        sectionRemainTime =
          practiceData.status === 'ATTENDED' && practiceInfo.Q1RemainTimeSecond
            ? practiceInfo.Q1RemainTimeSecond
            : practiceInfo.Q1SectionTimeSecond;
        break;
      case 'Quantitative#1Break':
        sectionRemainTime =
          practiceData.status === 'ATTENDED' &&
          practiceInfo.Q1BreakRemainTimeSecond
            ? practiceInfo.Q1BreakRemainTimeSecond
            : practiceInfo.Q1SectionTimeBreakSecond;
        break;
      case 'Verbal':
        sectionRemainTime =
          practiceData.status === 'ATTENDED' &&
          practiceInfo.verbalRemainTimeSecond
            ? practiceInfo.verbalRemainTimeSecond
            : practiceInfo.verbalSectionTimeSecond;
        break;
      case 'VerbalBreak':
        sectionRemainTime =
          practiceData.status === 'ATTENDED' &&
          practiceInfo.verbalBreakRemainTimeSecond
            ? practiceInfo.verbalBreakRemainTimeSecond
            : practiceInfo.verbalSectionBreakTimeSecond;
        break;
      case 'Reading':
        sectionRemainTime =
          practiceData.status === 'ATTENDED' &&
          practiceInfo.readingRemainTimeSecond
            ? practiceInfo.readingRemainTimeSecond
            : practiceInfo.readingSectionTimeSecond;
        break;
      case 'ReadingBreak':
        sectionRemainTime =
          practiceData.status === 'ATTENDED' &&
          practiceInfo.readingBreakRemainTimeSecond
            ? practiceInfo.readingBreakRemainTimeSecond
            : practiceInfo.readingSectionBreakTimeSecond;
        break;
      case 'Quantitative #2':
        sectionRemainTime =
          practiceData.status === 'ATTENDED' && practiceInfo.Q2RemainTimeSecond
            ? practiceInfo.Q2RemainTimeSecond
            : practiceInfo.Q2SectionTimeSecond;
        break;
      case 'Quantitative#2Break':
        sectionRemainTime =
          practiceData.status === 'ATTENDED' &&
          practiceInfo.Q2BreakRemainTimeSecond
            ? practiceInfo.Q2BreakRemainTimeSecond
            : practiceInfo.Q2SectionTimeBreakSecond;
        break;
    }

    return sectionRemainTime;
  },
  setRemainTimeSecond: async (studentPractice, section, leftTime) => {
    // eslint-disable-next-line prettier/prettier
    const studentPracticeInfo = await practicesRef.doc(studentPractice.id).get();
    const practiceData = studentPracticeInfo.data();
    const practiceInfo = await practiceData.practice;

    switch (section) {
      case 'Writing':
        practiceInfo.writingRemainTimeSecond = leftTime;
        break;
      case 'WritingBreak':
        practiceInfo.writingRemainTimeSecond = 0;
        practiceInfo.writingBreakRemainTimeSecond = leftTime;
        break;
      case 'Quantitative #1':
        practiceInfo.writingRemainTimeSecond = 0;
        practiceInfo.writingBreakRemainTimeSecond = 0;
        practiceInfo.Q1RemainTimeSecond = leftTime;
        break;
      case 'Quantitative#1Break':
        practiceInfo.writingRemainTimeSecond = 0;
        practiceInfo.writingBreakRemainTimeSecond = 0;
        practiceInfo.Q1RemainTimeSecond = 0;
        practiceInfo.Q1BreakRemainTimeSecond = leftTime;
        break;
      case 'Reading':
        practiceInfo.writingRemainTimeSecond = 0;
        practiceInfo.writingBreakRemainTimeSecond = 0;
        practiceInfo.Q1RemainTimeSecond = 0;
        practiceInfo.Q1BreakRemainTimeSecond = 0;
        practiceInfo.readingRemainTimeSecond = leftTime;
        break;
      case 'ReadingBreak':
        practiceInfo.writingRemainTimeSecond = 0;
        practiceInfo.writingBreakRemainTimeSecond = 0;
        practiceInfo.Q1RemainTimeSecond = 0;
        practiceInfo.Q1BreakRemainTimeSecond = 0;
        practiceInfo.readingRemainTimeSecond = 0;
        practiceInfo.readingBreakRemainTimeSecond = leftTime;
        break;
      case 'Verbal':
        practiceInfo.writingRemainTimeSecond = 0;
        practiceInfo.writingBreakRemainTimeSecond = 0;
        practiceInfo.Q1RemainTimeSecond = 0;
        practiceInfo.Q1BreakRemainTimeSecond = 0;
        practiceInfo.readingRemainTimeSecond = 0;
        practiceInfo.verbalRemainTimeSecond = leftTime;
        break;
      case 'VerbalBreak':
        practiceInfo.writingRemainTimeSecond = 0;
        practiceInfo.writingBreakRemainTimeSecond = 0;
        practiceInfo.Q1RemainTimeSecond = 0;
        practiceInfo.Q1BreakRemainTimeSecond = 0;
        practiceInfo.readingRemainTimeSecond = 0;
        practiceInfo.readingBreakRemainTimeSecond = 0;
        practiceInfo.verbalRemainTimeSecond = 0;
        practiceInfo.verbalBreakRemainTimeSecond = leftTime;
        break;
      case 'Quantitative #2':
        practiceInfo.writingRemainTimeSecond = 0;
        practiceInfo.writingBreakRemainTimeSecond = 0;
        practiceInfo.Q1RemainTimeSecond = 0;
        practiceInfo.Q1BreakRemainTimeSecond = 0;
        practiceInfo.verbalRemainTimeSecond = 0;
        practiceInfo.verbalBreakRemainTimeSecond = 0;
        practiceInfo.readingRemainTimeSecond = 0;
        practiceInfo.readingBreakRemainTimeSecond = 0;
        practiceInfo.Q2RemainTimeSecond = leftTime;
        break;
      case 'Quantitative#2Break':
        practiceInfo.writingRemainTimeSecond = 0;
        practiceInfo.writingBreakRemainTimeSecond = 0;
        practiceInfo.Q1RemainTimeSecond = 0;
        practiceInfo.Q1BreakRemainTimeSecond = 0;
        practiceInfo.verbalRemainTimeSecond = 0;
        practiceInfo.verbalBreakRemainTimeSecond = 0;
        practiceInfo.readingRemainTimeSecond = 0;
        practiceInfo.readingSectionBreakTimeSecond = 0;
        practiceInfo.Q2RemainTimeSecond = 0;
        practiceInfo.Q2BreakRemainTimeSecond = leftTime;
        break;
    }

    await practicesRef.doc(studentPractice.id).update({practice: practiceInfo});
  },
  setStudentAttendPractice: async (practiceData) => {
    await practicesRef
      .doc(practiceData.id)
      .update({status: 'ATTENDED', attendTimestamp: serverTimestamp()});
  },
  setSelectedOption: async (currentQuestion, optionLetter) => {
    await practicesQuestionRef.doc(currentQuestion.baseId).update({
      selectedOption: optionLetter,
      status: 'ANSWERED',
      answeredTimestamp: serverTimestamp(),
    });
  },
  getSectionsList: async (practiceData) => {
    let returnList = [];
    const sectionsList = await practicesSectionRef
      .where('studentPracticeId', '==', practiceData.id)
      .get();

    sectionsList.forEach(async (section) => {
      await returnList.push(section.data());
    });

    return returnList;
  },
  closeSection: async (sectionInfo, currentPractice) => {
    let practiceInfo = {...currentPractice.practice};

    switch (sectionInfo.section) {
      case 'Quantitative #1':
        //practiceInfo.Q1SectionTimeSecond = 0;
        practiceInfo.Q1SectionFinishedDatetime = serverTimestamp();
        practiceInfo.Q1SectionStatus = 'FINISHED';
        break;
      case 'Verbal':
        //practiceInfo.verbalSectionTimeSecond = 0;
        practiceInfo.verbalFinishedDatetime = serverTimestamp();
        practiceInfo.verbalSectionStatus = 'FINISHED';
        break;
      case 'Reading':
        //practiceInfo.readingSectionTimeSecond = 0;
        practiceInfo.readingFinishedDatetime = serverTimestamp();
        practiceInfo.readingSectionStatus = 'FINISHED';
        break;
      case 'Quantitative #2':
        //practiceInfo.Q2SectionTimeSecond = 0;
        practiceInfo.Q2SectionFinishedDatetime = serverTimestamp();
        practiceInfo.Q2SectionStatus = 'FINISHED';
        break;
      default:
        break;
    }

    await practicesRef.doc(`${currentPractice.id}`).update({
      practice: practiceInfo,
    });
  },
  setStudentAssessmentScore: async (practice) => {
    let correctCountTotal = 0;
    let omitCountTotal = 0;
    let notCorrectCountTotal = 0;
    let correctCountQ1 = 0;
    let omitCountQ1 = 0;
    let notCorrectCountQ1 = 0;
    let correctCountQ2 = 0;
    let omitCountQ2 = 0;
    let notCorrectCountQ2 = 0;
    let correctCountReading = 0;
    let omitCountReading = 0;
    let notCorrectCountReading = 0;
    let correctCountVerbal = 0;
    let omitCountVerbal = 0;
    let notCorrectCountVerbal = 0;
    let correctCount = 0;
    let notCorrectCount = 0;
    let omittedCount = 0;
    let mathCorrectCount = 0;
    let mathNotCorrectCount = 0;
    let mathOmittedCount = 0;
    let mathQuestionTypeCount = {};
    let verbalQuestionTypeCount = {};
    let readingQuestionTypeCount = {};
    let mathScoreMapping = {};
    let verbalScoreMapping = {};
    let readingScoreMapping = {};
    let mathRawScore = 0;
    let verbalRawScore = 0;
    let readingRawScore = 0;
    let mathPercentile = 0;
    let verbalPercentile = 0;
    let readingPercentile = 0;

    const questionsList = await practicesQuestionRef
      .where('studentPracticeId', '==', practice.id)
      .get();

    for (const question of questionsList.docs) {
      const questionData = question.data();
      const isOmitted = questionData.selectedOption === '';
      const isCorrect =
        questionData.selectedOption === questionData.correctLetter;
      const isNotCorrect =
        questionData.selectedOption !== questionData.correctLetter &&
        questionData.selectedOption !== '';

      let questionType = '';

      switch (questionData.section) {
        case 'Writing':
          break;
        case 'Reading':
          questionType = questionData.topic;

          if (!readingQuestionTypeCount[questionType]) {
            readingQuestionTypeCount[questionType] = {
              questions: 0,
              right: 0,
              wrong: 0,
              notAnswered: 0,
            };
          }

          readingQuestionTypeCount[questionType].questions++;

          if (isCorrect) {
            correctCountReading++;
            correctCount++;
            readingQuestionTypeCount[questionType].right++;
          }
          if (isNotCorrect) {
            notCorrectCountReading++;
            notCorrectCount++;
            readingQuestionTypeCount[questionType].wrong++;
          }
          if (isOmitted) {
            omitCountReading++;
            omittedCount++;
            readingQuestionTypeCount[questionType].notAnswered++;
          }

          break;
        case 'Verbal':
          questionType = questionData.questionType;

          if (!verbalQuestionTypeCount[questionType]) {
            verbalQuestionTypeCount[questionType] = {
              questions: 0,
              right: 0,
              wrong: 0,
              notAnswered: 0,
            };
          }

          verbalQuestionTypeCount[questionType].questions++;

          if (isCorrect) {
            correctCountVerbal++;
            correctCount++;
            verbalQuestionTypeCount[questionType].right++;
          }
          if (isNotCorrect) {
            notCorrectCountVerbal++;
            notCorrectCount++;
            verbalQuestionTypeCount[questionType].wrong++;
          }
          if (isOmitted) {
            omitCountVerbal++;
            omittedCount++;
            verbalQuestionTypeCount[questionType].notAnswered++;
          }

          break;
        case 'Quantitative #1':
          questionType = questionData.topic;

          if (!mathQuestionTypeCount[questionType]) {
            mathQuestionTypeCount[questionType] = {
              questions: 0,
              right: 0,
              wrong: 0,
              notAnswered: 0,
            };
          }

          mathQuestionTypeCount[questionType].questions++;

          if (isCorrect) {
            correctCountQ1++;
            correctCount++;
            mathCorrectCount++;
            mathQuestionTypeCount[questionType].right++;
          }
          if (isNotCorrect) {
            notCorrectCountQ1++;
            notCorrectCount++;
            mathNotCorrectCount++;
            mathQuestionTypeCount[questionType].wrong++;
          }
          if (isOmitted) {
            omitCountQ1++;
            omittedCount++;
            mathOmittedCount++;
            mathQuestionTypeCount[questionType].notAnswered++;
          }

          break;
        case 'Quantitative #2':
          questionType = questionData.topic;

          if (!mathQuestionTypeCount[questionType]) {
            mathQuestionTypeCount[questionType] = {
              questions: 0,
              right: 0,
              wrong: 0,
              notAnswered: 0,
            };
          }

          mathQuestionTypeCount[questionType].questions++;
          if (isCorrect) {
            correctCountQ2++;
            correctCount++;
            mathCorrectCount++;
            mathQuestionTypeCount[questionType].right++;
          }
          if (isNotCorrect) {
            notCorrectCountQ2++;
            notCorrectCount++;
            mathNotCorrectCount++;
            mathQuestionTypeCount[questionType].wrong++;
          }
          if (isOmitted) {
            omitCountQ2++;
            omittedCount++;
            mathOmittedCount++;
            mathQuestionTypeCount[questionType].notAnswered++;
          }

          break;
        default:
          break;
      }

      await practicesQuestionRef.doc(questionData.baseId).update({
        isOmitted: isOmitted,
        isCorrect: isCorrect,
        isNotCorrect: isNotCorrect,
      });
    }

    const sectionsList = await practicesSectionRef
      .where('studentPracticeId', '==', practice.id)
      .get();

    for (const section of sectionsList.docs) {
      const sectionData = section.data();

      switch (sectionData.section) {
        case 'Reading':
          await practicesSectionRef.doc(sectionData.baseId).update({
            omittedCount: omitCountReading,
            correctCount: correctCountReading,
            notCorrectCount: notCorrectCountReading,
          });
          break;
        case 'Verbal':
          await practicesSectionRef.doc(sectionData.baseId).update({
            omittedCount: omitCountVerbal,
            correctCount: correctCountVerbal,
            notCorrectCount: notCorrectCountVerbal,
          });
          break;
        case 'Quantitative #1':
          await practicesSectionRef.doc(sectionData.baseId).update({
            omittedCount: omitCountQ1,
            correctCount: correctCountQ1,
            notCorrectCount: notCorrectCountQ1,
          });
          break;
        case 'Quantitative #2':
          await practicesSectionRef.doc(sectionData.baseId).update({
            omittedCount: omitCountQ2,
            correctCount: correctCountQ2,
            notCorrectCount: notCorrectCountQ2,
          });
          break;
        default:
          break;
      }
    }

    correctCountTotal =
      correctCountReading +
      correctCountVerbal +
      correctCountQ1 +
      correctCountQ2;

    notCorrectCountTotal =
      notCorrectCountReading +
      notCorrectCountVerbal +
      notCorrectCountQ1 +
      notCorrectCountQ2;

    omitCountTotal =
      omitCountReading + omitCountVerbal + omitCountQ1 + omitCountQ2;

    mathRawScore =
      correctCountQ1 +
      correctCountQ2 -
      (notCorrectCountQ1 + notCorrectCountQ2) * 0.25;

    let studentGrade = practice.studentGrade;

    if (
      practice.practice?.testLevel.toUpperCase() === 'UPPER' &&
      practice.studentGrade < 8
    ) {
      studentGrade = '8';
    }
    if (
      practice.practice?.testLevel.toUpperCase() === 'MIDDLE' &&
      practice.studentGrade > 7
    ) {
      studentGrade = '7';
    }

    let scoreGrade = practice.grade ? practice.grade : studentGrade;

    mathScoreMapping = QuantitativeTestMapping.find((mapping) => {
      return (
        mapping.rawScoreFrom <= mathRawScore &&
        mapping.rawScoreTo >= mathRawScore &&
        mapping.grade === scoreGrade
      );
    });
    mathPercentile = mathScoreMapping
      ? practice.studentGender === 'MALE'
        ? parseInt(mathScoreMapping.malePercentile ? mathScoreMapping.malePercentile : 1)
        : parseInt(mathScoreMapping.femalePercentile ? mathScoreMapping.femalePercentile : 1)
      : 0;

    verbalRawScore = correctCountVerbal - notCorrectCountVerbal * 0.25;
    verbalScoreMapping = VerbalTestMapping.find((mapping) => {
      return (
        mapping.rawScoreFrom <= verbalRawScore &&
        mapping.rawScoreTo >= verbalRawScore &&
        mapping.grade === scoreGrade
      );
    });
    verbalPercentile = verbalScoreMapping
      ? practice.studentGender === 'MALE'
        ? parseInt(verbalScoreMapping.malePercentile ? verbalScoreMapping.malePercentile : 1)
        : parseInt(verbalScoreMapping.femalePercentile ? verbalScoreMapping.femalePercentile : 1)
      : 0;

    readingRawScore = correctCountReading - notCorrectCountReading * 0.25;
    readingScoreMapping = ReadingTestMapping.find((mapping) => {
      return (
        mapping.rawScoreFrom <= readingRawScore &&
        mapping.rawScoreTo >= readingRawScore &&
        mapping.grade === scoreGrade
      );
    });
    readingPercentile = readingScoreMapping
      ? practice.studentGender === 'MALE'
        ? parseInt(readingScoreMapping.malePercentile ? readingScoreMapping.malePercentile : 1)
        : parseInt(readingScoreMapping.femalePercentile ? readingScoreMapping.femalePercentile : 1)
      : 0;

    let totalScore = 0;

    totalScore =
      totalScore +
      (practice.studentGender === 'MALE'
        ? parseInt(readingScoreMapping.maleScaledScore ? readingScoreMapping.maleScaledScore : 400)
        : parseInt(readingScoreMapping.femaleScaledScore ? readingScoreMapping.femaleScaledScore : 400));

    totalScore =
      totalScore +
      (practice.studentGender === 'MALE'
        ? parseInt(verbalScoreMapping.maleScaledScore ? verbalScoreMapping.maleScaledScore : 400)
        : parseInt(verbalScoreMapping.femaleScaledScore ? verbalScoreMapping.femaleScaledScore : 400));

    totalScore =
      totalScore +
      (practice.studentGender === 'MALE'
        ? parseInt(mathScoreMapping.maleScaledScore ? mathScoreMapping.maleScaledScore : 400)
        : parseInt(mathScoreMapping.femaleScaledScore ? mathScoreMapping.femaleScaledScore : 400));

    let totalPercentile = 0;
    totalPercentile =
      totalPercentile + readingScoreMapping
        ? practice.studentGender === 'MALE'
          ? parseInt(readingScoreMapping.malePercentile ? readingScoreMapping.malePercentile : 1)
          : parseInt(readingScoreMapping.femalePercentile ? readingScoreMapping.femalePercentile : 1)
        : 0;

    totalPercentile =
      totalPercentile + verbalScoreMapping
        ? practice.studentGender === 'MALE'
          ? parseInt(verbalScoreMapping.malePercentile ? verbalScoreMapping.malePercentile : 1)
          : parseInt(verbalScoreMapping.femalePercentile ? verbalScoreMapping.femalePercentile : 1)
        : 0;

    totalPercentile =
      totalPercentile + mathScoreMapping
        ? practice.studentGender === 'MALE'
          ? parseInt(mathScoreMapping.malePercentile ? mathScoreMapping.malePercentile : 1)
          : parseInt(mathScoreMapping.femalePercentile ? mathScoreMapping.femalePercentile : 1)
        : 0;

    totalPercentile = Math.round(totalPercentile / 3) + 5;

    await practicesTestRef.doc(practice.id).update({
      omittedCount: omitCountTotal,
      correctCount: correctCountTotal,
      notCorrectCount: notCorrectCountTotal,
      correctCountReading: correctCountReading,
      correctCountVerbal: correctCountVerbal,
      correctCountQ1: correctCountQ1,
      correctCountQ2: correctCountQ2,
      notCorrectCountReading: notCorrectCountReading,
      notCorrectCountVerbal: notCorrectCountVerbal,
      notCorrectCountQ1: notCorrectCountQ1,
      notCorrectCountQ2: notCorrectCountQ2,
      omitCountReading: omitCountReading,
      omitCountVerbal: omitCountVerbal,
      omitCountQ1: omitCountQ1,
      omitCountQ2: omitCountQ2,
      mathQuestionTypeCount: mathQuestionTypeCount,
      verbalQuestionTypeCount: verbalQuestionTypeCount,
      readingQuestionTypeCount: readingQuestionTypeCount,
      readingRawScore: readingRawScore,
      mathRawScore: mathRawScore,
      verbalRawScore: verbalRawScore,
      readingScoreMapping: readingScoreMapping,
      verbalScoreMapping: verbalScoreMapping,
      mathScoreMapping: mathScoreMapping,
      totalPercentile: totalPercentile,
      totalScore: totalScore,
      mathPercentile: mathPercentile,
      verbalPercentile: verbalPercentile,
      readingPercentile: readingPercentile,
    });

    await practicesRef.doc(practice.id).update({
      omittedCount: omitCountTotal,
      correctCount: correctCountTotal,
      notCorrectCount: notCorrectCountTotal,
      correctCountReading: correctCountReading,
      correctCountVerbal: correctCountVerbal,
      correctCountQ1: correctCountQ1,
      correctCountQ2: correctCountQ2,
      notCorrectCountReading: notCorrectCountReading,
      notCorrectCountVerbal: notCorrectCountVerbal,
      notCorrectCountQ1: notCorrectCountQ1,
      notCorrectCountQ2: notCorrectCountQ2,
      omitCountReading: omitCountReading,
      omitCountVerbal: omitCountVerbal,
      omitCountQ1: omitCountQ1,
      omitCountQ2: omitCountQ2,
      mathQuestionTypeCount: mathQuestionTypeCount,
      verbalQuestionTypeCount: verbalQuestionTypeCount,
      readingQuestionTypeCount: readingQuestionTypeCount,
      readingRawScore: readingRawScore,
      mathRawScore: mathRawScore,
      verbalRawScore: verbalRawScore,
      readingScoreMapping: readingScoreMapping,
      verbalScoreMapping: verbalScoreMapping,
      mathScoreMapping: mathScoreMapping,
      totalPercentile: totalPercentile,
      totalScore: totalScore,
      mathPercentile: mathPercentile,
      verbalPercentile: verbalPercentile,
      readingPercentile: readingPercentile,
    });

    return;
  },
  getOpenSection: async (practiceData, state) => {
    let returnData = {};
    const timestampNow = await firebase.firestore.Timestamp.now();
    let baseTimestampSecond =
      practiceData.practice.practiceBeginTimestamp.seconds;
    const practiceInfo = practiceData.practice;

    if (
      practiceData.practice.isOneDayPractice !== 'true' ||
      practiceData.practice.isOneDayPractice === ''
    ) {
      if (practiceData.status === 'ASSIGNED') {
        baseTimestampSecond = timestampNow.seconds;
      }
      if (practiceData.status === 'ATTENDED') {
        baseTimestampSecond = practiceData.attendedDatetime.seconds;
      }
    }

    if (practiceData.status === 'ASSIGNED') {
      await practicesRef.doc(practiceData.id).update({
        status: 'ATTENDED',
        statusDatetime: serverTimestamp(),
        attendedDatetime: serverTimestamp(),
      });
      returnData.isChangeStatus = true;
      returnData.statusDatetime = timestampNow;
      returnData.status = 'ATTENDED';
    }

    const passedTimes = timestampNow.seconds - baseTimestampSecond;
    let sectionRemainTime = 0;
    let currentSection = {};

    /* Writing */
    sectionRemainTime = passedTimes - practiceInfo.writingSectionTimeSecond;
    if (sectionRemainTime < 0) {
      returnData.sectionRemainTime = sectionRemainTime * -1;
      returnData.sectionName = state.sectionsNames[0];
      currentSection = await state.sectionsList.find(
        (section) => section.section === state.sectionsNames[0],
      );
      returnData.currentSection =
        currentSection && Object.keys(currentSection).length > 0
          ? currentSection
          : {};
      returnData.isOneday = practiceData.practice.isOneDayPractice === 'true';
      returnData.status = practiceData.status;
      returnData.isChangeStatus = false;
      returnData.isOverPassedTimes = false;
      await practicesCollection.setRemainTimeSecond(
        state.currentPractice,
        state.sectionsNames[0],
        sectionRemainTime * -1,
      );
      return returnData;
    }

    /* Writing Break*/
    sectionRemainTime =
      sectionRemainTime - practiceInfo.writingSectionBreakTimeSecond;
    if (sectionRemainTime < 0) {
      returnData.sectionRemainTime = sectionRemainTime * -1;
      returnData.sectionName = state.sectionsNames[1];
      currentSection = state.sectionsList.find(
        (section) => section.section === state.sectionsNames[0],
      );
      returnData.currentSection =
        currentSection && Object.keys(currentSection).length > 0
          ? currentSection
          : {};
      returnData.isOneday = practiceData.practice.isOneDayPractice === 'true';
      returnData.status = practiceData.status;
      returnData.isChangeStatus = false;
      returnData.isOverPassedTimes = false;
      await practicesCollection.setRemainTimeSecond(
        state.currentPractice,
        state.sectionsNames[1],
        sectionRemainTime * -1,
      );
      return returnData;
    }

    /* Q1 */
    sectionRemainTime = sectionRemainTime - practiceInfo.Q1SectionTimeSecond;
    if (sectionRemainTime < 0) {
      returnData.sectionRemainTime = sectionRemainTime * -1;
      returnData.sectionName = state.sectionsNames[2];
      currentSection = await state.sectionsList.find(
        (section) => section.section === state.sectionsNames[2],
      );
      returnData.currentSection =
        currentSection && Object.keys(currentSection).length > 0
          ? currentSection
          : {};
      returnData.isOneday = practiceData.practice.isOneDayPractice === 'true';
      returnData.status = practiceData.status;
      returnData.isChangeStatus = false;
      returnData.isOverPassedTimes = false;
      await practicesCollection.setRemainTimeSecond(
        state.currentPractice,
        state.sectionsNames[2],
        sectionRemainTime * -1,
      );
      return returnData;
    }

    /* Q1 Break */
    sectionRemainTime =
      sectionRemainTime - practiceInfo.Q1SectionTimeBreakSecond;
    if (sectionRemainTime < 0) {
      returnData.sectionRemainTime = sectionRemainTime * -1;
      returnData.sectionName = state.sectionsNames[3];
      currentSection = await state.sectionsList.find(
        (section) => section.section === state.sectionsNames[2],
      );
      returnData.currentSection =
        currentSection && Object.keys(currentSection).length > 0
          ? currentSection
          : {};
      returnData.isOneday = practiceData.practice.isOneDayPractice === 'true';
      returnData.status = practiceData.status;
      returnData.isChangeStatus = false;
      returnData.isOverPassedTimes = false;
      await practicesCollection.setRemainTimeSecond(
        state.currentPractice,
        state.sectionsNames[3],
        sectionRemainTime * -1,
      );
      return returnData;
    }

    /* Reading */
    sectionRemainTime =
      sectionRemainTime - practiceInfo.readingSectionTimeSecond;
    if (sectionRemainTime < 0) {
      returnData.sectionRemainTime = sectionRemainTime * -1;
      returnData.sectionName = state.sectionsNames[4];
      currentSection = await state.sectionsList.find(
        (section) => section.section === state.sectionsNames[4],
      );
      returnData.currentSection =
        currentSection && Object.keys(currentSection).length > 0
          ? currentSection
          : {};
      returnData.isOneday = practiceData.practice.isOneDayPractice === 'true';
      returnData.status = practiceData.status;
      returnData.isChangeStatus = false;
      returnData.isOverPassedTimes = false;
      await practicesCollection.setRemainTimeSecond(
        state.currentPractice,
        state.sectionsNames[4],
        sectionRemainTime * -1,
      );
      return returnData;
    }

    /* Reading Break */
    sectionRemainTime =
      sectionRemainTime - practiceInfo.readingSectionBreakTimeSecond;
    if (sectionRemainTime < 0) {
      returnData.sectionRemainTime = sectionRemainTime * -1;
      returnData.sectionName = state.sectionsNames[5];
      currentSection = await state.sectionsList.find(
        (section) => section.section === state.sectionsNames[4],
      );
      returnData.currentSection =
        currentSection && Object.keys(currentSection).length > 0
          ? currentSection
          : {};
      returnData.isOneday = practiceData.practice.isOneDayPractice === 'true';
      returnData.status = practiceData.status;
      returnData.isChangeStatus = false;
      returnData.isOverPassedTimes = false;
      await practicesCollection.setRemainTimeSecond(
        state.currentPractice,
        state.sectionsNames[5],
        sectionRemainTime * -1,
      );
      return returnData;
    }

    /* Verbal */
    sectionRemainTime =
      sectionRemainTime - practiceInfo.verbalSectionTimeSecond;
    if (sectionRemainTime < 0) {
      returnData.sectionRemainTime = sectionRemainTime * -1;
      returnData.sectionName = state.sectionsNames[6];
      currentSection = await state.sectionsList.find(
        (section) => section.section === state.sectionsNames[6],
      );
      returnData.currentSection =
        currentSection && Object.keys(currentSection).length > 0
          ? currentSection
          : {};
      returnData.isOneday = practiceData.practice.isOneDayPractice === 'true';
      returnData.status = practiceData.status;
      returnData.isChangeStatus = false;
      returnData.isOverPassedTimes = false;
      await practicesCollection.setRemainTimeSecond(
        state.currentPractice,
        state.sectionsNames[6],
        sectionRemainTime * -1,
      );
      return returnData;
    }

    /* Verbal Break */
    sectionRemainTime =
      sectionRemainTime - practiceInfo.verbalSectionBreakTimeSecond;
    if (sectionRemainTime < 0) {
      returnData.sectionRemainTime = sectionRemainTime * -1;
      returnData.sectionName = state.sectionsNames[7];
      currentSection = await state.sectionsList.find(
        (section) => section.section === state.sectionsNames[6],
      );
      returnData.currentSection =
        currentSection && Object.keys(currentSection).length > 0
          ? currentSection
          : {};
      returnData.isOneday = practiceData.practice.isOneDayPractice === 'true';
      returnData.status = practiceData.status;
      returnData.isChangeStatus = false;
      returnData.isOverPassedTimes = false;
      await practicesCollection.setRemainTimeSecond(
        state.currentPractice,
        state.sectionsNames[7],
        sectionRemainTime * -1,
      );

      return returnData;
    }

    /* Q2 */
    sectionRemainTime = sectionRemainTime - practiceInfo.Q2SectionTimeSecond;
    if (sectionRemainTime < 0) {
      returnData.sectionRemainTime = sectionRemainTime * -1;
      returnData.sectionName = state.sectionsNames[8];
      currentSection = await state.sectionsList.find(
        (section) => section.section === state.sectionsNames[8],
      );
      returnData.currentSection =
        currentSection && Object.keys(currentSection).length > 0
          ? currentSection
          : {};
      returnData.isOneday = practiceData.practice.isOneDayPractice === 'true';
      returnData.status = practiceData.status;
      returnData.isChangeStatus = false;
      returnData.isOverPassedTimes = false;
      await practicesCollection.setRemainTimeSecond(
        state.currentPractice,
        state.sectionsNames[8],
        sectionRemainTime * -1,
      );
      return returnData;
    }

    /* Q2 Break */
    // sectionRemainTime =
    //   sectionRemainTime - practiceInfo.Q2SectionTimeBreakSecond;
    // if (sectionRemainTime < 0) {
    //   returnData.sectionRemainTime = sectionRemainTime * -1;
    //   returnData.sectionName = state.sectionsNames[9];
    //   currentSection = await state.sectionsList.find(
    //     (section) => section.section === state.sectionsNames[8],
    //   );
    //   returnData.currentSection =
    //     currentSection && Object.keys(currentSection).length > 0
    //       ? currentSection
    //       : {};
    //   returnData.isOneday = practiceData.practice.isOneDayPractice === 'true';
    //   returnData.status = practiceData.status;
    //   returnData.isChangeStatus = false;
    //   returnData.isOverPassedTimes = false;
    //   await practicesCollection.setRemainTimeSecond(
    //     state.currentPractice,
    //     state.sectionsNames[9],
    //     sectionRemainTime * -1,
    //   );
    //   return returnData;
    // }

    returnData.currentSection = {};
    returnData.isOverPassedTimes = true;

    return returnData;
  },
  // getOpenSection: async (practiceData, state) => {
  //   let returnData = {};
  //   const timestampNow = await firebase.firestore.Timestamp.now();
  //   let baseTimestampSecond =
  //     practiceData.practice.practiceBeginTimestamp.seconds;

  //   if (
  //     practiceData.practice.isOneDayPractice !== 'true' ||
  //     practiceData.practice.isOneDayPractice == ''
  //   ) {
  //     if (practiceData.status === 'ASSIGNED') {
  //       baseTimestampSecond = timestampNow.seconds;
  //     }
  //     if (practiceData.status === 'ATTENDED') {
  //       baseTimestampSecond = practiceData.statusDatetime.seconds;
  //     }
  //   }

  //   const passedTimes = timestampNow.seconds - baseTimestampSecond;
  //   let sectionRemainTime = 0;
  //   let sectionName = '';
  //   let tempRemainTime = passedTimes;
  //   let currentSection = {};
  //   let sumTimeLimit = state.sectionsTimeLimit.reduce((sum, a) => sum + a, 0);

  //   if (passedTimes > 0) {
  //     for (let i = 0; i < state.sectionsTimeLimit.length; i++) {
  //       const sectionTime = state.sectionsTimeLimit[i];

  //       if (tempRemainTime - sectionTime > 0) {
  //         tempRemainTime = tempRemainTime - sectionTime;
  //       } else {
  //         sectionRemainTime = sectionTime - tempRemainTime;
  //         sectionName = state.sectionsNames[i];

  //         currentSection = state.sectionsList.find(
  //           (section) => section.section === sectionName,
  //         );

  //         if (currentSection == undefined) {
  //           currentSection = {};
  //         }

  //         break;
  //       }
  //     }
  //   } else {
  //     sectionName = state.sectionsNames[0];
  //     currentSection = state.sectionsList.find(
  //       (section) => section.section === sectionName,
  //     );
  //   }

  //   returnData.sectionRemainTime = sectionRemainTime;
  //   returnData.sectionName = sectionName;
  //   returnData.currentSection =
  //     Object.keys(currentSection).length > 0 ? currentSection : {};
  //   returnData.isOverPassedTimes = passedTimes > sumTimeLimit;
  //   returnData.isOneday = practiceData.practice.isOneDayPractice === 'true';
  //   returnData.status = practiceData.status;
  //   returnData.isChangeStatus = false;

  //   if (practiceData.status === 'ASSIGNED') {
  //     practicesRef
  //       .doc(practiceData.id)
  //       .update({status: 'ATTENDED', statusDatetime: serverTimestamp()});
  //     returnData.isChangeStatus = true;
  //     returnData.statusDatetime = timestampNow;
  //     returnData.status = 'ATTENDED';
  //   }

  //   return returnData;
  // },
  updateWritingOption: (option, currentQuestion) => {
    practicesQuestionRef
      .doc(currentQuestion.baseId)
      .update({selectedOption: option});
  },
  updateWritingEssay: (essayText, currentQuestion) => {
    practicesQuestionRef
      .doc(currentQuestion.baseId)
      .update({writedEssay: essayText ? essayText : ''});
  },
  setFinishedPractices: async (practiceData) => {
    practicesRef
      .doc(practiceData.id)
      .update({status: 'FINISHED', statusDatetime: serverTimestamp()});
  },
};
