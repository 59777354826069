import React, {useCallback, useState, useEffect} from 'react';
import {IconButton} from '@mui/material';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import AppScrollbar from '@crema/core/AppScrollbar';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useSelector} from 'react-redux';
import CartItem from './CartItem';
import DiscountSum from './DiscountSum';
import {moneyFormat2} from 'util/common';
import {useNavigate} from 'react-router-dom';
import {productsCollection} from 'services/productsCollection';

const CartContent = ({onClose, sxStyle}) => {
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart);
  const products = useSelector((state) => state.firestore.ordered.products);
  const [subTotal, setSubTotal] = useState(0);
  const [discountTotal, setDiscountTotal] = useState(0);

  productsCollection.getActiveProducts();

  useEffect(() => {
    getSubtotal();
  }, [cart]);

  const getSubtotal = useCallback(() => {
    let values = 0;
    let sumCartItemDiscount = 0;
    let middlePracticeCount = 0;
    let upperPracticeCount = 0;
    let middleSuggestCount = 0;
    let upperSuggestCount = 0;
    let discountSubTotal = 0;

    cart.cartItems.map((item) => {
      values =
        values +
        Number(item.amount) -
        (item.discount ? Number(item.discount) : 0);

      sumCartItemDiscount = item.discount
        ? sumCartItemDiscount + Number(item.discount)
        : sumCartItemDiscount;

      if (item.level === 'Upper') {
        if (item.type === 'package') {
          upperPracticeCount += Number(item.practiceCount);
        }
        if (
          item.type === 'suggest' ||
          (item.type === 'package' && Number(item.suggestCount) > 0)
        ) {
          upperSuggestCount += Number(item.suggestCount);
        }
      } else if (item.level === 'Middle') {
        if (item.type === 'package') {
          middlePracticeCount += Number(item.practiceCount);
        }
        if (
          item.type === 'suggest' ||
          (item.type === 'package' && Number(item.suggestCount) > 0)
        ) {
          middleSuggestCount += Number(item.suggestCount);
        }
      }
    });

    while (middlePracticeCount > 0 || upperPracticeCount > 0) {
      let middlePackage = products.filter(
        (element) =>
          Number(element.practiceCount) == middlePracticeCount &&
          Number(element.suggestCount) == middleSuggestCount &&
          element.level === 'Middle',
      );

      middlePracticeCount =
        middlePackage.length == 0
          ? 0
          : middlePracticeCount - Number(middlePackage[0].practiceCount);
      middleSuggestCount =
        middlePackage.length == 0
          ? 0
          : middleSuggestCount - Number(middlePackage[0].suggestCount);

      let upperPackage = products.filter(
        (element) =>
          Number(element.practiceCount) == upperPracticeCount &&
          Number(element.suggestCount) == upperSuggestCount &&
          element.level === 'Upper',
      );

      upperPracticeCount =
        upperPackage.length == 0
          ? 0
          : upperPracticeCount - Number(upperPackage.practiceCount);
      upperSuggestCount =
        upperPackage.length == 0
          ? 0
          : upperSuggestCount - Number(upperPackage.suggestCount);

      discountSubTotal =
        discountSubTotal + middlePackage.length
          ? middlePackage.reduce((pre, cur) => pre + Number(cur.discount), 0)
          : 0 + upperPackage
          ? upperPackage.reduce((pre, cur) => pre + Number(cur.discount), 0)
          : 0;
    }

    setDiscountTotal(discountSubTotal - sumCartItemDiscount);
    setSubTotal(
      values -
        (discountSubTotal > 0 ? discountSubTotal - sumCartItemDiscount : 0),
    );
  }, [cart]);

  const goToCheckout = () => {
    navigate('checkout');
    onClose();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 380,
        height: '100%',
        ...sxStyle,
      }}
    >
      <Box
        sx={{
          padding: '5px 20px',
          display: 'flex',
          alignItems: 'center',
          borderBottom: 1,
          borderBottomColor: (theme) => theme.palette.divider,
          minHeight: {xs: 56, sm: 70},
        }}
      >
        <Typography component='h3' variant='h3'>
          Your Cart ({cart.cartItems.length > 0 ? cart.cartItems.length : 0})
        </Typography>
        <IconButton
          sx={{
            height: 40,
            width: 40,
            marginLeft: 'auto',
            color: 'text.secondary',
          }}
          onClick={onClose}
          size='large'
        >
          <CancelOutlinedIcon />
        </IconButton>
      </Box>
      <AppScrollbar
        sx={{
          height: {xs: 'calc(100% - 96px)', sm: 'calc(100% - 110px)'},
        }}
      >
        <List sx={{py: 0}}>
          {cart.cartItems.map((item, index) => (
            <CartItem key={index} item={item} index={index} />
          ))}
          {discountTotal > 0 ? <DiscountSum discount={discountTotal} /> : ``}
        </List>
      </AppScrollbar>
      <Box
        sx={{
          pr: 10,
          pl: 10,
          pb: 5,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography component='h2' variant='h2'>
            Subtotal
          </Typography>
        </Box>
        <Box>
          <Typography component='h2' variant='h2'>
            {moneyFormat2(subTotal * 1000)}
          </Typography>
        </Box>
      </Box>
      <Button
        sx={{
          borderRadius: 0,
          width: '100%',
          textTransform: 'capitalize',
          marginTop: 'auto',
          height: 80,
        }}
        variant='contained'
        color='primary'
        onClick={goToCheckout}
      >
        <Typography component='h1' variant='h1'>
          CHECKOUT
        </Typography>
      </Button>
    </Box>
  );
};

export default CartContent;

CartContent.propTypes = {
  onClose: PropTypes.func,
  sxStyle: PropTypes.object,
};
