import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import Settings from './Setting';
import Common from './Common';
import Cart from './Cart';
import Assessment from './Assessment';
import Analysis from './Analysis';
import Practice from './Practice';
import Student from './Student';

import {firebaseReducer} from 'react-redux-firebase';
import {firestoreReducer} from 'redux-firestore';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    common: Common,
    cart: Cart,
    assessment: Assessment,
    analysis: Analysis,
    practice: Practice,
    student: Student,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
  });
export default reducers;
