/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React from 'react';
import Box from '@mui/material/Box';
// import {Button} from '@mui/material';
import {useLayoutContext} from '../../../../utility/AppContextProvider/LayoutContextProvider';
import Typography from '@mui/material/Typography';
import FooterWrapper from './FooterWrapper';
import Cart from '../../../../../pages/member/Cart';
import AppGridContainer from '@crema/core/AppGridContainer';
import Grid from '@mui/material/Grid';

const AppFooter = () => {
  const {footer, footerType, navStyle} = useLayoutContext();

  return (
    <>
      {footer &&
      footerType === 'fluid' &&
      navStyle !== 'h-default' &&
      navStyle !== 'hor-light-nav' &&
      navStyle !== 'hor-dark-layout' ? (
        <FooterWrapper className='footer'>
          {/* <div className='footerContainer'>
            <Typography>©BEST SSAT PREP</Typography> 
            <Box sx={{ml: 'auto'}}>
              <Cart />
            </Box>
          </div> */}

          <Box sx={{p: 5}}>
            <Box sx={{display: 'flex', justifyContent: 'space-around'}}>
              <Typography sx={{color: '#888888', fontWeight: '700'}}>
                © BEST SSAT PREP
              </Typography>
            </Box>
            <AppGridContainer spacing={4}>
              <Grid container item xs={12} md={3} />
              <Grid
                item
                xs={12}
                md={6}
                sx={{textAlign: 'center', fontSize: '12px', color: '#888888'}}
              >
                Issue Report & Questions : support@bestssatprep.com
              </Grid>
            </AppGridContainer>
            &nbsp;
            <AppGridContainer spacing={4}>
              <Grid container item xs={12} md={3} />
              <Grid
                item
                xs={12}
                md={6}
                sx={{textAlign: 'center', fontSize: '12px', color: '#888888'}}
              >
                All content on this site, including the assessments and all items comprising them, are confidential and proprietary. They may not be copied, distributed, or otherwise disclosed to any third party without the prior written consent of BEST SSAT PREP.
              </Grid>
            </AppGridContainer>
          </Box>
        </FooterWrapper>
      ) : null}
    </>
  );
};

export default AppFooter;
