/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import {useFirestoreConnect} from 'react-redux-firebase';
import {getAuth} from 'firebase/auth';
import {db} from '../@crema/services/auth/firebase/firebase';
import {serverTimestamp} from 'firebase/firestore';
import {writeOrderProcessLog} from 'services/utilCollections';
import {generateMathDisplay} from 'util/math';
import {
  TEST_STATUSES,
  SECTION_STATUSES,
  ASSESSMENT_STATUSES,
  VALID_SECTION_NAMES,
} from '../util/constants';

import ReadingTestMapping from 'pages/member/Tests/Analysis/data/reading_test_mapping.json';
import QuantitativeTestMapping from 'pages/member/Tests/Analysis/data/quantitative_test_mapping.json';
import VerbalTestMapping from 'pages/member/Tests/Analysis/data/verbal_test_mapping.json';
import { DesktopAccessDisabledTwoTone } from '@mui/icons-material';
import { forEach } from 'lodash';
import { getAnalytics, logEvent } from 'firebase/analytics';
import {RETAKE_LIMIT} from 'shared/constants/AppConst.js';

const COLLECTION = {
  SECTIONS: 'sections',
  TESTS: 'tests',
  USERS: 'users',
  TEMP: 'temp',
  QUESTIONS: 'questions',
  SUGGESTED: 'suggested',
  RETAKEHISTORY: 'retakehistory',
  USERTESTS: 'user-tests',
  TESTSSECTION: 'user-tests-section',
  TESTSQUESTION: 'user-tests-section-question',  
};

const testsRef = db.collection(COLLECTION.TESTS);
const sectionsRef = db.collection(COLLECTION.SECTIONS);
const usersRef = db.collection(COLLECTION.USERS);
const questionsRef = db.collection(COLLECTION.QUESTIONS);
const suggestedRef = db.collection(COLLECTION.SUGGESTED);
const retakeHistoryRef = db.collection(COLLECTION.RETAKEHISTORY);
const usertestsRef = db.collection(COLLECTION.USERTESTS);
const testssectionRef = db.collection(COLLECTION.TESTSSECTION);
const testsquestionRef = db.collection(COLLECTION.TESTSQUESTION);

const auth = getAuth();

const analytics = getAnalytics();

const isUserExist = async (uid) => {
  let isExist = false;

  try {
    await usersRef
      .doc(uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          isExist = true;
        }
      });
  } catch (err) {
    console.log(err);
  }

  return isExist;
};

const getUserInfo = async (uid) => {
  let userData = {};

  try {
    await usersRef
      .doc(uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          userData = doc.data();
        }
      });
  } catch (err) {
    console.log(err);
  }

  return userData;
};

const getSampleTests = async () => {
  let sampleTests = [];

  try {
    await testsRef
      .where('isFree', '==', 'true')
      .where('isActive', '==', 'true')
      .get()
      .then((tests) => {
        tests.forEach((test) => {
          sampleTests.push(test.data());
        });
      });
  } catch (err) {
    console.log(err);
  }

  return sampleTests;
};

const getLevelSampleTests = async (level) => {
  let sampleTests = [];

  try {
    await testsRef
      .where('isFree', '==', 'true')
      .where('isActive', '==', 'true')
      .where('level', '==', level)
      .get()
      .then((tests) => {
        tests.forEach((test) => {
          sampleTests.push(test.data());
        });
      });
  } catch (err) {
    console.log(err);
  }

  return sampleTests;
};

const getTest = async (baseId) => {
  let testInfo = {};

  try {
    await testsRef
      .doc(baseId)
      .get()
      .then((test) => {
        testInfo = test.data();
      });
  } catch (err) {
    console.log(err);
  }

  return testInfo;
};

const getSectionInfo = async (baseId) => {
  let sectionInfo = {};

  try {
    await sectionsRef
      .doc(baseId)
      .get()
      .then((section) => {
        sectionInfo = section.data();
      });
  } catch (err) {
    console.log(err);
  }

  return sectionInfo;
};

const getQuestionInfo = async (baseId) => {
  let questionInfo = {};

  try {
    await questionsRef
      .where('baseId', '==', baseId)
      .get()
      .then((question) => {
        for (const doc of question.docs) {
          questionInfo = doc.data();
        }
      });
  } catch (err) {
    console.log(err);
  }

  return questionInfo;
};

const setTestStatus = async (testId) => {
  let resultMsg = '';
  let isUnattempted = false;
  let isInprogress = false;
  let isCompleted = false;
  let isArchived = false;
  let testStatus = TEST_STATUSES.UNATTEMPTED;
  let history = {
    label: '',
    datetime: serverTimestamp(),
  };

  if (typeof testId == 'undefined') return `Parameter missing.`;

  const userSectionRef = db.collection(
    `${COLLECTION.USERS}/${auth.currentUser.uid}/${COLLECTION.TESTS}/${testId}/${COLLECTION.SECTIONS}`,
  );

  try {
    await userSectionRef.get(testId).then((snapshot) => {
      for (const doc of snapshot.docs) {
      // snapshot.forEach((doc) => {
        let docData = doc.data();

        switch (docData.status) {
          case SECTION_STATUSES.UNATTEMPTED:
            isUnattempted = true;
            break;
          case SECTION_STATUSES.IN_PROGRESS:
            isInprogress = true;
            break;
          case SECTION_STATUSES.COMPLETED:
            isCompleted = true;
            break;
          case SECTION_STATUSES.ARCHIVED:
            isArchived = true;
            break;
        }
      }
      // });
    });

    if (isInprogress) {
      testStatus = TEST_STATUSES.IN_PROGRESS;
      history.label = `Test status is changed to 'In progress'.`;
    } else if (isCompleted) {
      if (isUnattempted) {
        testStatus = TEST_STATUSES.IN_PROGRESS;
        history.label = `Test status is changed to 'In progress'.`;
      } else {
        testStatus = TEST_STATUSES.COMPLETED;
        history.label = `Test status is changed to 'Completed'.`;
      }
    } else if (isArchived) {
      if (isUnattempted) {
        testStatus = TEST_STATUSES.IN_PROGRESS;
        history.label = `Test status is changed to 'In progress'.`;
      } else {
        testStatus = TEST_STATUSES.ARCHIVED;
        history.label = `Test status is changed to 'Archived'.`;
      }
    } else if (isUnattempted) {
      testStatus = TEST_STATUSES.UNATTEMPTED;
      history.label = `Test status is changed to 'Unattempted'.`;
    } else {
      testStatus = TEST_STATUSES.UNATTEMPTED;
      history.label = `Test status is changed to 'Unattempted'.`;
    }

    const userTestRef = db.collection(
      `${COLLECTION.USERS}/${auth.currentUser.uid}/${COLLECTION.TESTS}`,
    );

    userTestRef
      .doc(testId)
      .collection('history')
      .add(history);

    userTestRef
      .doc(testId)
      .update({status: testStatus, statusDate: serverTimestamp()})
      .then(() => {
        resultMsg = `Test status update successfully.`;
      });
  } catch (err) {
    resultMsg = err;
    console.log(err);
  }

  return resultMsg;
};

export const putTestsToUser = async (uid, testId, isSample = false) => {
  // logEvent(analytics, `${testId}`, {uid: uid, testId: testId, isSample: isSample});
  const userData = await getUserInfo(uid);
  // logEvent(analytics, `${testId}:${userData.displayName}`, {displayNmae: userData.displayName, email: userData.email, gender: userData.gender, grade: userData.grade});

  if (isSample) {
    userData.gender = '';
    userData.grade = 0;
  } else {
    if (userData.gender === '' || typeof userData.gender == 'undefined')
      return 'There is no gender in user information';

    if (
      userData.grade < 5 ||
      userData.grade > 12 ||
      typeof userData.grade == 'undefined'
    )
      return 'Check grade in user information';
  }

  if (userData && uid) {
    let batch = db.batch();
    let testInfo = {};
    testInfo = await getTest(testId);
    // logEvent(analytics, `${testId}:${testInfo.name}`, {testId: testId, name: testInfo.name, price: testInfo.price, sectionCount: testInfo.sections.size});

    if (!testInfo) {
      console.log(`There is no test information(${testId}).`);
      return `There is no test information(${testId}).`;
    }

    testInfo.status = TEST_STATUSES.UNATTEMPTED;
    testInfo.gender = userData.gender ? userData.gender : 'MALE';
    testInfo.grade = userData.grade ? userData.grade : 5;
    testInfo.retakeCount = 0;
    testInfo.retakeLimit = RETAKE_LIMIT;

    let testRef = usersRef.doc(`${uid}/${COLLECTION.TESTS}/${testInfo.id}`,);

    writeOrderProcessLog(
      auth.currentUser.uid,
      testId,
      'set test',
      testInfo,
    );
  
    try {    
      // await testRef.set(testInfo);
      batch.set(testRef, testInfo);
  
      for await (const section of testInfo.sections) {
        const sectionInfo = await getSectionInfo(section);
        // logEvent(analytics, `${testId}:${section}`, {testId: testId, sectionId: section, name: sectionInfo.name, level: sectionInfo.level, questionCount: sectionInfo.questions.size});      
  
        sectionInfo.questionsTotal = sectionInfo.questions.length;
        sectionInfo.questionsAnswered = 0;
        sectionInfo.timeLeft = sectionInfo.givenTime * 60 * 1000;
        sectionInfo.givenTime = sectionInfo.givenTime * 60 * 1000;
        sectionInfo.status = SECTION_STATUSES.UNATTEMPTED;
        sectionInfo.assessmentStatus = ``;
        sectionInfo.createdAt = serverTimestamp();
  
        switch (sectionInfo.section) {
          case VALID_SECTION_NAMES.QUANTITATIVE_1:
            sectionInfo.sortOrder = 1;
            break;
          case VALID_SECTION_NAMES.QUANTITATIVE_2:
            sectionInfo.sortOrder = 4;
            break;
          case VALID_SECTION_NAMES.READING:
            sectionInfo.sortOrder = 2;
            break;
          case VALID_SECTION_NAMES.VERBAL:
            sectionInfo.sortOrder = 3;
            break;
          case VALID_SECTION_NAMES.WRITING:
            sectionInfo.sortOrder = 0;
            break;
          default:
            sectionInfo.sortOrder = 999;
            break;
        }
  
        let sectionRef = usersRef.doc(`${uid}/${COLLECTION.TESTS}/${testInfo.baseId}/${COLLECTION.SECTIONS}/${sectionInfo.baseId}`,);
  
        writeOrderProcessLog(
          auth.currentUser.uid,
          `${testId} ${section}`,
          'set section',
          sectionInfo,
        );
    
        // await sectionRef.set(sectionInfo);
        batch.set(sectionRef, sectionInfo);
        for await (const question of sectionInfo.questions) {
          const questionInfo = await getQuestionInfo(question);
  
          // logEvent(analytics, `${testId}:${section}:${question}`, {testId: testId, sectionId: section, questionId: question, question: questionInfo.question, difficulty: questionInfo.difficulty});
          questionInfo.selectedOption = ``;
  
          let questionRef = usersRef.doc(`${uid}/${COLLECTION.TESTS}/${testInfo.baseId}/${COLLECTION.SECTIONS}/${sectionInfo.baseId}/${COLLECTION.QUESTIONS}/${questionInfo.baseId}`,);
          // await questionRef.set(questionInfo);
          batch.set(questionRef, questionInfo);
        };
      };
    } catch (err) {
      console.log(err);
      writeOrderProcessLog(
        auth.currentUser.uid,
        `${testId}`,
        'test create error',
        err,
      );
      // logEvent(analytics, `${uid}:${testId}:${err}`, {err: err});
    }
    await batch.commit();
  }
};

export const putUserTest = async (userId, testId) => {
  const sections = [];
  const questions = [];
  let batch = db.batch();

  const history = {
    label: 'Assign',
    datetime: serverTimestamp(),
    userId: auth.currentUser.email,
  };

  const testData = (await testsRef.doc(testId).get()).data();

  for (const section of testData.sections) {
    const sectionData = (
      await sectionsRef.doc(section).get()
    ).data();
    sections.push(sectionData);

    for (const question of sectionData.questions) {
      let questionData = (
        await questionsRef.doc(question).get()
      ).data();
      questionData.sectionId = sectionData.id;
      questions.push(questionData);
    }
  }

  const userInfo = (await usersRef.doc(userId).get()).data();
  const baseDocId = `${userInfo.email}-${testId}`;

  try {
    const testInfo = {
      baseId: baseDocId,
      userId: userId,
      testId: testId,
      userFirstName: userInfo.firstName,
      userLastname: userInfo.lastName,
      userEmail: userInfo.email,
      studentPhoneNumber: userInfo.phoneNumber,
      isDeleted: 'false',
      test: testData,
      status: 'ASSIGNED',
      statusDatetime: serverTimestamp(),
      userPracticeId: baseDocId,
      userGrade: userInfo.grade ? userInfo.grade : 0,
      userGender: userInfo.gender
        ? userInfo.gender
        : '',
      isFree: testData.isFree ? testData.isFree : 'false',
      userCountry: userInfo.country ? userInfo.country : '',
      userDisplayName : userInfo.displayName ? userInfo.displayName : '',
      userType : userInfo.userType ? userInfo.displayName : '',
      suggestedType: 'false',
    };

    // const result = await usertestsRef.doc(baseDocId).set(testData);
    const usertestsDocRef = usertestsRef.doc(baseDocId);
    batch.set(usertestsDocRef, testInfo);

    await usertestsRef
    .doc(baseDocId)
    .collection('history')
    .add(history);

    for (const section of sections) {
      const sectionBaseDocId = baseDocId + '-' + section.id;
      section.baseId = sectionBaseDocId;
      section.status = 'INITIAL';
      section.userTestId = baseDocId;

      // await testssectionRef.doc(sectionBaseDocId).set(section);
      const testssectionDocRef = testssectionRef.doc(sectionBaseDocId);
      batch.set(testssectionDocRef, section);
    }   
  
    for (const question of questions) {
      const questionBaseDocId =
        baseDocId + '-' + question.sectionId + '-' + question.baseId;
      const sectionBaseDocId = baseDocId + '-' + question.sectionId;
      question.baseId = questionBaseDocId;
      question.status = 'INITIAL';
      question.userTestId = baseDocId;
      question.sectionId = sectionBaseDocId;

      // await testsquestionRef
      //   .doc(questionBaseDocId)
      //   .set(question);
      const testsquestionDocRef = testsquestionRef.doc(questionBaseDocId);
      batch.set(testsquestionDocRef, question);
    }
  } catch (err) {
    console.log(err);
  }
  await batch.commit();
};

export const putFreeTestToUser = async (uid) => {
  if ((await isUserExist(uid)) && uid) {
    try {
      const sampleTests = await getSampleTests();
      //logEvent(analytics, `putFreeTestToUser`, {sampleTests: sampleTests});      
      for await (const test of sampleTests) {
        //logEvent(analytics, `putFreeTestToUser`, {test: test});
        await putTestsToUser(uid, test.id, true);
      }
    } catch (err) {
      console.log(err);
    }
  }
};

export const putLevelFreeTestToUser = async (level) => {
  if (level) {
    const uid = auth.currentUser.uid;

    try {
      const sampleTests = await getLevelSampleTests(level);
      
      for await (const test of sampleTests) {
        await putTestsToUser(uid, test.id, true);
      }
    } catch (err) {
      console.log(err);
    }
  }
};

export const putBuySuggestQuestions = async (uid, testId) => {
  let history = {
    label: 'Purchase suggest question',
    datetime: serverTimestamp(),
    userId: uid,
    testId: testId,
  };

  if (testId && uid) {
    try {
      await usersRef
        .doc(`${uid}/${COLLECTION.TESTS}/${testId}`)
        .update({suggestedType: 'true'})
        .then(() => {
          history.result = `Document successfully updated!`;
        })
        .catch((error) => {
          history.result = `Error updating document: ` + error;
        });

      await usersRef
        .doc(`${uid}/${COLLECTION.TESTS}/${testId}`)
        .collection('history')
        .add(history);

      // const userInfo = (await usersRef.doc(uid).get()).data();
      // const baseDocId = `${userInfo.email}-${testId}`;      
      // await usertestsRef.doc(baseDocId).update({suggestedType: 'true'});
    } catch (err) {
      console.log(err);
    };
  }
};

export const setPracticeTests = async () => {
  let returnData = {};
  returnData.userTests = await testsCollection.getTestsInUser();
  returnData.nonUserTests = await testsCollection.getTestsNotInUser();
  return returnData;
};

export const getTestsInfo = async () => {
  const returnData = [];

  const testsData = await testsRef
    .where('isActive', '==', 'true')
    //.orderBy('level','asc')
    //.orderBy('name','asc')    
    .get();
  
  for (const test of testsData.docs) {
    const testData = test.data();
    
    if(testData.isFree === 'true') continue;

    await usersRef
      .doc(auth.currentUser.uid)
      .collection(COLLECTION.TESTS)
      .doc(testData.baseId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          testData.suggestedType = doc.data().suggestedType ? doc.data().suggestedType : 'false';
          testData.isPurchased = true;
        } else {
          testData.isPurchased = false;
        }
      });
    returnData.push(testData);
  }

  return returnData;
};

export const testsCollection = {
  getTestsDataAndListener: () => {
    useFirestoreConnect([
      {
        collection: COLLECTION.TESTS,
        storeAs: COLLECTION.TESTS,
      },
    ]);
  },
  getTestsInUser: async () => {
    const userTests = [];
    
    try {
      const testsData = await usersRef
        .doc(auth.currentUser.uid)
        .collection(COLLECTION.TESTS)
        .get();

      for (const test of testsData.docs) {        
        const testData = test.data();
        let sectionsInTest = [];
        let retakeHistory = [];
        
        const history = await retakeHistoryRef
                        .where(`userUID`, '==', auth.currentUser.uid)
                        .where(`testBaseId`, '==', testData.baseId)
                        .get();

        for (const retake of history.docs) {
          const retakeData = retake.data();

          retakeData.retakeAt 
            = retakeData.retakeDatetime ?
              retakeData
              .retakeDatetime
              .toDate()
              .toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
              }) : '';
          
          retakeHistory.push(retakeData);
        }
                
        retakeHistory.sort((a, b) => { return a.retakeSeq - b.retakeSeq; });

        const sectionsData = await usersRef
          .doc(auth.currentUser.uid)
          .collection(COLLECTION.TESTS)
          .doc(testData.baseId)
          .collection(COLLECTION.SECTIONS)
          .get();

        for (const section of sectionsData.docs) {
          const sectionData = section.data();
        
          sectionsInTest.push(sectionData);
        }
        testData.sectionsList = testData.sections;
        testData.sections = sectionsInTest;
        testData.retakeHistory = retakeHistory;
        if (!testData.retakeLimit) testData.retakeLimit = RETAKE_LIMIT;
        if (!testData.retakeCount) testData.retakeCount = 0;
        
        userTests.push(testData);
      }
        // usersRef
        //   .doc(auth.currentUser.uid)
        //   .collection(COLLECTION.TESTS)
        //   .doc(test.id)
        //   .collection(COLLECTION.SECTIONS)
        //   .get()
        //   .then((sections) => {
        //         let sectionsInTest = [];
        //         sections.forEach((section) => {
        //           let sectionData = section.data();
        //           sectionsInTest.push(sectionData);
        //         });
        //         docData.sections = sectionsInTest;
        //       });
        //     userTests.push(docData);
        //   });
        // });
    } catch (err) {
      console.log(err);
    }

    return userTests;
  },
  getSectionsInTestForUser: async (testId, sections) => {
    const docs = [{id: testId, name: VALID_SECTION_NAMES.WRITING},{id: testId, name: VALID_SECTION_NAMES.QUANTITATIVE_1},{id: testId, name: VALID_SECTION_NAMES.READING},{id: testId, name: VALID_SECTION_NAMES.VERBAL},{id: testId, name: VALID_SECTION_NAMES.QUANTITATIVE_2}];

    if (typeof sections == 'undefined') return docs;

    const userSectionRef = db.collection(
      `${COLLECTION.USERS}/${auth.currentUser.uid}/${COLLECTION.TESTS}/${testId}/${COLLECTION.SECTIONS}`,
    );

    try {
      await userSectionRef
        .where(`baseId`, 'in', sections)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            let docData = doc.data();
            docData.id = docData.baseId;

            if (docData.sortOrder) {
              docs[docData.sortOrder] = docData;
            } else {
              switch (docData.section) {
                case VALID_SECTION_NAMES.QUANTITATIVE_1:
                  docs[1] = docData;
                  break;
                case VALID_SECTION_NAMES.QUANTITATIVE_2:
                  docs[4] = docData;
                  break;
                case VALID_SECTION_NAMES.READING:
                  docs[2] = docData;
                  break;
                case VALID_SECTION_NAMES.VERBAL:
                  docs[3] = docData;
                  break;
                case VALID_SECTION_NAMES.WRITING:
                  docs[0] = docData;
                  break;
                }
            }
            //docs.push(docData);
          });
        });
    } catch (err) {
      console.log(err);
    }

    let filteredDocs = docs.filter(function (el) {
      return el;
    });

    return docs;
    //return filteredDocs;
  },
  getUserTestInfo: async (testId) => {
    let docs = {};

    if (typeof testId == 'undefined') return docs;

    const userSectionRef = db.collection(
      `${COLLECTION.USERS}/${auth.currentUser.uid}/${COLLECTION.TESTS}`,
    );

    try {
      await userSectionRef
        .where(`baseId`, '==', testId)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            docs = doc.data();
          });
        });
    } catch (err) {
      console.log(err);
    }

    return docs;
  },
  getUserSectionInfo: async (testId, sectionId) => {
    let docs = {};

    if (typeof testId == 'undefined') return docs;

    const userSectionRef = db.collection(
      `${COLLECTION.USERS}/${auth.currentUser.uid}/${COLLECTION.TESTS}/${testId}/${COLLECTION.SECTIONS}`,
    );

    try {
      await userSectionRef
        .where(`baseId`, '==', sectionId)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            docs = doc.data();
          });
        });
    } catch (err) {
      console.log(err);
    }

    return docs;
  },
  setSectionStatus: async (testId, sectionId, statusValue) => {
    let resultMsg = '';
    if (
      typeof testId == 'undefined' ||
      typeof sectionId == 'undefined' ||
      !statusValue
    )
      return `Parameter missing.`;

    const userSectionRef = db.collection(
      `${COLLECTION.USERS}/${auth.currentUser.uid}/${COLLECTION.TESTS}/${testId}/${COLLECTION.SECTIONS}`,
    );

    try {
      await userSectionRef
        .doc(sectionId)
        .update({status: statusValue})
        .then(() => {
          resultMsg = `Status update successfully.`;
        });

      await setTestStatus(testId);
    } catch (err) {
      resultMsg = err;
      console.log(err);
    }

    return resultMsg;
  },
  setSectionAssessmentStatus: async (testId, sectionId, statusValue) => {
    let resultMsg = '';
    let sectionStatus = SECTION_STATUSES.UNATTEMPTED;

    if (
      typeof testId == 'undefined' ||
      typeof sectionId == 'undefined' ||
      !statusValue
    )
      return `Parameter missing.`;

    const userSectionRef = db.collection(
      `${COLLECTION.USERS}/${auth.currentUser.uid}/${COLLECTION.TESTS}/${testId}/${COLLECTION.SECTIONS}`,
    );

    try {
      switch (statusValue) {
        case ASSESSMENT_STATUSES.IN_PROGRESS:
          sectionStatus = SECTION_STATUSES.IN_PROGRESS;
          break;
        case ASSESSMENT_STATUSES.PAUSED:
          sectionStatus = SECTION_STATUSES.IN_PROGRESS;
          break;
        case ASSESSMENT_STATUSES.PRE_FINISH:
          sectionStatus = SECTION_STATUSES.COMPLETED;
          break;
        case ASSESSMENT_STATUSES.COMPLETED:
          sectionStatus = SECTION_STATUSES.COMPLETED;
          break;
      }

      await userSectionRef
        .doc(sectionId)
        .update({assessmentStatus: statusValue, status: sectionStatus})
        .then(() => {
          resultMsg = `Status update successfully.`;
        });

      await setTestStatus(testId);
    } catch (err) {
      resultMsg = err;
      console.log(err);
    }

    return resultMsg;
  },
  setSectionAssessmentTime: (testId, sectionId, timeLeft) => {
    let resultMsg = '';
    if (
      typeof testId == 'undefined' ||
      typeof sectionId == 'undefined' ||
      !timeLeft
    )
      return `Parameter missing.`;

    const userSectionRef = db.collection(
      `${COLLECTION.USERS}/${auth.currentUser.uid}/${COLLECTION.TESTS}/${testId}/${COLLECTION.SECTIONS}`,
    );

    try {
      userSectionRef
        .doc(sectionId)
        .update({timeLeft: timeLeft})
        .then(() => {
          resultMsg = `time left update successfully.`;
        });
    } catch (err) {
      resultMsg = err;
      console.log(err);
    }

    return resultMsg;
  },
  getQuestionsInSection: async (testId, sectionId) => {
    let questions = {};
    let docs = [];
    let curQuestionNumber = 0;

    if (typeof testId == 'undefined' || typeof sectionId == 'undefined')
      return questions;

    const userSectionRef = db.collection(
      `${COLLECTION.USERS}/${auth.currentUser.uid}/${COLLECTION.TESTS}/${testId}/${COLLECTION.SECTIONS}/${sectionId}/${COLLECTION.QUESTIONS}`,
    );

    try {
      const snapshot = await userSectionRef.get();

      let questionNumber = 1;
        // for(const doc in snapshot) {

        // };
        snapshot.forEach((doc) => {
          let docData = doc.data();
          docData.id = docData.baseId;
          docData.questionNumber = questionNumber;
          docData.optionC = docData.optionC ? docData.optionC : '';
          docData.optionD = docData.optionD ? docData.optionD : '';
          docData.optionE = docData.optionE ? docData.optionE : '';
          // docData.optionA = generateMathDisplay(docData.optionA);
          // docData.optionB = generateMathDisplay(docData.optionB);
          // docData.optionC ? docData.optionC = generateMathDisplay(docData.optionC) : docData.optionC = '';
          // docData.optionD ? docData.optionD = generateMathDisplay(docData.optionD) : docData.optionD = '';
          // docData.optionE ? docData.optionE = generateMathDisplay(docData.optionE) : docData.optionE = '';
          docs.push(docData);
          if (curQuestionNumber == 0 && docData.selectedOption === '')
            curQuestionNumber = questionNumber;
          questionNumber++;
        });
        questions.curQuestionNumber =
          curQuestionNumber == 0 ? 1 : curQuestionNumber;
        questions.userQuestions = docs;
    } catch (err) {
      console.log(err);
    }

    return questions;
  },
  setSelectedOption: async (testId, sectionId, questionId, selectedOption) => {
    let resultMsg = '';
    if (
      typeof testId == 'undefined' ||
      typeof sectionId == 'undefined' ||
      typeof questionId == 'undefined'
    )
      return `Parameter missing.`;

    const userSectionRef = db.collection(
      `${COLLECTION.USERS}/${auth.currentUser.uid}/${COLLECTION.TESTS}/${testId}/${COLLECTION.SECTIONS}/${sectionId}/${COLLECTION.QUESTIONS}`,
    );

    try {
      userSectionRef
        .doc(questionId)
        .update({selectedOption: selectedOption})
        .then(() => {
          userSectionRef
            .where(`selectedOption`, '!=', '')
            .get()
            .then((snapshot) => {
              const sectionRef = db.collection(
                `${COLLECTION.USERS}/${auth.currentUser.uid}/${COLLECTION.TESTS}/${testId}/${COLLECTION.SECTIONS}`,
              );

              sectionRef
                .doc(sectionId)
                .update({questionsAnswered: snapshot.size});
            });
          resultMsg = `selected Option update successfully.`;
        });
    } catch (err) {
      resultMsg = err;
      console.log(err);
    }

    return resultMsg;
  },
  setTestStatus: async (testId) => {
    let resultMsg = '';
    let isUnattempted = false;
    let isInprogress = false;
    let isCompleted = false;
    let isArchived = false;
    let testStatus = TEST_STATUSES.UNATTEMPTED;

    if (typeof testId == 'undefined') return `Parameter missing.`;

    const userSectionRef = db.collection(
      `${COLLECTION.USERS}/${auth.currentUser.uid}/${COLLECTION.TESTS}/${testId}/${COLLECTION.SECTIONS}`,
    );

    try {
      await userSectionRef.get(testId).then((snapshot) => {
        snapshot.forEach((doc) => {
          let docData = doc.data();

          switch (docData.status) {
            case SECTION_STATUSES.UNATTEMPTED:
              isUnattempted = true;
              break;
            case SECTION_STATUSES.IN_PROGRESS:
              isInprogress = true;
              break;
            case SECTION_STATUSES.COMPLETED:
              isCompleted = true;
              break;
            case SECTION_STATUSES.ARCHIVED:
              isArchived = true;
              break;
          }
        });
      });

      if (isInprogress) testStatus = TEST_STATUSES.IN_PROGRESS;
      else if (isCompleted) {
        if (isUnattempted) testStatus = TEST_STATUSES.IN_PROGRESS;
        else testStatus = TEST_STATUSES.COMPLETED;
      } else if (isArchived) {
        if (isUnattempted) testStatus = TEST_STATUSES.IN_PROGRESS;
        else testStatus = TEST_STATUSES.ARCHIVED;
      } else if (isUnattempted) testStatus = TEST_STATUSES.UNATTEMPTED;
      else testStatus = TEST_STATUSES.UNATTEMPTED;

      const userTestRef = db.collection(
        `${COLLECTION.USERS}/${auth.currentUser.uid}/${COLLECTION.TESTS}`,
      );

      userTestRef
        .doc(testId)
        .update({status: testStatus})
        .then(() => {
          resultMsg = `Test status update successfully.`;
        });
    } catch (err) {
      resultMsg = err;
      console.log(err);
    }

    return resultMsg;
  },
  getSectionTimeLeft: async (testId, sectionId) => {
    let timeLeft = 0;

    if (typeof testId == 'undefined' || typeof sectionId == 'undefined')
      return timeLeft;

    const userSectionRef = db.collection(
      `${COLLECTION.USERS}/${auth.currentUser.uid}/${COLLECTION.TESTS}/${testId}/${COLLECTION.SECTIONS}`,
    );

    try {
      await userSectionRef
        .where(`baseId`, '==', sectionId)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            let sectionData = doc.data();
            timeLeft = sectionData.timeLeft;
          });
        });
    } catch (err) {
      console.log(err);
    }

    return timeLeft;
  },
  setSectionTimeLeft: async (testId, sectionId, timeLeft) => {
    let resultMsg = '';

    if (
      typeof testId == 'undefined' ||
      typeof sectionId == 'undefined' ||
      typeof timeLeft == 'undefined'
    )
      return 'Parameter error';

    const userSectionRef = db.collection(
      `${COLLECTION.USERS}/${auth.currentUser.uid}/${COLLECTION.TESTS}/${testId}/${COLLECTION.SECTIONS}`,
    );

    try {
      await userSectionRef
        .doc(sectionId)
        .update({timeLeft: timeLeft})
        .then(() => {
          resultMsg = `Test status update successfully.`;
        });
    } catch (err) {
      resultMsg = err;
      console.log(err);
    }

    return resultMsg;
  },
  getTestsNotInUser: async () => {
    const userTests = [];
    const allTests = [];
    const notInUserTests = [];
    try {
      await testsRef.get().then((tests) => {
        tests.forEach((test) => {
          allTests.push(test.data().baseId);
        });
      });

      await usersRef
        .doc(auth.currentUser.uid)
        .collection(COLLECTION.TESTS)
        .get()
        .then((tests) => {
          tests.forEach((test) => {
            userTests.push(test.data().baseId);
          });
        });

      const notInUserTestsArray = allTests.filter(al =>
        userTests.findIndex(ul =>
          JSON.stringify(ul) == JSON.stringify(al)) == -1
      );

      if (userTests.length == 0) {
        userTests.push('empty-ID');
      }

      if(notInUserTestsArray.length > 0) {
        for (const test of notInUserTestsArray) {
          await testsRef
            .where(`baseId`, '==', test)
            .get()
            .then((tests) => {
              tests.forEach((test) => {
                let docData = test.data();
                if (
                  docData.isActive === `true` &&
                  (docData.isFree ? docData.isFree : 'false') === `false`
                ) {
                  docData.id = docData.baseId;

                  sectionsRef
                    .where(`baseId`, 'in', docData.sections)
                    .get()
                    .then((sections) => {
                      let sectionsInTest = [];
                      sections.forEach((section) => {
                        let sectionData = section.data();
                        sectionData.givenTime = sectionData.givenTime * 60 * 1000;
                        sectionsInTest.push(sectionData);
                      });
                      docData.sections = sectionsInTest;
                    });

                  notInUserTests.push(docData);
                }
              });
            });
          };
        };
    } catch (err) {
      console.log(err);
    }

    return notInUserTests;
  },
  getSectionsInTest: async (baseId) => {
    let sectionsArray = [];
    const sectionsInTest = [];

    try {
      await testsRef
        .where(`baseId`, '==', baseId)
        .get()
        .then((tests) => {
          tests.forEach((test) => {
            sectionsArray = test.data().sections;
          });
        });

      await sectionsRef
        .where(`baseId`, 'in', sectionsArray)
        .get()
        .then((sections) => {
          sections.forEach((section) => {
            let sectionData = section.data();
            //sectionData.givenTime = sectionData.givenTime * 60 * 1000;
            sectionData.id = sectionData.baseId;
            sectionsInTest.push(sectionData);
          });
        });
    } catch (err) {
      console.log(err);
    }

    return sectionsInTest;
  },
  resetSection: async (testId, sectionId) => {
    console.log(`testsCorrections.js resetSection`);
    let resultMsg = '';
    if (typeof testId == 'undefined' || typeof sectionId == 'undefined')
      return 'Parameter error';

    const userSectionRef = db.collection(
      `${COLLECTION.USERS}/${auth.currentUser.uid}/${COLLECTION.TESTS}/${testId}/${COLLECTION.SECTIONS}`,
    );

    const userQuestionsRef = db.collection(
      `${COLLECTION.USERS}/${auth.currentUser.uid}/${COLLECTION.TESTS}/${testId}/${COLLECTION.SECTIONS}/${sectionId}/${COLLECTION.QUESTIONS}`,
    );

    try {
      console.log(`begein try`);
      await userSectionRef.get(sectionId).then((sections) => {
        console.log(`sections : ${sections}`);
        if (!sections.exists) {
          resultMsg = `Document for reset section successfully.`;
        }

        sections.forEach((section) => {
          console.log(`section : ${section}`);
          userSectionRef
            .doc(sectionId)
            .update({
              timeLeft: section.data().givenTime,
              questionsAnswered: 0,
              assessmentStatus: '',
              status: SECTION_STATUSES.UNATTEMPTED,
            })
            .then(() => {
              userQuestionsRef.get().then((questions) => {
                questions.forEach((question) => {
                  userQuestionsRef
                    .doc(question.data().baseId)
                    .update({
                      selectedOption: '',
                      writedEssay: '',
                  });
                });
              });
            })
            .then(() => {
              console.log(`testId : ${testId}`);
              setTestStatus(testId);
            })
            .then(() => {
              resultMsg = `Reset section successfully.`;
            });
        });
      });
    } catch (err) {
      resultMsg = err;
      console.log(err);
    }

    return resultMsg;
  },
  resetTest: async (testId) => {
    let resultMsg = '';
    let userData;

    if (typeof testId == 'undefined')
      return 'Parameter error';

    await usersRef.doc(`${auth.currentUser.uid}`).get().then((user) => {
      userData = user.data();  
      if (!userData.grade) userData.grade = 8;
      if (!userData.gender) userData.gender = 'Male';
    });
    
    const userTestRef = db.collection(
      `${COLLECTION.USERS}/${auth.currentUser.uid}/${COLLECTION.TESTS}`,
    );

    const userSectionRef = db.collection(
      `${COLLECTION.USERS}/${auth.currentUser.uid}/${COLLECTION.TESTS}/${testId}/${COLLECTION.SECTIONS}`,
    );

    try {
      await userTestRef.where(`baseId`, '==', testId).get().then((tests) => {
        if (!tests.exists) {
          resultMsg = `Document for reset test successfully.`;
        };

        userTestRef
          .doc(testId)
          .update({grade: userData.grade, gender: userData.gender, retakeCount: tests.docs[0].data().retakeCount ? tests.docs[0].data().retakeCount + 1 : 1, retakeLimit: RETAKE_LIMIT});

        retakeHistoryRef.add({userUID:`${auth.currentUser.uid}`, testBaseId: `${testId}`, retakeSeq: tests.docs[0].data().retakeCount ? tests.docs[0].data().retakeCount + 1 : 1, retakeType: `User`, retakeUserUID:`${auth.currentUser.uid}`, retakeReason: `User click retake button.`, retakeIP:``, retakeDatetime: serverTimestamp()});
        
        // tests.forEach((test) => {
        for (const test of tests.docs) {
          // sections.forEach((sectionId) => {
          for (const sectionId of test.data().sections) {
              const userSectionRef = db.collection(
              `${COLLECTION.USERS}/${auth.currentUser.uid}/${COLLECTION.TESTS}/${testId}/${COLLECTION.SECTIONS}`,
            );
        
            userSectionRef.where(`baseId`, '==', sectionId).get().then((sections) => {
              if (!sections.exists) {
                resultMsg = `Document for reset section successfully.`;
              }

              // sections.forEach((section) => {
              for (const section of sections.docs) {
                const userQuestionsRef = db.collection(
                  `${COLLECTION.USERS}/${auth.currentUser.uid}/${COLLECTION.TESTS}/${testId}/${COLLECTION.SECTIONS}/${sectionId}/${COLLECTION.QUESTIONS}`,
                );
  
                userSectionRef
                  .doc(sectionId)
                  .update({
                    timeLeft: section.data().givenTime,
                    questionsAnswered: 0,
                    assessmentStatus: '',
                    status: SECTION_STATUSES.UNATTEMPTED,
                  })
                  .then(() => {
                    userQuestionsRef.get().then((questions) => {
                      questions.forEach((question) => {
                        userQuestionsRef
                          .doc(question.data().baseId)
                          .update({selectedOption: '', writedEssay: ''});
                      });
                    });
                  })
                  .then(() => {
                    setTestStatus(testId);
                  })
                  .then(() => {
                    resultMsg = `Reset test successfully.`;
                  });
              // });
              }
            });
          // });
          }
        // });
        }
      });
    } catch (err) {
      resultMsg = err;
      console.log(err);
    }

    return resultMsg;
  },
  getFinishedTestsInUser: async () => {
    const userTests = [];

    try {
      const testsData = await usersRef
        .doc(auth.currentUser.uid)
        .collection(COLLECTION.TESTS)
        .where(`status`, '==', TEST_STATUSES.COMPLETED)
        .get();

      for (const test of testsData.docs) {
        let testData = test.data();
        // if(testData.isFree === 'true') continue;
        testData.grade = testData.grade > 10 ? 10 : testData.grade;

        let mathRawScore = 0;
        let verbalRawScore = 0;
        let readingRawScore = 0;
        let mathScoreMapping = {};
        let verbalScoreMapping = {};
        let readingScoreMapping = {};
        let mathResultCount = {};
        let verbalResultCount = {};
        let readingResultCount = {};
        let mathQuestionTypeCount = {};
        let sectionsInTest = [];
        let scaledScores = [];
        let isFinishVerbal = false;
        let isFinishReading = false;
        let isFinishQuantitative1 = false;
        let isFinishQuantitative2 = false;
        let overallScaredScore = {};

        const sectionsData = await usersRef
          .doc(auth.currentUser.uid)
          .collection(COLLECTION.TESTS)
          .doc(testData.baseId)
          .collection(COLLECTION.SECTIONS)
          .get();

        for (const section of sectionsData.docs) {
          const sectionData = section.data();
          let questionsInSection = [];
          let questionTypeCount = {};
          let questionType = '';
          let rawScore = 0;
          let answerCount = {
            right: 0,
            wrong: 0,
            notAnswered: 0,
          };

          if (
            sectionData.section === VALID_SECTION_NAMES.VERBAL &&
            sectionData.status === SECTION_STATUSES.COMPLETED
          )
            isFinishVerbal = true;

          if (
            sectionData.section === VALID_SECTION_NAMES.READING &&
            sectionData.status === SECTION_STATUSES.COMPLETED
          )
            isFinishReading = true;

          if (
            sectionData.section === VALID_SECTION_NAMES.QUANTITATIVE_1 &&
            sectionData.status === SECTION_STATUSES.COMPLETED
          )
            isFinishQuantitative1 = true;

          if (
            sectionData.section === VALID_SECTION_NAMES.QUANTITATIVE_2 &&
            sectionData.status === SECTION_STATUSES.COMPLETED
          )
            isFinishQuantitative2 = true;

          const questionsData = await usersRef
            .doc(auth.currentUser.uid)
            .collection(COLLECTION.TESTS)
            .doc(testData.baseId)
            .collection(COLLECTION.SECTIONS)
            .doc(sectionData.baseId)
            .collection(COLLECTION.QUESTIONS)
            .get();

          for (const question of questionsData.docs) {
            const questionData = question.data();
            if (
              sectionData.section === 'Quantitative #1' ||
              sectionData.section === 'Quantitative #2'
            ) {
              questionType = questionData.topic;

              if (!mathQuestionTypeCount[questionType]) {
                mathQuestionTypeCount[questionType] = {
                  questions: 0,
                  right: 0,
                  wrong: 0,
                  notAnswered: 0,
                };
              }

              mathQuestionTypeCount[questionType].questions =
                mathQuestionTypeCount[questionType].questions + 1;

              if (questionData.selectedOption === '') {
                mathQuestionTypeCount[questionType].notAnswered =
                  mathQuestionTypeCount[questionType].notAnswered + 1;
              } else {
                if (
                  questionData.selectedOption === questionData.correctLetter
                ) {
                  mathQuestionTypeCount[questionType].right =
                    mathQuestionTypeCount[questionType].right + 1;
                } else {
                  mathQuestionTypeCount[questionType].wrong =
                    mathQuestionTypeCount[questionType].wrong + 1;
                }
              }
            } else {
              questionType = questionData.questionType;
            }

            if (!questionTypeCount[questionType]) {
              questionTypeCount[questionType] = {
                questions: 0,
                right: 0,
                wrong: 0,
                notAnswered: 0,
              };
            }

            questionTypeCount[questionType].questions =
              questionTypeCount[questionType].questions + 1;

            if (questionData.selectedOption === '') {
              questionTypeCount[questionType].notAnswered =
                questionTypeCount[questionType].notAnswered + 1;
              answerCount.notAnswered++;
            } else {
              if (questionData.selectedOption === questionData.correctLetter) {
                questionTypeCount[questionType].right =
                  questionTypeCount[questionType].right + 1;
                answerCount.right++;
                rawScore++;
              } else {
                questionTypeCount[questionType].wrong =
                  questionTypeCount[questionType].wrong + 1;
                answerCount.wrong++;
                rawScore = rawScore - 0.25;
              }
            }

            questionsInSection.push(questionData);
          }

          sectionData.questionsList = questionsInSection;
          sectionData.resultCount = questionTypeCount;
          sectionData.rawScore = rawScore;
          sectionData.answerCount = answerCount;
          sectionsInTest.push(sectionData);

          if (
            sectionData.section === 'Quantitative #1' ||
            sectionData.section === 'Quantitative #2'
          ) {
            mathRawScore = mathRawScore + rawScore;
          } else if (sectionData.section === 'Verbal') {
            verbalRawScore = rawScore;
            verbalResultCount = questionTypeCount;
          } else if (sectionData.section === 'Reading') {
            readingRawScore = rawScore;
            readingResultCount = questionTypeCount;
          }
        }

        mathScoreMapping = QuantitativeTestMapping.find((mapping) => {
          return (
            mapping.rawScoreFrom <= mathRawScore &&
            mapping.rawScoreTo >= mathRawScore &&
            mapping.grade == testData.grade
          );
        });

        if (isFinishQuantitative1 && isFinishQuantitative2) {
          let sectionScaledScore = {};
          sectionScaledScore.section = `Quantitative`  + ` Section`;
          sectionScaledScore.score =
            testData.gender === 'MALE'
              ? parseInt(mathScoreMapping.maleScaledScore)
              : parseInt(mathScoreMapping.femaleScaledScore);
          sectionScaledScore.percentile =
            testData.gender === 'MALE'
              ? parseInt(mathScoreMapping.malePercentile)
              : parseInt(mathScoreMapping.femalePercentile);
          sectionScaledScore.range = mathScoreMapping.range;
          scaledScores.push(sectionScaledScore);
        }
        verbalScoreMapping = VerbalTestMapping.find((mapping) => {
          return (
            mapping.rawScoreFrom <= verbalRawScore &&
            mapping.rawScoreTo >= verbalRawScore &&
            mapping.grade == testData.grade
          );
        });

        if (isFinishVerbal) {
          let sectionScaledScore = {};
          sectionScaledScore.section = VALID_SECTION_NAMES.VERBAL  + ` Section`;
          sectionScaledScore.score =
            testData.gender === 'MALE'
              ? parseInt(verbalScoreMapping.maleScaledScore)
              : parseInt(verbalScoreMapping.femaleScaledScore);
          sectionScaledScore.percentile =
            testData.gender === 'MALE'
              ? parseInt(verbalScoreMapping.malePercentile)
              : parseInt(verbalScoreMapping.femalePercentile);
          sectionScaledScore.range = verbalScoreMapping.range;
          scaledScores.push(sectionScaledScore);
        }

        readingScoreMapping = ReadingTestMapping.find((mapping) => {
          return (
            mapping.rawScoreFrom <= readingRawScore &&
            mapping.rawScoreTo >= readingRawScore &&
            mapping.grade == testData.grade
          );
        });

        if (isFinishReading) {
          let sectionScaledScore = {};
          sectionScaledScore.section = VALID_SECTION_NAMES.READING + ` Section`;
          sectionScaledScore.score =
            testData.gender === 'MALE'
              ? parseInt(readingScoreMapping.maleScaledScore)
              : parseInt(readingScoreMapping.femaleScaledScore);
          sectionScaledScore.percentile =
            testData.gender === 'MALE'
              ? parseInt(readingScoreMapping.malePercentile)
              : parseInt(readingScoreMapping.femalePercentile);
          sectionScaledScore.range = readingScoreMapping.range;
          scaledScores.push(sectionScaledScore);
        }

        testData.sectionsList = sectionsInTest;
        testData.userInfo = await getUserInfo(auth.currentUser.uid);
        testData.mathRawScore = mathRawScore;
        testData.verbalRawScore = verbalRawScore;
        testData.readingRawScore = readingRawScore;
        testData.mathScoreMapping = mathScoreMapping;
        testData.verbalScoreMapping = verbalScoreMapping;
        testData.readingScoreMapping = readingScoreMapping;
        
        let totalScore = 0;
        if(testData.gender === 'MALE') {
          if(isFinishQuantitative1 && isFinishQuantitative2) {
            totalScore = totalScore + parseInt(mathScoreMapping.maleScaledScore);
          };
          if(isFinishReading) {
            totalScore = totalScore + parseInt(readingScoreMapping.maleScaledScore);
          };
          if(isFinishVerbal) {
            totalScore = totalScore + parseInt(verbalScoreMapping.maleScaledScore);
          };
        } else {
          if(isFinishQuantitative1 && isFinishQuantitative2) {
            totalScore = testData.totalScore + parseInt(mathScoreMapping.femaleScaledScore);
          };
          if(isFinishReading) {
            totalScore = testData.totalScore + parseInt(readingScoreMapping.femaleScaledScore);
          };
          if(isFinishVerbal) {
            totalScore = testData.totalScore + parseInt(verbalScoreMapping.femaleScaledScore);
          };
        }

        testData.totalScore = totalScore;
        testData.percentile =
          testData.gender === 'MALE'
            ? Math.round(
                (parseInt(mathScoreMapping.malePercentile)
                  + parseInt(verbalScoreMapping.malePercentile)
                  + parseInt(readingScoreMapping.malePercentile)
                  ) / 3,
              )
            : Math.round(
                (parseInt(mathScoreMapping.femalePercentile)
                  + parseInt(verbalScoreMapping.femalePercentile)
                  + parseInt(readingScoreMapping.femalePercentile)
                ) / 3,
              );
        testData.totalPercentile = testData.percentile + 5;
        testData.mathResultCount = mathQuestionTypeCount;
        testData.verbalResultCount = verbalResultCount;
        testData.readingResultCount = readingResultCount;
        overallScaredScore.section = 'Overall Scaled Score';
        overallScaredScore.percentile = testData.percentile + 5;
        overallScaredScore.score = testData.totalScore;
        overallScaredScore.range = '';
        scaledScores.push(overallScaredScore);
        testData.scaledScores = scaledScores;
        userTests.push(testData);
      }
    } catch (err) {
      console.log(err);
    }

    return userTests;
  },
  getSuggestedQuestions: async (questionId, section) => {
    const suggestedQuestions = [];

    try {
      const questionsData = await suggestedRef
        .where(`relatedTestQuestion`, '==', questionId)
        .get();

      for (const question of questionsData.docs) {
        const questionData = question.data();
        questionData.status = 'Unattempted';
        questionData.selectedOption = '';
        suggestedQuestions.push(questionData);
      }
    } catch (err) {
      console.log(err);
    }

    return suggestedQuestions;
  },
  setWriteSectionSelectedOption: async (testId, sectionId, questionId, option) => {
    try {
      const userQuestionsRef = db.collection(
        `${COLLECTION.USERS}/${auth.currentUser.uid}/${COLLECTION.TESTS}/${testId}/${COLLECTION.SECTIONS}/${sectionId}/${COLLECTION.QUESTIONS}`,
      );
      userQuestionsRef
      .doc(questionId)
      .update({selectedOption: option});
    } catch (err) {
      console.log(err);
    }
  },
  setWriteSectionWritedEssay: async (testId, sectionId, questionId, text) => {
    try {
      const userQuestionsRef = db.collection(
        `${COLLECTION.USERS}/${auth.currentUser.uid}/${COLLECTION.TESTS}/${testId}/${COLLECTION.SECTIONS}/${sectionId}/${COLLECTION.QUESTIONS}`,
      );
      userQuestionsRef
      .doc(questionId)
      .update({writedEssay: text});
      //.update({writedEssay: text.replaceAll('\n','<br>')});
    } catch (err) {
      console.log(err);
    }
  },
};

