/* eslint-disable no-unused-vars */
import {
  SET_ANALYSIS_TESTS_LIST,
  SET_ANALYSIS_INIT_SECTIONS_DATA,
  SET_ANALYSIS_CURRENT_SECTION,
  SET_ANALYSIS_CURRENT_TEST_BASEID,
  SET_ANALYSIS_CURRENT_TEST,
  SET_ANALYSIS_SECTIONS_DATA,
  SET_ANALYSIS_CURRENT_QUESTION_NUMBER,
  SET_ANALYSIS_CURRENT_QUESTIONS,
  SET_ANALYSIS_CURRENT_ANSWER_FILTER,
  SET_ANALYSIS_SUGGESTED_QUESTIONS,
  SET_ANALYSIS_SUGGESTED_QUESTION_ANSWER,
  SET_ANALYSIS_SUGGESTED_QUESTION_COMPLETED,
  SET_ANALYSIS_CURRENT_TEST_SECTION,
} from 'shared/constants/ActionTypes';

import {
  OVERVIEW,
  QUANTITATIVE1,
  QUANTITATIVE2,
  READING,
  VERBAL,
  WRITING,
} from 'shared/constants/QuestionsConst';

import {FILTER_KEYS} from 'util/constants';
import {mapDifficultyString} from 'util/strings';

const initialState = {
  testsList: [],
  currentSection: '',
  currentSectionName: '',
  currentSectionStatus: '',
  currentTestBaseId: '',
  currentAnswerFilter: {key: 'none', value: '---'},
  currentQuestionNo: 1,
  currentQuestions: [],
  currentTest: {},
  quantitative1: [],
  quantitative2: [],
  reading: [],
  verbal: [],
  writing: [],
  grade: 6,
  gender: 'MALE',
  suggestedQuestions: [],
  filteredQuestions: [],
};

const analysisReducer = (state = initialState, action) => {
  // let curTest = {};
  // let testList = [];
  let sectionData = {};
  let findNextFiltered = [];

  switch (action.type) {
    case SET_ANALYSIS_TESTS_LIST:
      state.quantitative1 = [];
      state.quantitative2 = [];
      state.reading = [];
      state.verbal = [];
      state.writing = [];
      state.currentSection = 'Overview';
      state.currentSectionName = '';
      state.currentSectionStatus = '';
      state.testsList = action.payload;
      state.grade = 5;
      state.gender = 'MALE';
      state.currentQuestionNo = 1;
      state.suggestedQuestions = [];

      state.currentTestBaseId =
        state.currentTestBaseId === '' && action.payload.length > 0
          ? action.payload[0].baseId
          : state.currentTestBaseId;

      action.payload
        ? action.payload.forEach((test) => {
            if (test.baseId === state.currentTestBaseId) {
              state.currentTest = test;
              state.grade = test.grade;
              state.gender = test.gender;
              test.sectionsList
                ? test.sectionsList.forEach((section) => {
                    switch (section.section) {
                      case QUANTITATIVE1:
                        state.quantitative1 = section.questionsList;
                        break;
                      case QUANTITATIVE2:
                        state.quantitative2 = section.questionsList;
                        break;
                      case READING:
                        state.reading = section.questionsList;
                        break;
                      case VERBAL:
                        state.verbal = section.questionsList;
                        break;
                      case WRITING:
                        state.writing = section.questionsList;
                        break;
                    }
                  })
                : '';
            }
          })
        : '';

      state.currentAnswerFilter = {key: 'none', value: '---'};
      state.filteredQuestions = state.currentQuestions;
      state.currentQuestionNo = 1;

      return {...state};
    case SET_ANALYSIS_INIT_SECTIONS_DATA:
      state.quantitative2 = [];
      state.quantitative2 = [];
      state.reading = [];
      state.verbal = [];
      state.writing = [];
      state.currentQuestionNo = 1;
      state.suggestedQuestions = [];

      action.payload
        ? action.payload.forEach((test) => {
            if (test.baseId === state.currentTestBaseId) {
              state.currentTest = test;
              state.grade = test.grade;
              state.gender = test.gender;

              test.sectionsList
                ? test.sectionsList.forEach((section) => {
                    switch (section.section) {
                      case QUANTITATIVE1:
                        state.quantitative1 = section.questionsList;
                        break;
                      case QUANTITATIVE2:
                        state.quantitative2 = section.questionsList;
                        break;
                      case READING:
                        state.reading = section.questionsList;
                        break;
                      case VERBAL:
                        state.verbal = section.questionsList;
                        break;
                      case WRITING:
                        state.writing = section.questionsList;
                        break;
                    }
                  })
                : '';
            }
          })
        : '';

      state.currentAnswerFilter = {key: 'none', value: '---'};
      state.filteredQuestions = state.currentQuestions;
      state.currentQuestionNo = 1;

      return {...state};
    case SET_ANALYSIS_CURRENT_SECTION:
      sectionData = state.currentTest.sectionsList.filter(
        (section) => section.section === action.payload,
      );

      if (sectionData.length > 0) {
        state.currentSectionName = sectionData[0].name;
        state.currentSectionStatus = sectionData[0].status;
        state.currentQuestions = sectionData[0].questionsList;
      } else {
        state.currentSectionName = action.payload;
        state.currentSectionStatus = '';
        state.currentQuestions = [];
      }

      state.currentSection = action.payload;

      state.currentAnswerFilter = {key: 'none', value: '---'};
      state.filteredQuestions = state.currentQuestions;
      state.currentQuestionNo = 1;

      return {...state};
    case SET_ANALYSIS_CURRENT_TEST_BASEID:
      state.currentTestBaseId = action.payload;

      action.payload
        ? state.testsList.forEach((test) => {
            if (test.baseId === state.currentTestBaseId) {
              state.currentTest = test;
              state.grade = test.grade;
              state.gender = test.gender;
              test.sectionsList
                ? test.sectionsList.forEach((section) => {
                    switch (section.section) {
                      case QUANTITATIVE1:
                        state.quantitative1 = section.questionsList;
                        break;
                      case QUANTITATIVE2:
                        state.quantitative2 = section.questionsList;
                        break;
                      case READING:
                        state.reading = section.questionsList;
                        break;
                      case VERBAL:
                        state.verbal = section.questionsList;
                        break;
                      case WRITING:
                        state.writing = section.questionsList;
                        break;
                    }
                  })
                : '';

              sectionData = test.sectionsList.filter(
                (section) => section.section === state.currentSection,
              );

              if (sectionData.length > 0) {
                state.currentSectionName = sectionData[0].name;
                state.currentSectionStatus = sectionData[0].status;
                state.currentQuestions = sectionData[0].questionsList;
              } else {
                state.currentSectionName = action.payload;
                state.currentSectionStatus = '';
                state.currentQuestions = [];
              }
            }
          })
        : '';

      state.currentAnswerFilter = {key: 'none', value: '---'};
      state.filteredQuestions = state.currentQuestions;
      state.currentQuestionNo = 1;
      state.currentSection = OVERVIEW;

      return {...state};
    case SET_ANALYSIS_CURRENT_TEST_SECTION:
      action.payload
        ? state.testsList.forEach((test) => {
            if (test.baseId === action.payload.test) {
              state.currentTest = test;
              state.grade = test.grade;
              state.gender = test.gender;
              test.sectionsList
                ? test.sectionsList.forEach((section) => {
                    switch (section.section) {
                      case QUANTITATIVE1:
                        state.quantitative1 = section.questionsList;
                        break;
                      case QUANTITATIVE2:
                        state.quantitative2 = section.questionsList;
                        break;
                      case READING:
                        state.reading = section.questionsList;
                        break;
                      case VERBAL:
                        state.verbal = section.questionsList;
                        break;
                      case WRITING:
                        state.writing = section.questionsList;
                        break;
                    }
                  })
                : '';

              sectionData = test.sectionsList.filter(
                (section) => section.section === action.payload.section,
              );

              if (sectionData.length > 0) {
                state.currentSection = sectionData[0].section;
                state.currentSectionName = sectionData[0].name;
                state.currentSectionStatus = sectionData[0].status;
                state.currentQuestions = sectionData[0].questionsList;
              } else {
                state.currentSection = 'Overview';
                state.currentSectionName = '';
                state.currentSectionStatus = '';
                state.currentQuestions = [];
              }
            }
          })
        : '';

      state.currentAnswerFilter = {key: 'none', value: '---'};
      state.filteredQuestions = state.currentQuestions;
      state.currentQuestionNo = 1;

      return {...state};
    case SET_ANALYSIS_CURRENT_TEST:
      return {
        ...state,
        testsList: action.payload,
      };
    case SET_ANALYSIS_SECTIONS_DATA:
      return {
        ...state,
        testsList: action.payload,
      };
    case SET_ANALYSIS_CURRENT_QUESTION_NUMBER:
      if (
        (state.filteredQuestions.length > 0 &&
          state.filteredQuestions.length != state.currentQuestions.length) ||
        state.currentAnswerFilter.key != FILTER_KEYS.NONE
      ) {
        if (state.currentQuestionNo > action.payload) {
          // backward
          findNextFiltered = state.filteredQuestions.filter((question) => {
            return question.index < state.currentQuestionNo - 1;
          });

          if (findNextFiltered.length > 0) {
            state.currentQuestionNo =
              findNextFiltered[findNextFiltered.length - 1].index + 1;
          }
        } else {
          // foreward
          findNextFiltered = state.filteredQuestions.filter((question) => {
            return question.index > state.currentQuestionNo - 1;
          });

          if (findNextFiltered.length > 0) {
            state.currentQuestionNo = findNextFiltered[0].index + 1;
          }
        }
      } else {
        state.currentQuestionNo = action.payload;
      }

      return {
        ...state,
      };
    case SET_ANALYSIS_CURRENT_ANSWER_FILTER:
      state.currentAnswerFilter = action.payload;

      if (action.payload.key === FILTER_KEYS.CORRECTNESS) {
        if (action.payload.value.toLowerCase() === 'correct') {
          state.filteredQuestions = state.currentQuestions.filter(
            (question) => {
              return question.selectedOption == question.correctLetter;
            },
          );
        } else if (action.payload.value.toLowerCase() === 'incorrect') {
          state.filteredQuestions = state.currentQuestions.filter(
            (question) => {
              return (
                question.selectedOption != question.correctLetter ||
                question.selectedOption == ''
              );
            },
          );
        } else if (action.payload.value.toLowerCase() === 'omit') {
          state.filteredQuestions = state.currentQuestions.filter(
            (question) => {
              return question.selectedOption == '';
            },
          );
        }
      }

      if (action.payload.key === FILTER_KEYS.DIFFICULTY) {
        state.filteredQuestions = state.currentQuestions.filter((question) => {
          return (
            mapDifficultyString(question.difficulty).toLowerCase() ==
            action.payload.value.toLowerCase()
          );
        });
        console.log(state.filteredQuestions);
      }

      if (action.payload.key === FILTER_KEYS.QUESTION_TYPE) {
        state.filteredQuestions = state.currentQuestions.filter((question) => {
          return (
            (question.questionType || question.topic).toLowerCase() ==
            action.payload.value.toLowerCase()
          );
        });
      }

      if (action.payload.key === FILTER_KEYS.NONE)
        state.filteredQuestions = state.currentQuestions;

      state.filteredQuestions.forEach((filteredQuestion) => {
        filteredQuestion.index = state.currentQuestions.findIndex(
          (currentQuestion) => {
            return currentQuestion.baseId == filteredQuestion.baseId;
          },
        );
      });

      return {
        ...state,
      };
    case SET_ANALYSIS_SUGGESTED_QUESTIONS:
      state.suggestedQuestions = action.payload;

      return {
        ...state,
      };
    case SET_ANALYSIS_SUGGESTED_QUESTION_ANSWER:
      if (
        state.suggestedQuestions[action.payload.questionNo - 1]
          .selectedOption === action.payload.answer
      ) {
        state.suggestedQuestions[action.payload.questionNo - 1].selectedOption =
          '';
        state.suggestedQuestions[action.payload.questionNo - 1].status =
          'Unattempted';
      } else {
        state.suggestedQuestions[action.payload.questionNo - 1].selectedOption =
          action.payload.answer;
        state.suggestedQuestions[action.payload.questionNo - 1].status =
          'Marked';
      }

      return {
        ...state,
      };
    case SET_ANALYSIS_SUGGESTED_QUESTION_COMPLETED:
      state.suggestedQuestions.forEach((question) => {
        question.status = 'Completed';
      });

      return {
        ...state,
      };
    default:
      return state;
  }
};

export default analysisReducer;
