import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_UPDATE_ITEM,
  CART_CLEAN_UP,
} from 'shared/constants/ActionTypes';

export const addToCart = (item) => {
  return (dispatch) => dispatch({type: CART_ADD_ITEM, payload: item});
};

export const updateCartItem = (data) => {
  return (dispatch) => {
    dispatch({type: CART_UPDATE_ITEM, payload: data});
  };
};

export const removeFromCartItem = (data) => {
  return (dispatch) => {
    dispatch({type: CART_REMOVE_ITEM, payload: data});
  };
};

export const makeEmptyCart = () => {
  return (dispatch) => dispatch({type: CART_CLEAN_UP});
};
