/* eslint-disable no-unused-vars */
import React from 'react';
import {Box, Typography} from '@mui/material';
import IntlMessages from '@crema/utility/IntlMessages';
import {Fonts} from 'shared/constants/AppEnums';
import ChangePasswordForm from './ChangePasswordForm';
import {Formik} from 'formik';
import * as yup from 'yup';
import AppInfoView from '@crema/core/AppInfoView';
import {fetchError, showMessage} from 'redux/actions';
import {useDispatch} from 'react-redux';

import {
  getAuth,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from 'firebase/auth';

const validationSchema = yup.object({
  oldPassword: yup
    .string()
    .required('No password provided.')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
  newPassword: yup
    .string()
    .required('New password required.')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
  retypeNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

const ChangePassword = () => {
  const auth = getAuth();
  const dispatch = useDispatch();

  return (
    <Box
      sx={{
        position: 'relative',
        maxWidth: 550,
      }}
    >
      <Typography
        component='h3'
        sx={{
          fontSize: 16,
          fontWeight: Fonts.BOLD,
          mb: {xs: 3, lg: 5},
        }}
      >
        <IntlMessages id='common.changePassword' />
      </Typography>
      <Formik
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={{
          oldPassword: '',
          newPassword: null,
          retypeNewPassword: 'us',
        }}
        validationSchema={validationSchema}
        onSubmit={async (data, {setSubmitting}) => {
          setSubmitting(true);

          const credential = EmailAuthProvider.credential(
            auth.currentUser.email,
            data.oldPassword,
          );

          try {
            await reauthenticateWithCredential(auth.currentUser, credential);
            await updatePassword(auth.currentUser, data.newPassword);
            dispatch(showMessage('Success to change password.'));
          } catch (e) {
            dispatch(fetchError('Failed to change password.'));
          }
          setSubmitting(false);
        }}
      >
        {() => <ChangePasswordForm />}
      </Formik>
      <AppInfoView />
    </Box>
  );
};

export default ChangePassword;
