import React from 'react';
import {RoutePermittedRole} from 'shared/constants/AppConst';

const Practice = React.lazy(() => import('./Practice'));
const StudentReport = React.lazy(() => import('./Report'));

export const studentConfig = [
  {
    permittedRole: [
      RoutePermittedRole.STUDENT,
      RoutePermittedRole.ADMIN,
      RoutePermittedRole.SUPERADMIN,
    ],
    path: '/practice',
    element: <Practice />,
  },
  {
    permittedRole: [
      RoutePermittedRole.MEMBER,
      RoutePermittedRole.ADMIN,
      RoutePermittedRole.SUPERADMIN,
    ],
    path: '/studentReport',
    element: <StudentReport />,
  },
];
