/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import {IconButton} from '@mui/material';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CartContent from './CartContent';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AppTooltip from '../../../@crema/core/AppTooltip';
import {alpha} from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import {useDispatch, useSelector} from 'react-redux';

const Cart = ({
  drawerPosition,
  tooltipPosition,
  isMenu,
  sxNotificationContentStyle,
}) => {
  const cart = useSelector((state) => state.cart);
  const [showCart, setShowCart] = useState(false);
  const [itemCount, setItemCount] = useState(0);

  useEffect(() => {
    setItemCount(cart.cartItems.length);
  }, [cart]);

  return (
    <>
      {isMenu ? (
        <Box component='span' onClick={() => setShowCart(true)}>
          Cart
        </Box>
      ) : (
        <AppTooltip title='Cart' placement={tooltipPosition}>
          <IconButton
            className='icon-btn'
            sx={{
              borderRadius: '50%',
              width: 40,
              height: 40,
              color: (theme) => theme.palette.text.secondary,
              backgroundColor: (theme) => theme.palette.background.default,
              border: 1,
              borderColor: 'transparent',
              '&:hover, &:focus': {
                color: (theme) => theme.palette.text.primary,
                backgroundColor: (theme) =>
                  alpha(theme.palette.background.default, 0.9),
                borderColor: (theme) =>
                  alpha(theme.palette.text.secondary, 0.25),
              },
            }}
            onClick={() => setShowCart(true)}
            size='large'
          >
            <Badge badgeContent={itemCount} color='primary'>
              <ShoppingCartIcon />
            </Badge>
          </IconButton>
        </AppTooltip>
      )}
      <Drawer
        anchor={drawerPosition}
        open={showCart}
        onClose={() => setShowCart(false)}
      >
        <CartContent
          sxStyle={sxNotificationContentStyle}
          onClose={() => setShowCart(false)}
        />
      </Drawer>
    </>
  );
};

export default Cart;

Cart.defaultProps = {
  drawerPosition: 'right',
  tooltipPosition: 'bottom',
};

Cart.propTypes = {
  drawerPosition: PropTypes.string,
  tooltipPosition: PropTypes.string,
  isMenu: PropTypes.bool,
  sxNotificationContentStyle: PropTypes.object,
};
