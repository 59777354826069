/* eslint-disable no-unused-vars */
import React from 'react';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import PropTypes from 'prop-types';
import {Box, ListItem, Typography} from '@mui/material';
import {Fonts} from 'shared/constants/AppEnums';
import WorkIcon from '@mui/icons-material/Work';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import {moneyFormat} from 'util/common';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {IconButton} from '@mui/material';
import {useDispatch} from 'react-redux';
import {removeFromCartItem} from 'redux/actions';

const makeItemDetailText = (
  level,
  isPackage,
  isPractice,
  isSuggest,
  isBundle,
  testList,
) => {
  let detailText = '';
  const levelAbb = level.toUpperCase() === 'MIDDLE' ? 'ML' : 'UL';

  if (isBundle && !isSuggest) {
    detailText = `Five ${levelAbb} PTs`;
    return detailText;
  }

  if (isBundle && isSuggest) {
    detailText = `Five ${levelAbb} PTs + BSP AI Suggestions for Five ${levelAbb} PTs`;
    return detailText;
  }

  if (!isBundle && isPractice && isSuggest) {
    detailText = `${levelAbb} PT ${
      testList[0].testIndex + 1
    } + BSP AI Suggestions for ${levelAbb} PT ${testList[0].testIndex + 1}`;
    return detailText;
  }

  if (!isBundle && isPractice && !isSuggest) {
    detailText = `${levelAbb} PT ${testList[0].testIndex + 1}`;
    return detailText;
  }

  if (!isBundle && !isPractice && isSuggest) {
    detailText = `BSP AI Suggestions for ${levelAbb} PT ${
      testList[0].testIndex + 1
    }`;
    return detailText;
  }

  return detailText;
};

const CartItem = (props) => {
  const dispatch = useDispatch();
  const {item, index} = props;
  const isPackage = item.type.toUpperCase() === 'PACKAGE';
  const isSuggest = Number.parseInt(item.suggestCount) > 0;
  const isPractice = Number.parseInt(item.practiceCount) > 0;
  const isBundle = item.practiceCount > 1;

  const removeItem = () => {
    dispatch(removeFromCartItem({index: index}));
  };

  return (
    <ListItem
      sx={{
        padding: '8px 20px',
        display: 'flex',
        justifyContent: 'start-end',
      }}
      className='item-hover'
    >
      <ListItemAvatar
        sx={{
          minWidth: 0,
          mr: 4,
        }}
      >
        <AssignmentTurnedInIcon
          sx={{
            width: 48,
            height: 48,
          }}
        />
      </ListItemAvatar>
      <Box
        sx={{
          width: '70%',
          fontSize: 14,
          color: (theme) => theme.palette.text.secondary,
        }}
      >
        <Typography>
          <Box
            component='span'
            sx={{
              fontSize: 16,
              fontWeight: Fonts.MEDIUM,
              mb: 0.5,
              color: (theme) => theme.palette.text.primary,
              mr: 1,
              display: 'inline-block',
            }}
          >
            {item.title}
          </Box>
          {/* <br />
          {item.level} */}
        </Typography>
        {makeItemDetailText(
          item.level,
          isPackage,
          isPractice,
          isSuggest,
          isBundle,
          item.testList,
        )}
        <Box
          sx={{
            fontSize: 16,
            fontWeight: Fonts.MEDIUM,
          }}
        >
          {moneyFormat(Number(item.amount))}
          {Number(item.discount) > 0 && (
            <Typography>
              {' '}
              Discount: {moneyFormat(Number(item.discount))}
            </Typography>
          )}
        </Box>
      </Box>
      <Box>
        <IconButton
          sx={{
            height: 40,
            width: 40,
            marginLeft: 'auto',
            color: 'text.secondary',
          }}
          onClick={removeItem}
          size='large'
        >
          <DeleteForeverIcon />
        </IconButton>
      </Box>
    </ListItem>
  );
};

export default CartItem;

CartItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.any,
};
