//API
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const SHOW_ERROR = 'show_error';
export const HIDE_MESSAGE = 'hide_message';
export const TOGGLE_APP_DRAWER = 'toggle_app_drawer';
export const UPDATING_CONTENT = 'updating_content';
export const SHOW_BACK_DROP = 'show_back_drop';
export const HIDE_BACK_DROP = 'hide_back_drop';
export const MESSAGE_BACK_DROP = 'message_back_drop';

//APP SETTING
export const TOGGLE_NAV_COLLAPSED = 'toggle_nav_collapsed';
export const SET_INITIAL_PATH = 'set_initial_path';

//AUTH0
export const UPDATE_AUTH_USER = 'update_auth_user';
export const SET_AUTH_TOKEN = 'set_auth_token';
export const SIGNOUT_AUTH_SUCCESS = 'signout_auth_success';

//ANALYTICS-DASHBOARD
export const GET_ANALYTICS_DATA = 'get_analytics_data';

//ECOMMERCE-DASHBOARD
export const GET_ECOMMERCE_DATA = 'get_ecommerce_data';

//ACADEMY-DASHBOARD
export const GET_ACADEMY_DATA = 'get_academy_data';

//CRM-DASHBOARD
export const GET_CRM_DATA = 'get_crm_data';

//CRYPTO-DASHBOARD
export const GET_CRYPTO_DATA = 'get_crypto_data';

//HEATH_ CARE-DASHBOARD
export const GET_HC_DATA = 'get_hc_data';

//METRICS-DASHBOARD
export const GET_METRICS_DATA = 'get_metrics_data';

//WIDGETS_DASHBOARD
export const GET_WIDGETS_DATA = 'get_widgets_data';

//MAIL-APP
export const GET_MAIL_LIST = 'get_mail_list';
export const GET_FOLDER_LIST = 'get_folder_list';
export const GET_LABEL_LIST = 'get_label_list';
export const TOGGLE_MAIL_DRAWER = 'toggle_mail_drawer';
export const COMPOSE_MAIL = 'compose_mail';
export const GET_MAIL_DETAIL = 'get_mail_detail';
export const UPDATE_MAIL_FOLDER = 'update_mail_folders';
export const UPDATE_MAIL_LABEL = 'update_mail_label';
export const UPDATE_STARRED_STATUS = 'update_starred_status';
export const UPDATED_MAIL_DETAIL = 'updated_mail_detail';
export const CHANGE_READ_STATUS = 'change_read_status';
export const GET_CONNECTION_LIST = 'get_connection_list';
export const NULLIFY_MAIL = 'nullify_mail';

//TODO-APP
export const GET_TASK_LIST = 'get_task_list';
export const CREATE_NEW_TASK = 'create_new_task';
export const TOGGLE_TODO_DRAWER = 'toggle_todo_drawer';
export const GET_TODO_FOLDER_LIST = 'GET_TODO_FOLDER_LIST';
export const GET_TODO_LABEL_LIST = 'GET_TODO_LABEL_LIST';
export const GET_TODO_STATUS_LIST = 'GET_TODO_STATUS_LIST';
export const GET_TODO_PRIORITY_LIST = 'GET_TODO_PRIORITY_LIST';
export const UPDATE_TASK_FOLDER = 'UPDATE_TASK_FOLDER';
export const UPDATE_TASK_LABEL = 'UPDATE_TASK_LABEL';
export const UPDATE_TASK_STARRED_STATUS = 'UPDATE_TASK_STARRED_STATUS';
export const GET_TASK_DETAIL = 'GET_TASK_DETAIL';
export const UPDATE_TASK_DETAIL = 'UPDATE_TASK_DETAIL';
export const GET_TODO_STAFF_LIST = 'GET_TODO_STAFF_LIST';

//CONTACT_APP
export const GET_CONTACT_LIST = 'GET_CONTACT_LIST';
export const GET_CONTACT_FOLDER_LIST = 'GET_CONTACT_FOLDER_LIST';
export const GET_CONTACT_LABEL_LIST = 'GET_CONTACT_LABEL_LIST';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const UPDATE_CONTACT_LABEL = 'UPDATE_CONTACT_LABEL';
export const UPDATE_CONTACT_STARRED_STATUS = 'UPDATE_CONTACT_STARRED_STATUS';
export const GET_CONTACT_DETAIL = 'GET_CONTACT_DETAIL';
export const TOGGLE_CONTACT_DRAWER = 'TOGGLE_CONTACT_DRAWER';
export const UPDATE_CONTACT_DETAIL = 'UPDATE_CONTACT_DETAIL';
export const CREATE_NEW_CONTACT = 'CREATE_NEW_CONTACT';

//SCRUMBOARD_APP
export const GET_MEMBER_LIST = 'GET_MEMBER_LIST';
export const GET_SCRUM_LABEL_LIST = 'GET_SCRUM_LABEL_LIST';
export const GET_BOARDS = 'GET_BOARDS';
export const GET_BOARD_DETAIL = 'GET_BOARD_DETAIL';
export const ADD_BOARD_LIST = 'ADD_BOARD_LIST';
export const ADD_LIST_CARD = 'ADD_LIST_CARD';
export const EDIT_LIST_CARD = 'EDIT_LIST_CARD';
export const DELETE_LIST_CARD = 'DELETE_LIST_CARD';
export const DELETE_BOARD = 'DELETE_BOARD';
export const ADD_NEW_BOARD = 'ADD_NEW_BOARD';
export const DELETE_LIST = 'DELETE_LIST';
export const EDIT_BOARD_DETAIL = 'EDIT_BOARD_DETAIL';
export const EDIT_BOARD_LIST = 'EDIT_BOARD_LIST';

//CHAT_APP
export const GET_CONNECTIONS_LIST = 'get_connections_list';
export const GET_USER_MESSAGES = 'get_user_messages';
export const ADD_NEW_MESSAGE = 'add_new_message';
export const EDIT_MESSAGE = 'edit_message';
export const DELETE_MESSAGE = 'delete_message';
export const DELETE_USER_MESSAGES = 'delete_user_messages';
export const TOGGLE_CHAT_DRAWER = 'toggle_chat_drawer';
export const SELECT_USER = 'select_user';

//WALL_APP
export const GET_WALL_DATA = 'get_wall_data';
export const GET_FEED_POSTS = 'get_feed_posts';
export const CREATE_NEW_POST = 'create_new_post';
export const UPDATE_POST = 'update_post';

//USER_LIST
export const GET_USER_LIST = 'GET_USER_LIST';

//ECOMMERCE_LIST
export const GET_ECOMMERCE_LIST = 'get_ecommerce_list';
export const SET_PRODUCT_VIEW_TYPE = 'set_product_view_type';
export const SET_FILTER_DATA = 'set_filter_data';
export const SET_PRODUCT_DATA = 'set_product_data';
export const GET_RECENT_ORDER = 'get_recent_order';
export const GET_CUSTOMERS = 'get_customers';
export const ADD_CART_ITEM = 'add_cart_item';
export const REMOVE_CART_ITEM = 'remove_cart_item';
export const UPDATE_CART_ITEM = 'update_cart_item';
export const SET_CART_ITEMS = 'set_cart_items';

//CK-EDITOR
export const GET_BALLOON_BLOCK_DATA = 'get_balloon_block_data';
export const UPDATE_BALLOON_BLOCK_DATA = 'update_balloon_block_data';
export const GET_BALLOON_DATA = 'get_balloon_data';
export const UPDATE_BALLOON_DATA = 'update_balloon_data';
export const GET_CLASSIC_DATA = 'get_classic_data';
export const UPDATE_CLASSIC_DATA = 'update_classic_data';
export const GET_INLINE_DATA = 'get_inline_data';
export const UPDATE_INLINE_DATA = 'update_inline_data';
export const GET_DOCUMENT_DATA = 'get_document_data';
export const UPDATE_DOCUMENT_DATA = 'update_document_data';
export const GET_CUSTOM_DATA = 'get_custom_data';
export const UPDATE_CUSTOM_DATA = 'update_custom_data';

//GALLERY
export const GET_GALLERY_PHOTO = 'get_gallery_photo';

//CART
export const CART_ADD_ITEM = 'cart_add_item';
export const CART_REMOVE_ITEM = 'cart_remove_item';
export const CART_UPDATE_ITEM = 'cart_updat_item';
export const CART_CLEAN_UP = 'cart_clean_up';

//ASSESSMENT
export const ASSESSMENT_UPDATE_LEFTTIME = 'assessment_update_lefttime';
export const ASSESSMENT_UPDATE_SECTION_STATUS =
  'assessment_update_section_status';
export const ASSESSMENT_UPDATE_STATUS = 'assessment_update_status';
export const ASSESSMENT_UPDATE_ANSWER = 'assessment_update_answer';
export const SET_CURRENT_TEST = 'set_current_test';
export const SET_CURRENT_SECTION = 'set_current_section';
export const SET_CURRENT_QUESTION = 'set_current_question';
export const SET_QUESTIONS_IN_SECTION = 'set_questions_in_section';
export const SET_QUESTION_INFO = 'set_question_info';
export const SET_ASSESSMENT_STATUS = 'set_assessment_status';
export const SET_ASSESSMENT_TIMELEFT = 'set_assessment_timeleft';
export const MOVE_PREVIOUS_QUESTION = 'move_to_previous_question';
export const MOVE_NEXT_QUESTION = 'move_to_next_question';
export const SET_SELECTED_OPTION = 'set_selected_option';
export const SET_WRITE_SECTION_SELECTED_OPTION =
  'set_write_section_selected_option';
export const SET_WRITE_SECTION_WRITED_ESSAY = 'set_write_section_writed_essay';

//ANALYSIS
export const SET_ANALYSIS_TESTS_LIST = 'set_analysis_tests_list';
export const SET_ANALYSIS_CURRENT_TEST_BASEID =
  'set_analysis_current_test_baseid';
export const SET_ANALYSIS_SECTIONS_DATA = 'set_analysis_sections_data';
export const SET_ANALYSIS_CURRENT_TEST = 'set_analysis_current_test';
export const SET_ANALYSIS_CURRENT_SECTION = 'set_analysis_current_section';
export const SET_ANALYSIS_INIT_SECTIONS_DATA =
  'set_analysis_init_sections_data';
export const SET_ANALYSIS_CURRENT_QUESTION_NUMBER =
  'set_analysis_current_question_number';
export const SET_ANALYSIS_CURRENT_QUESTIONS = 'set_analysis_current_questions';
export const SET_ANALYSIS_CURRENT_ANSWER_FILTER =
  'set_analysis_current_answer_filter';
export const SET_ANALYSIS_SUGGESTED_QUESTIONS =
  'set_analysis_suggested_questions';
export const SET_ANALYSIS_SUGGESTED_QUESTION_ANSWER =
  'set_analysis_suggested_question_answer';
export const SET_ANALYSIS_SUGGESTED_QUESTION_COMPLETED =
  'set_analysis_suggested_question_completed';
export const SET_ANALYSIS_CURRENT_TEST_SECTION =
  'set_analysis_current_test_section';

// PRACTICE
export const SET_PRACTICE_USER_TESTS_LIST = 'set_practice_user_tests_list';
export const SET_PRACTICE_NON_USER_TESTS_LIST =
  'set_practice_non_user_tests_list';
export const RESET_PRACTICE_SECTION = 'reset_practice_section';
export const SET_PRACTICE_CURRENT_TESTID = 'set_practice_current_testid';
export const SET_PRACTICE_CURRENT_SECTIONID = 'set_practice_current_sectionid';
export const RETAKE_PRACTICE_TEST = 'retake_practice_test';
export const SET_PRACTICE_QUESTIONS = 'set_practice_questions';
export const SET_PRACTICE_SELECTED_OPTION = 'set_practice_selected_option';
export const SET_PRACTICE_CURRENT_QUESTION = 'set_practice_current_question';
export const SET_PRACTICE_QUESTION_SELECTED_OPTION =
  'set_practice_question_current_question';
export const GET_PRACTICE_QUESTIONS = 'get_practice_questions';
export const SET_CURRENT_PRACTICE = 'set_current_practice';
export const SET_OPEN_WRITING_SECTION = 'set_open_writing_section';
export const SET_OPEN_NONWRITING_SECTION = 'set_open_nonwriting_section';
export const SET_OPEN_NEXT_SECTION = 'set_open_next_section';
export const SET_OPEN_NEXT_SECTION_WITH_QUESTIONS =
  'set_open_next_section_with_questions';
export const SET_CLOSE_FINISH_INSTRUCTION = 'set_close_finish_instruction';
export const SET_PRACTICE_INITIAL_SECTION = 'set_practice_initial_section';
export const SET_INITIAL_PRACTICE_STATE = 'set_initial_practice_state';
export const SET_OPEN_BREAK_SECTION = 'set_open_break_section';
export const SET_OPEN_FINISH_INSTRUCTION = 'set_open_finish_instruction';
export const PRACTICE_SECTIONS = [
  'Writing',
  'WritingBreak',
  'Quantitative #1',
  'Quantitative#1Break',
  'Reading',
  'ReadingBreak',
  'Verbal',
  'VerbalBreak',
  'Quantitative #2',
  'Finish',
];

export const SET_STUDENT_REPORT_PRACTICE = 'set_student_report_practice';
export const SET_STUDENT_REPORT_PRACTICE_DATA =
  'set_student_report_practice_data';
export const SET_STUDENT_REPORT_CURRENT_PRACTICE =
  'set_student_report_current_practice';
export const SET_STUDENT_REPORT_CURRENT_SECTION =
  'set_student_report_current_section';
export const SET_STUDENT_REPORT_CURRENT_QUESTION_NUMBER =
  'set_student_report_current_question_number';
export const SET_STUDENT_REPORT_CURRENT_ANSWER_FILTER =
  'set_student_report_current_answer_filter';
export const REPORT_SECTIONS = [
  'Overview',
  'Quantitative #1',
  'Reading',
  'Verbal',
  'Quantitative #2',
];
