import {
  SET_STUDENT_REPORT_PRACTICE,
  SET_STUDENT_REPORT_PRACTICE_DATA,
  REPORT_SECTIONS,
  SET_STUDENT_REPORT_CURRENT_PRACTICE,
  SET_STUDENT_REPORT_CURRENT_SECTION,
  SET_STUDENT_REPORT_CURRENT_QUESTION_NUMBER,
  SET_STUDENT_REPORT_CURRENT_ANSWER_FILTER,
} from 'shared/constants/ActionTypes';

import {FILTER_KEYS} from 'util/constants';
import {mapDifficultyString} from 'util/strings';

const initialState = {
  practicesList: [],
  currentPractice: {},
  currentQuestionIndex: 0,
  currentQuestion: {},
  currentSection: {},
  currentSectionTitle: 'Overview',
  currentSectionQuestions: [],
  practiceQuestions: [],
  practiceSections: [],
  reportSections: REPORT_SECTIONS,
  partitionIndex: 0,
  currentAnswerFilter: {key: 'none', value: '---'},
  filteredQuestions: [],
};

const studentReducer = (state = initialState, action) => {
  let tempCurrentSectionQuestions = [];
  let tempPracticeSections = [];
  let tempPracticeQuestions = [];

  switch (action.type) {
    case SET_STUDENT_REPORT_PRACTICE:
      return {...state};
    case SET_STUDENT_REPORT_PRACTICE_DATA:
      state.practicesList = [...action.payload.practices];
      state.currentPractice = {...action.payload.practice};
      state.practiceQuestions = [...action.payload.practice.questionsList];
      state.practiceSections = [...action.payload.practice.sectionsList];

      return {...state};
    case SET_STUDENT_REPORT_CURRENT_PRACTICE:
      tempCurrentSectionQuestions = [];
      tempPracticeSections = [];
      tempPracticeQuestions = [];

      state.currentPractice = state.practicesList.find(
        (practice) => practice.studentPracticeId === action.practiceInfo.id,
      );
      state.currentQuestionIndex = 0;
      state.partitionIndex = 0;
      state.currentQuestion = {};
      state.currentSectionQuestions = [];
      state.currentSection = {};
      state.practiceQuestions = [];
      state.practiceSections = [];

      for (const question of action.practiceInfo.questionsList) {
        tempPracticeQuestions.push(question);
      }
      state.practiceQuestions = [...tempPracticeQuestions];

      for (const section of action.practiceInfo.sectionsList) {
        tempPracticeSections.push(section);
      }
      state.practiceSections = [...tempPracticeSections];

      switch (state.currentSectionTitle) {
        case 'Overview':
          state.currentSection = {};
          state.currentSectionQuestions = [];
          state.currentQuestion = {};
          break;
        default:
          state.currentSection = tempPracticeSections.find(
            (section) => section.section === state.currentSectionTitle,
          );
          for (const question of tempPracticeQuestions) {
            if (question.section === state.currentSectionTitle) {
              tempCurrentSectionQuestions.push(question);
            }
          }
          state.currentSectionQuestions = [...tempCurrentSectionQuestions];
          state.currentQuestion =
            tempCurrentSectionQuestions.length > 0
              ? {...tempCurrentSectionQuestions[0]}
              : {};
          break;
      }

      if (tempCurrentSectionQuestions.length == 0) {
        state.currentSectionTitle = 'Overview';
      }

      return {...state};
    case SET_STUDENT_REPORT_CURRENT_SECTION:
      tempCurrentSectionQuestions = [];
      state.currentSectionTitle = action.event;
      state.currentQuestionIndex = 0;
      state.partitionIndex = 0;

      switch (action.event) {
        case 'Overview':
          state.currentSection = {};
          state.currentSectionQuestions = [];
          state.currentQuestion = {};
          break;
        default:
          state.currentSection = state.practiceSections.find(
            (practice) => practice.section === action.event,
          );
          for (const question of state.practiceQuestions) {
            if (question.section === action.event) {
              tempCurrentSectionQuestions.push(question);
            }
          }
          state.currentSectionQuestions = [...tempCurrentSectionQuestions];
          state.currentQuestion =
            tempCurrentSectionQuestions.length > 0
              ? {...tempCurrentSectionQuestions[0]}
              : {};
          break;
      }

      return {...state};
    case SET_STUDENT_REPORT_CURRENT_QUESTION_NUMBER:
      state.currentQuestionIndex = action.questionIndex;
      state.currentQuestion =
        state.currentSectionQuestions[action.questionIndex];

      return {...state};
    case SET_STUDENT_REPORT_CURRENT_ANSWER_FILTER:
      state.currentAnswerFilter = action.payload;

      if (action.payload.key === FILTER_KEYS.CORRECTNESS) {
        if (action.payload.value.toLowerCase() === 'correct') {
          state.filteredQuestions = state.currentSectionQuestions.filter(
            (question) => {
              return question.selectedOption == question.correctLetter;
            },
          );
        } else if (action.payload.value.toLowerCase() === 'incorrect') {
          state.filteredQuestions = state.currentSectionQuestions.filter(
            (question) => {
              return (
                question.selectedOption != question.correctLetter ||
                question.selectedOption == ''
              );
            },
          );
        } else if (action.payload.value.toLowerCase() === 'omit') {
          state.filteredQuestions = state.currentSectionQuestions.filter(
            (question) => {
              return question.selectedOption == '';
            },
          );
        }
      }

      if (action.payload.key === FILTER_KEYS.DIFFICULTY) {
        state.filteredQuestions = state.currentSectionQuestions.filter(
          (question) => {
            return (
              mapDifficultyString(question.difficulty).toLowerCase() ==
              action.payload.value.toLowerCase()
            );
          },
        );
      }

      if (action.payload.key === FILTER_KEYS.QUESTION_TYPE) {
        state.filteredQuestions = state.currentSectionQuestions.filter(
          (question) => {
            return (
              (question.questionType || question.topic).toLowerCase() ==
              action.payload.value.toLowerCase()
            );
          },
        );
      }

      if (action.payload.key === FILTER_KEYS.NONE)
        state.filteredQuestions = state.currentSectionQuestions;

      state.filteredQuestions.forEach((filteredQuestion) => {
        filteredQuestion.index = state.currentSectionQuestions.findIndex(
          (currentQuestion) => {
            return currentQuestion.baseId == filteredQuestion.baseId;
          },
        );
      });

      return {
        ...state,
      };
    default:
      return state;
  }
};

export default studentReducer;
