/* eslint-disable no-unused-vars */
import React from 'react';
import {alpha, Box, Button, Typography, TextField} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import AppGridContainer from '@crema/core/AppGridContainer';
import Grid from '@mui/material/Grid';
import IntlMessages from '@crema/utility/IntlMessages';
import {useDropzone} from 'react-dropzone';
import {Form} from 'formik';
import PropTypes from 'prop-types';
import AppTextField from '@crema/core/AppFormComponents/AppTextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';

import EditIcon from '@mui/icons-material/Edit';
import {styled} from '@mui/material/styles';
import {Fonts} from 'shared/constants/AppEnums';

const AvatarViewWrapper = styled('div')(({theme}) => {
  return {
    position: 'relative',
    cursor: 'pointer',
    '& .edit-icon': {
      position: 'absolute',
      bottom: 0,
      right: 0,
      zIndex: 1,
      border: `solid 2px ${theme.palette.background.paper}`,
      backgroundColor: alpha(theme.palette.primary.main, 0.7),
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
      width: 26,
      height: 26,
      display: 'none',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'all 0.4s ease',
      cursor: 'pointer',
      '& .MuiSvgIcon-root': {
        fontSize: 16,
      },
    },
    '&.dropzone': {
      outline: 0,
      '&:hover .edit-icon, &:focus .edit-icon': {
        display: 'flex',
      },
    },
  };
});

const PersonalInfoForm = ({values, setFieldValue, errors}) => {
  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setFieldValue('photoURL', URL.createObjectURL(acceptedFiles[0]));
    },
  });

  const onChangeCheckAlphaNumeric = (e) => {
    const regex = /^[a-zA-Z0-9 ]+$/;
    if (regex.test(e.target.value) || e.target.value === '') {
      setFieldValue(e.target.name, e.target.value);
    }
  };

  return (
    <Form noValidate autoComplete='off'>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: {xs: 5, lg: 6},
        }}
      >
        <AvatarViewWrapper {...getRootProps({className: 'dropzone'})}>
          <input {...getInputProps()} />
          <label htmlFor='icon-button-file'>
            <Avatar
              sx={{
                width: {xs: 50, lg: 64},
                height: {xs: 50, lg: 64},
                cursor: 'pointer',
              }}
              src={values.photoURL}
            />
            <Box className='edit-icon'>
              <EditIcon />
            </Box>
          </label>
        </AvatarViewWrapper>
        <Box
          sx={{
            ml: 4,
          }}
        >
          <Typography
            sx={{
              fontWeight: Fonts.MEDIUM,
            }}
          >
            {values.displayName}
          </Typography>
          <Typography
            sx={{
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            {values.email}
          </Typography>
        </Box>
      </Box>
      <AppGridContainer spacing={4} mb={5}>
        <Grid item xs={12} md={12}>
          <FormControl
            variant='outlined'
            sx={{
              width: '100%',
            }}
          >
            <InputLabel id='select-footer'>Gender</InputLabel>
            <Select
              sx={{
                '& .MuiOutlinedInput-input': {
                  padding: '12px 32px 12px 14px',
                },
              }}
              labelId='select-footer'
              label='Footer Type'
              value={values.gender}
              onChange={(e) => {
                setFieldValue('gender', e.target.value);
              }}
            >
              <MenuItem value={'MALE'}>MALE</MenuItem>
              <MenuItem value={'FEMALE'}>FEMALE</MenuItem>
            </Select>
          </FormControl>
          {errors.gender && <div style={{color: 'red'}}>{errors.gender}</div>}
        </Grid>
        <Grid item xs={12} md={12}>
          {/* <AppTextField
            name='grade'
            fullWidth
            label={<IntlMessages id='common.grade' />}
          /> */}

          <FormControl
            variant='outlined'
            sx={{
              width: '100%',
            }}
          >
            <InputLabel id='common.grade'>Grade</InputLabel>
            <Select
              sx={{
                '& .MuiOutlinedInput-input': {
                  padding: '12px 32px 12px 14px',
                },
              }}
              labelId='common.grade'
              label='Footer Type'
              value={values.grade}
              onChange={(e) => {
                setFieldValue('grade', e.target.value);
              }}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={7}>7</MenuItem>
              <MenuItem value={8}>8</MenuItem>
              <MenuItem value={9}>9</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={11}>11</MenuItem>
            </Select>
          </FormControl>
          {errors.grade && <div style={{color: 'red'}}>{errors.grade}</div>}
        </Grid>
      </AppGridContainer>
      <AppGridContainer spacing={4}>
        <Grid item xs={12} md={6}>
          <TextField
            name='firstName'
            fullWidth
            label={<IntlMessages id='common.firstName' />}
            value={values.firstName}
            onChange={(event) => onChangeCheckAlphaNumeric(event)}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            name='lastName'
            fullWidth
            label={<IntlMessages id='common.lastName' />}
            value={values.lastName}
            onChange={(event) => onChangeCheckAlphaNumeric(event)}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <TextField
            name='displayName'
            fullWidth
            label={<IntlMessages id='common.displayName' />}
            value={values.displayName}
            onChange={(event) => onChangeCheckAlphaNumeric(event)}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <AppTextField
            name='email'
            disabled
            fullWidth
            label={<IntlMessages id='common.email' />}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <AppTextField
            name='phoneNumber'
            fullWidth
            label={<IntlMessages id='common.phoneNumber' />}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              sx={{
                position: 'relative',
                minWidth: 100,
              }}
              color='primary'
              variant='contained'
              type='submit'
            >
              <IntlMessages id='common.saveChanges' />
            </Button>
            {/* <Button
              sx={{
                position: 'relative',
                minWidth: 100,
                ml: 2.5,
              }}
              color='primary'
              variant='outlined'
              type='cancel'
            >
              <IntlMessages id='common.cancel' />
            </Button> */}
          </Box>
        </Grid>
      </AppGridContainer>
    </Form>
  );
};

export default PersonalInfoForm;
PersonalInfoForm.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.object,
};
