/* eslint-disable no-unused-vars */
import {useFirestoreConnect} from 'react-redux-firebase';
import {db} from '../@crema/services/auth/firebase/firebase';
import {serverTimestamp} from 'firebase/firestore';

const COLLECTION = {
  POPUP: 'popup',
  ORDERLOGGING: 'order-logging',
  USERS: 'users',
};

const orderLoggingRef = db.collection(COLLECTION.ORDERLOGGING);
const usersRef = db.collection(COLLECTION.USERS);

export const utilCollection = {
  getPopupEmergencyDataAndListener: () => {
    useFirestoreConnect([
      {
        collection: COLLECTION.POPUP,
        storeAs: COLLECTION.POPUP,
        where: [
          ['enable', '==', 'yes'],
          ['emergency', '==', 'yes'],
        ],
      },
    ]);
  },
};

export const writeOrderProcessLog = async (
  userId,
  log,
  type = '',
  contents = '',
) => {
  const userInfo = (await usersRef.doc(userId).get()).data();

  try {
    orderLoggingRef.add({
      userId: userId,
      userEmail: userInfo.email,
      userInfo: userInfo,
      log: log,
      type: type,
      contents: contents,
      datetime: serverTimestamp(),
    });
  } catch (e) {
    console.log(e);
  }
};
