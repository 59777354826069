import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
  auth,
  createUserWithEmailAndPassword,
  facebookAuthProvider,
  githubAuthProvider,
  googleAuthProvider,
  onAuthStateChanged,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  twitterAuthProvider,
  updateProfile,
  db,
} from './firebase';
import {useDispatch} from 'react-redux';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
} from 'shared/constants/ActionTypes';

import {doc, getDoc, writeBatch} from 'firebase/firestore';
//import {putFreeTestToUser} from '../../../../services/testsCollections';
import {sendEmailHandler} from 'services/sendEmailHandler';

const FirebaseContext = createContext();
const FirebaseActionsContext = createContext();

export const useFirebase = () => useContext(FirebaseContext);
export const useFirebaseActions = () => useContext(FirebaseActionsContext);

const FirebaseAuthProvider = ({children}) => {
  const [firebaseData, setFirebaseData] = useState({
    user: undefined,
    isLoading: true,
    isAuthenticated: false,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    const getAuthUser = onAuthStateChanged(
      auth,
      (user) => {
        setFirebaseData({
          user: user,
          isAuthenticated: Boolean(user),
          isLoading: false,
        });
      },
      () => {
        setFirebaseData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
      },
      (completed) => {
        setFirebaseData({
          user: undefined,
          isLoading: false,
          isAuthenticated: completed,
        });
      },
    );

    return () => {
      getAuthUser();
    };
  }, []);

  const getProvider = (providerName) => {
    switch (providerName) {
      case 'google': {
        return googleAuthProvider;
      }
      case 'facebook': {
        return facebookAuthProvider;
      }
      case 'twitter': {
        return twitterAuthProvider;
      }
      case 'github': {
        return githubAuthProvider;
      }
      default:
        return googleAuthProvider;
    }
  };

  const createUserProfile = async (user) => {
    // TODO check uid at users
    /**
     * if uid exists then set new
     * else update to exists with display name or ...
     */

    const userDocRef = await doc(db, 'users', user.uid);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      const userDocData = userDocSnap.data();
      if (userDocData.providerId !== user.providerData[0].providerId) {
        // Get a new write batch
        const batch = writeBatch(db);

        const updateData = {
          displayName: user.displayName ? user.displayName : '',
          providerId: user.providerData ? user.providerData[0].providerId : '',
        };
        batch.update(userDocRef, updateData);
        const history = {
          discription:
            'Changed providerId ' +
            userDocData.providerId +
            ' to ' +
            user.providerData[0].providerId,
          createdAt: new Date(),
        };

        batch.set(
          db.collection('users').doc(user.uid).collection('history').doc(),
          history,
        );
        await batch.commit();
      }
    } else {
      await db
        .collection('users')
        .doc(user.uid)
        .set({
          firstName: user.firstName ? user.firstName : '',
          lastName: user.lastName ? user.lastName : '',
          phoneNumber: user.phoneNumber ? user.phoneNumber : '',
          email: user.email,
          displayName: user.displayName ? user.displayName : '',
          initials: '',
          userType: 'MEMBER',
          providerId: user.providerData
            ? user.providerData[0].providerId
            : 'firebase',
          createdAt: new Date(),
        });

      const messageData = {
        from: 'no-reply <no-reply@bestssatprep.com>',
        to: user.email,
        subject: 'Welcome to BEST SSAT PREP',
        html: '<h1>Thank you for signing up Email from BEST SSAT PREP!</h1>',
        template: 'welcome-email-template',
        'h:X-Mailgun-Variables': {test: 'Thank you for signing up.'},
      };

      if (user.email) {
        const response = await sendEmailHandler(messageData);
        if (response.status == 200) {
          console.log(response.data.result);
        } else {
          console.log(response.data.result);
        }
      }

      // TODO ASSIGN SAMPLE TEST
      //await putFreeTestToUser(user.uid);
    }
  };

  const logInWithPopup = async (providerName) => {
    dispatch({type: FETCH_START});
    try {
      const {user} = await signInWithPopup(auth, getProvider(providerName));

      await createUserProfile(user);

      setFirebaseData({
        user,
        isAuthenticated: true,
        isLoading: false,
      });
      dispatch({type: FETCH_SUCCESS});
    } catch (error) {
      setFirebaseData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };

  const logInWithEmailAndPassword = async ({email, password}) => {
    dispatch({type: FETCH_START});
    try {
      const {user} = await signInWithEmailAndPassword(auth, email, password);
      setFirebaseData({user, isAuthenticated: true, isLoading: false});
      dispatch({type: FETCH_SUCCESS});
    } catch (error) {
      setFirebaseData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };
  const registerUserWithEmailAndPassword = async ({name, email, password}) => {
    dispatch({type: FETCH_START});
    try {
      const {user} = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );
      await sendEmailVerification(auth.currentUser, {
        url: window.location.href,
        handleCodeInApp: true,
      });
      const userData = {
        user: {...user, displayName: name},
      };
      await createUserProfile(userData.user);

      await updateProfile(auth.currentUser, {
        displayName: name,
      });

      setFirebaseData({
        user: {...user, displayName: name},
        isAuthenticated: true,
        isLoading: false,
      });
      dispatch({type: FETCH_SUCCESS});
    } catch (error) {
      setFirebaseData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };

  const logout = async () => {
    setFirebaseData({...firebaseData, isLoading: true});
    try {
      await signOut(auth);
      setFirebaseData({
        user: null,
        isLoading: false,
        isAuthenticated: false,
      });
    } catch (error) {
      setFirebaseData({
        user: null,
        isLoading: false,
        isAuthenticated: false,
      });
    }
  };

  return (
    <FirebaseContext.Provider
      value={{
        ...firebaseData,
      }}
    >
      <FirebaseActionsContext.Provider
        value={{
          logInWithEmailAndPassword,
          registerUserWithEmailAndPassword,
          logInWithPopup,
          logout,
        }}
      >
        {children}
      </FirebaseActionsContext.Provider>
    </FirebaseContext.Provider>
  );
};
export default FirebaseAuthProvider;

FirebaseAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
