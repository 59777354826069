/* eslint-disable no-unused-vars */
import React from 'react';
import {useAuthUser} from '@crema/utility/AuthHooks';
import {Formik} from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import {Box, Typography, Button} from '@mui/material';
import {useSelector, useDispatch} from 'react-redux';
import {isLoaded} from 'react-redux-firebase';
import IntlMessages from '@crema/utility/IntlMessages';
import {Fonts} from 'shared/constants/AppEnums';
import AppLoader from '@crema/core/AppLoader';
import {usersCollection} from '../../../../services/usersCollection';
import AppInfoView from '@crema/core/AppInfoView';
import {showMessage} from 'redux/actions';
import BillingInformationForm from './BillingInformationForm';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = yup.object({
  // email: yup.string().email('Invalid email format').required('Required'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  address: yup.string().required('Address is required'),
  city: yup.string().required('City is required'),
  country: yup.string().required('Country is required'),
  province: yup.string().required('Province is required'),
  zipCode: yup.string().required('ZIPCODE is required'),
  // phoneNumber: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
});

const BillingInformation = ({isDialogOpen, handleClose}) => {
  const dispatch = useDispatch();
  const {user} = useAuthUser();
  const profile = useSelector((state) => state.firebase.profile);

  if (!isLoaded(profile)) {
    return <AppLoader />;
  }

  const saveProfileBillingDetails = async (data) => {
    if (data.country == '' || data.province == '') {
      dispatch(showMessage('Please, Enter all informations.'));
      return;
    }

    try {
      await usersCollection.updateUserBillingDetails(user.uid, data);
      dispatch(showMessage('Success to update.'));
      if (isDialogOpen) {
        handleClose();
      }
    } catch (e) {
      throw new Error('Error to update billing details');
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        maxWidth: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          component='h3'
          sx={{
            fontSize: 16,
            fontWeight: Fonts.BOLD,
            mb: {xs: 3, lg: 5},
          }}
        >
          <IntlMessages id='common.billingInformation' />
        </Typography>

        {isDialogOpen && (
          <IconButton
            aria-label='close'
            color='inherit'
            size='large'
            onClick={() => {
              handleClose();
            }}
          >
            <CloseIcon fontSize='inherit' />
          </IconButton>
        )}
      </Box>

      <Formik
        validateOnBlur={true}
        initialValues={{
          ...profile.billingDetails,
        }}
        validationSchema={validationSchema}
        onSubmit={async (data, {setSubmitting}) => {
          setSubmitting(true);
          await saveProfileBillingDetails(data);
          setSubmitting(false);
        }}
      >
        {({values, setFieldValue, errors}) => {
          return (
            <BillingInformationForm
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
            />
          );
        }}
      </Formik>
      <AppInfoView />
    </Box>
  );
};

BillingInformation.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.string,
  isDialogOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default BillingInformation;
