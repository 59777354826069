import React from 'react';
import {Box, Button} from '@mui/material';
import AppGridContainer from '@crema/core/AppGridContainer';
import Grid from '@mui/material/Grid';
import IntlMessages from '@crema/utility/IntlMessages';
import {Form} from 'formik';
import PropTypes from 'prop-types';
import AppTextField from '@crema/core/AppFormComponents/AppTextField';

const ReferralEmailForm = () => {
  return (
    <Form noValidate autoComplete='off'>
      <AppGridContainer spacing={4}>
        <Grid item xs={12} md={12}>
          <AppTextField
            name='referralEmail'
            value=''
            fullWidth
            label={<IntlMessages id='common.referral' />}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              sx={{
                position: 'relative',
                minWidth: 100,
              }}
              color='primary'
              variant='contained'
              type='submit'
            >
              <IntlMessages id='common.saveChanges' />
            </Button>
            {/* <Button
              sx={{
                position: 'relative',
                minWidth: 100,
                ml: 2.5,
              }}
              color='primary'
              variant='outlined'
              type='cancel'
            >
              <IntlMessages id='common.cancel' />
            </Button> */}
          </Box>
        </Grid>
      </AppGridContainer>
    </Form>
  );
};

export default ReferralEmailForm;
ReferralEmailForm.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.object,
};
