/* eslint-disable no-unused-vars */
import {
  firebase,
  auth,
  db,
  createUserWithEmailAndPassword,
} from '../@crema/services/auth/firebase/firebase';
import {serverTimestamp} from 'firebase/firestore';
import {useFirestoreConnect} from 'react-redux-firebase';
import axios from 'axios';
import {getAuth} from 'firebase/auth';
import _ from 'lodash';

const COLLECTION = {
  PRODUCTS: 'products',
  PROMOTIONS: 'promotions',
  ORDERS: 'orders',
  USERS: 'users',
  TESTS: 'tests',
};

const productsRef = db.collection(COLLECTION.PRODUCTS);
const promotionsRef = db.collection(COLLECTION.PROMOTIONS);

export const productsCollection = {
  getProductsDataAndListener: () => {
    useFirestoreConnect([
      {
        collection: COLLECTION.PRODUCTS,
        storeAs: 'products',
        orderBy: ['level'],
      },
    ]);
  },
  getActiveProducts: () => {
    useFirestoreConnect([
      {
        collection: COLLECTION.PRODUCTS,
        storeAs: 'products',
        where: [['status', '==', 'ACTIVE']],
        orderBy: ['level'],
      },
    ]);
  },
  getActivePromotions: () => {
    useFirestoreConnect([
      {
        collection: COLLECTION.PROMOTIONS,
        storeAs: 'promotions',
        where: [['isActive', '==', true]],
      },
    ]);
  },
  getMyOrderProducts: () => {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    useFirestoreConnect([
      {
        collection: COLLECTION.ORDERS,
        storeAs: 'myOrderProducts',
        where: [['user_uid', '==', currentUser.uid]],
        orderBy: ['createdAt', 'desc'],
      },
    ]);
  },
  getMyPracticeLists: () => {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    useFirestoreConnect([
      {
        collection: `${COLLECTION.USERS}/${currentUser.uid}/${COLLECTION.TESTS}`,
        storeAs: 'myPracticeLists',
      },
    ]);
  },
  addProduct: async (productData) => {
    const productDataCopy = _.cloneDeep(productData);
    productDataCopy.createdAt = serverTimestamp();
    return await productsRef.add(productDataCopy);
  },
  deleteProduct: async (itemId) => {
    return await productsRef.doc(itemId).delete();
  },
  updateProduct: async (updateData) => {
    const updateDataCopy = _.cloneDeep(updateData);
    updateDataCopy.updatedAt = serverTimestamp();
    await productsRef.doc(updateData.id).update(updateData);
  },
  getFeaturedProducts: async () => {
    useFirestoreConnect([
      {
        collection: COLLECTION.PRODUCTS,
        storeAs: 'featuredProducts',
        where: [
          ['category', '==', 'FEATURED'],
          ['status', '==', 'ACTIVE'],
        ],
        orderBy: ['level'],
      },
    ]);
  },
  getActivePromotionList: async () => {
    const promotionList = [];
    const now = firebase.firestore.Timestamp.now();

    try {
      const promotions = await promotionsRef
        .where('isActive', '==', true)
        .get();

      for (const promotion of promotions.docs) {
        const promotionData = promotion.data();
        const beginDate = new Date(promotionData.beginDate);
        const endDate = new Date(promotionData.endDate);
        endDate.setDate(endDate.getDate() + 1);

        const beginDateSeconds = Math.floor(beginDate.getTime() / 1000);
        const endDateSeconds = Math.floor(endDate.getTime() / 1000);

        if (now.seconds >= beginDateSeconds && now.seconds <= endDateSeconds) {
          promotionList.push(promotionData);
        }
      }
    } catch (err) {
      console.log(err);
    }

    return promotionList;
  },
};
