import React from 'react';
import {useAuthUser} from '@crema/utility/AuthHooks';
import {Formik} from 'formik';
import * as yup from 'yup';
import ReferralEmailForm from './ReferralEmailForm';
import PropTypes from 'prop-types';
import {Box, Typography} from '@mui/material';
import {useSelector, useDispatch} from 'react-redux';
import {isLoaded} from 'react-redux-firebase';
import IntlMessages from '@crema/utility/IntlMessages';
import AppLoader from '@crema/core/AppLoader';
import {usersCollection} from '../../../../services/usersCollection';
import AppInfoView from '@crema/core/AppInfoView';
import {showMessage, showError} from 'redux/actions';
import {Fonts} from 'shared/constants/AppEnums';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const validationSchema = yup.object({
  referralEmail: yup
    .string()
    .email('Invalid email format')
    .required('Required'),
});

const ReferralEmail = ({isDialogOpen, handleClose}) => {
  const {user} = useAuthUser();
  const profile = useSelector((state) => state.firebase.profile);
  const dispatch = useDispatch();

  if (!isLoaded(profile)) {
    return <AppLoader />;
  }

  return (
    <Box
      sx={{
        position: 'relative',
        maxWidth: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          component='h3'
          sx={{
            fontSize: 16,
            fontWeight: Fonts.BOLD,
            mb: {xs: 3, lg: 4},
          }}
        >
          <IntlMessages id='common.referral' />
        </Typography>

        {isDialogOpen && (
          <IconButton
            aria-label='close'
            color='inherit'
            size='large'
            onClick={() => {
              handleClose();
            }}
          >
            <CloseIcon fontSize='inherit' />
          </IconButton>
        )}
      </Box>
      <Formik
        validateOnBlur={true}
        initialValues={{
          ...profile,
        }}
        validationSchema={validationSchema}
        onSubmit={async (data, {setSubmitting}) => {
          setSubmitting(true);

          try {
            const returnMsg = await usersCollection.updateUserReferralEmail(
              user.uid,
              data,
              profile,
            );
            returnMsg instanceof Error
              ? dispatch(showError(returnMsg))
              : dispatch(showMessage(returnMsg));
          } catch (e) {
            console.log(e);
            dispatch(showMessage('Please, Enter all informations.'));
          }
          setSubmitting(false);
        }}
      >
        {({values, setFieldValue, errors}) => {
          return (
            <ReferralEmailForm
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
            />
          );
        }}
      </Formik>
      <AppInfoView />
    </Box>
  );
};

export default ReferralEmail;

ReferralEmail.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.string,
  isDialogOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};
