/* eslint-disable no-unused-vars */
import {
  ASSESSMENT_UPDATE_LEFTTIME,
  ASSESSMENT_UPDATE_SECTION_STATUS,
  ASSESSMENT_UPDATE_STATUS,
  ASSESSMENT_UPDATE_ANSWER,
  SET_CURRENT_TEST,
  SET_CURRENT_SECTION,
  SET_CURRENT_QUESTION,
  SET_QUESTIONS_IN_SECTION,
  SET_QUESTION_INFO,
  SET_ASSESSMENT_STATUS,
  SET_ASSESSMENT_TIMELEFT,
  MOVE_PREVIOUS_QUESTION,
  MOVE_NEXT_QUESTION,
  SET_SELECTED_OPTION,
  SET_WRITE_SECTION_SELECTED_OPTION,
  SET_WRITE_SECTION_WRITED_ESSAY,
} from 'shared/constants/ActionTypes';

const initialState = {
  currentTest: {},
  currentSection: {},
  currentQuestion: {},
  questionInfo: {},
  questions: [],
  currentQuestionIndex: 0,
  status: '',
  timeLeft: 0,
  partitionIndex: 0,
};

const assessmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case ASSESSMENT_UPDATE_LEFTTIME:
      return {
        ...state,
        cartItems: [...state.cartItems, action.payload],
      };

    case ASSESSMENT_UPDATE_STATUS:
      return {
        ...state,
        cartItems: state.cartItems.map((item, index) =>
          index === action.payload.index ? action.payload.item : item,
        ),
      };
    case ASSESSMENT_UPDATE_ANSWER:
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (item, index) => index !== action.payload.index,
        ),
      };
    case ASSESSMENT_UPDATE_SECTION_STATUS:
      return {
        ...state,
      };
    case SET_CURRENT_TEST:
      return {
        ...state,
        currentTest: action.payload,
      };
    case SET_CURRENT_SECTION:
      return {
        ...state,
        currentSection: action.payload,
        status: action.payload.status,
        timeLeft: action.payload.timeLeft,
        partitionIndex: Math.floor(
          (state.currentQuestionIndex ? 1 : state.currentQuestionIndex) / 10,
        ),
      };
    case SET_CURRENT_QUESTION:
      return {
        ...state,
        currentQuestion: action.payload,
        currentQuestionIndex: action.payload.questionNumber,
        partitionIndex: Math.floor((action.payload.questionNumber - 1) / 10),
      };
    case SET_QUESTIONS_IN_SECTION:
      return {
        ...state,
        questions: action.payload,
      };
    case SET_QUESTION_INFO:
      return {
        ...state,
        questionInfo: action.payload,
        currentQuestionIndex: action.payload.curQuestionNumber,
        currentQuestion:
          action.payload.userQuestions[action.payload.curQuestionNumber - 1],
      };
    case SET_ASSESSMENT_STATUS:
      return {
        ...state,
        status: action.payload.status,
      };
    case SET_ASSESSMENT_TIMELEFT:
      return {
        ...state,
        timeLeft: action.payload.timeLeft,
      };
    case MOVE_PREVIOUS_QUESTION:
      return {
        ...state,
        currentQuestion: state.questions[state.currentQuestionIndex - 2],
        partitionIndex: Math.floor((state.currentQuestionIndex - 2) / 10),
        currentQuestionIndex: state.currentQuestionIndex - 1,
      };
    case MOVE_NEXT_QUESTION:
      return {
        ...state,
        currentQuestion: state.questions[state.currentQuestionIndex],
        partitionIndex: Math.floor(state.currentQuestionIndex / 10),
        currentQuestionIndex: state.currentQuestionIndex + 1,
      };
    case SET_SELECTED_OPTION:
      state.currentQuestion.selectedOption = action.payload;
      state.questions[state.currentQuestionIndex - 1].selectedOption =
        action.payload;
      return {
        ...state,
      };
    case SET_WRITE_SECTION_SELECTED_OPTION:
      state.currentQuestion.selectedOption = action.payload;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default assessmentReducer;
