/* eslint-disable no-unused-vars */
import {
  SET_PRACTICE_USER_TESTS_LIST,
  SET_PRACTICE_NON_USER_TESTS_LIST,
  RESET_PRACTICE_SECTION,
  SET_PRACTICE_CURRENT_TESTID,
  SET_PRACTICE_CURRENT_SECTIONID,
  RETAKE_PRACTICE_TEST,
  MOVE_PREVIOUS_QUESTION,
  MOVE_NEXT_QUESTION,
  SET_PRACTICE_QUESTIONS,
  SET_PRACTICE_SELECTED_OPTION,
  SET_PRACTICE_QUESTION_SELECTED_OPTION,
  SET_PRACTICE_CURRENT_QUESTION,
  GET_PRACTICE_QUESTIONS,
  PRACTICE_SECTIONS,
  SET_CURRENT_PRACTICE,
  SET_OPEN_WRITING_SECTION,
  SET_OPEN_NONWRITING_SECTION,
  SET_OPEN_NEXT_SECTION,
  SET_OPEN_NEXT_SECTION_WITH_QUESTIONS,
  SET_CLOSE_FINISH_INSTRUCTION,
  SET_PRACTICE_INITIAL_SECTION,
  SET_INITIAL_PRACTICE_STATE,
  SET_OPEN_BREAK_SECTION,
  SET_OPEN_FINISH_INSTRUCTION,
} from 'shared/constants/ActionTypes';
import {practicesCollection} from 'services/practicesCollections';
import {testsCollection, setPracticeTests} from 'services/testsCollections';

const initialState = {
  userTestList: [],
  nonUserTestList: [],
  currentTestId: '',
  currentSectionId: '',
  currentSection: '',
  currentSectionIndex: 0,
  currentPractice: [],
  practiceQuestions: [],
  currentQuestionIndex: 0,
  currentQuestion: {},
  currentSectionInfo: {},
  sectionsList: [],
  partitionIndex: 0,
  sectionsTimeLimit: [0, 0, 0, 0, 0, 0, 0, 0, 0],
  sectionsNames: PRACTICE_SECTIONS,
  openWritingSection: false,
  openNonWritingSection: false,
  openBreakTimeNotice: false,
  openFinishPracticeNotice: false,
  retakeFlag: false,
};

const practiceReducer = (state = initialState, action) => {
  let tempCurrentQuestion = {};
  let tempPracticeQuestions = [];
  let nextSectionIndex = 0;
  let tempCurrentSection = {};
  let tempPractice = {};

  switch (action.type) {
    case SET_PRACTICE_USER_TESTS_LIST:
      state.userTestList = action.payload;

      return {...state};
    case SET_PRACTICE_NON_USER_TESTS_LIST:
      state.nonUserTestList = action.payload;

      return {...state};
    case RESET_PRACTICE_SECTION:
      return {...state};
    case SET_PRACTICE_CURRENT_TESTID:
      state.currentTestId = action.payload;

      return {...state};
    case SET_PRACTICE_CURRENT_SECTIONID:
      state.currentSectionId = action.payload;

      return {...state};
    case RETAKE_PRACTICE_TEST:
      // testsCollection.resetTest(action.payload).then(() => {
      //   setPracticeTests().then((data) => {
      //     //state.userTestList = [];
      //     //state.nonUserTestList = [];
      //     state.userTestList = data.userTests;
      //     //state.nonUserTestList.push(data.nonUserTests);
      //   });
      // });
      state.userTestList = action.payload;
      return {...state, retakeFlag: !state.retakeFlag};
    case MOVE_PREVIOUS_QUESTION:
      return {
        ...state,
        currentQuestion:
          state.practiceQuestions[state.currentQuestionIndex - 1],
        partitionIndex: Math.floor((state.currentQuestionIndex - 1) / 10),
        currentQuestionIndex: state.currentQuestionIndex - 1,
      };
    case MOVE_NEXT_QUESTION:
      return {
        ...state,
        currentQuestion:
          state.practiceQuestions[state.currentQuestionIndex + 1],
        partitionIndex: Math.floor((state.currentQuestionIndex + 1) / 10),
        currentQuestionIndex: state.currentQuestionIndex + 1,
      };
    case SET_CURRENT_PRACTICE:
      tempCurrentSection = action.sectionsList.find(
        (section) =>
          section.section === PRACTICE_SECTIONS[state.currentSectionIndex],
      );
      return {
        ...state,
        currentPractice: action.practiceData,
        currentSection: PRACTICE_SECTIONS[state.currentSectionIndex],
        sectionsList: action.sectionsList,
        currentSectionInfo: tempCurrentSection,
        sectionsTimeLimit: [
          action.practiceData.practice.writingSectionTimeSecond,
          action.practiceData.practice.writingSectionBreakTimeSecond,
          action.practiceData.practice.Q1SectionTimeSecond,
          action.practiceData.practice.Q1SectionTimeBreakSecond,
          action.practiceData.practice.readingSectionTimeSecond,
          action.practiceData.practice.readingSectionBreakTimeSecond,
          action.practiceData.practice.verbalSectionTimeSecond,
          action.practiceData.practice.verbalSectionBreakTimeSecond,
          action.practiceData.practice.Q2SectionTimeSecond,
        ],
      };
    case SET_PRACTICE_QUESTIONS:
      return {
        ...state,
        currentSection: PRACTICE_SECTIONS[state.currentSectionIndex],
        practiceQuestions: action.practiceData,
        currentQuestion: action.practiceData[0],
        currentQuestionIndex: 0,
      };
    case SET_PRACTICE_SELECTED_OPTION:
      tempPracticeQuestions = [];
      tempCurrentQuestion = {};

      tempCurrentQuestion = {...state.currentQuestion};
      tempCurrentQuestion.selectedOption = action.payload;
      tempPracticeQuestions = [...state.practiceQuestions];
      tempPracticeQuestions[state.currentQuestionIndex] = tempCurrentQuestion;

      practicesCollection.setSelectedOption(
        state.currentQuestion,
        action.payload,
      );

      return {
        ...state,
        practiceQuestions: tempPracticeQuestions,
        currentQuestion: tempCurrentQuestion,
      };
    case SET_PRACTICE_QUESTION_SELECTED_OPTION:
      tempPracticeQuestions = [];
      tempCurrentQuestion = {};
      tempCurrentQuestion = {
        ...state.practiceQuestions[action.questionNumber - 1],
      };
      tempCurrentQuestion.selectedOption = action.payload;
      tempPracticeQuestions = [...state.practiceQuestions];
      tempPracticeQuestions[action.questionNumber - 1] = tempCurrentQuestion;

      practicesCollection.setSelectedOption(
        tempCurrentQuestion,
        action.payload,
      );

      return {
        ...state,
        practiceQuestions: tempPracticeQuestions,
        currentQuestion: tempCurrentQuestion,
        currentQuestionIndex: action.questionNumber - 1,
        partitionIndex: Math.floor((action.questionNumber - 1) / 10),
      };
    case SET_PRACTICE_CURRENT_QUESTION:
      tempCurrentQuestion = {};
      tempCurrentQuestion = {...state.practiceQuestions[action.payload - 1]};

      return {
        ...state,
        currentQuestion: tempCurrentQuestion,
        currentQuestionIndex: action.payload - 1,
        partitionIndex: Math.floor((action.payload - 1) / 10),
      };
    case GET_PRACTICE_QUESTIONS:
      return {...state};
    case SET_OPEN_WRITING_SECTION:
      return {
        ...state,
        openWritingSection: true,
      };
    case SET_OPEN_NONWRITING_SECTION:
      return {...state, openWritingSection: false, openNonWritingSection: true};
    case SET_OPEN_NEXT_SECTION:
      nextSectionIndex =
        state.currentSectionIndex +
        (state.sectionsTimeLimit[state.currentSectionIndex + 1] == 0 ? 2 : 1);
      tempCurrentSection = state.sectionsList.find(
        (section) => section.section === PRACTICE_SECTIONS[nextSectionIndex],
      );

      return {
        ...state,
        currentSectionIndex: nextSectionIndex,
        currentSection: PRACTICE_SECTIONS[nextSectionIndex],
        currentSectionInfo: tempCurrentSection,
        currentQuestionIndex: 0,
        partitionIndex: 0,
        openWritingSection: PRACTICE_SECTIONS[nextSectionIndex] === 'Writing',
        openNonWritingSection:
          PRACTICE_SECTIONS[nextSectionIndex] !== 'Writing' &&
          !PRACTICE_SECTIONS[nextSectionIndex].includes('Break') &&
          !PRACTICE_SECTIONS[nextSectionIndex].includes('Finish') &&
          PRACTICE_SECTIONS.length >= nextSectionIndex,
        openBreakTimeNotice:
          PRACTICE_SECTIONS[nextSectionIndex].includes('Break'),
        openFinishPracticeNotice:
          PRACTICE_SECTIONS[nextSectionIndex].includes('Finish'),
      };
    case SET_OPEN_NEXT_SECTION_WITH_QUESTIONS:
      nextSectionIndex = state.currentSectionIndex + 1;
      tempCurrentSection = state.sectionsList.find(
        (section) => section.section === PRACTICE_SECTIONS[nextSectionIndex],
      );

      return {
        ...state,
        currentSectionIndex: nextSectionIndex,
        currentSection: PRACTICE_SECTIONS[nextSectionIndex],
        currentSectionInfo: tempCurrentSection,
        practiceQuestions: action.questions,
        currentQuestion: action.questions[0],
        currentQuestionIndex: 0,
        partitionIndex: 0,
        openWritingSection: PRACTICE_SECTIONS[nextSectionIndex] === 'Writing',
        openNonWritingSection:
          PRACTICE_SECTIONS[nextSectionIndex] !== 'Writing' &&
          !PRACTICE_SECTIONS[nextSectionIndex].includes('Break') &&
          !PRACTICE_SECTIONS[nextSectionIndex].includes('Finish') &&
          PRACTICE_SECTIONS.length >= nextSectionIndex,
        openBreakTimeNotice:
          PRACTICE_SECTIONS[nextSectionIndex].includes('Break'),
        openFinishPracticeNotice:
          PRACTICE_SECTIONS[nextSectionIndex].includes('Finish'),
      };
    case SET_CLOSE_FINISH_INSTRUCTION:
      return {
        ...state,
        openFinishPracticeNotice: false,
      };
    case SET_PRACTICE_INITIAL_SECTION:
      tempPractice = {...state.currentPractice};
      state.currentSectionId = action.openSectionData.baseId;
      state.currentSection = action.openSectionData.sectionName;
      state.currentSectionIndex = PRACTICE_SECTIONS.indexOf(
        action.openSectionData.sectionName,
      );
      state.practiceQuestions = [...action.questionsData];
      state.currentQuestionIndex = 0;
      state.currentQuestion = action.questionsData[0];
      state.currentSectionInfo = action.openSectionData.currentSection;
      if (action.openSectionData.isChangeStatus) {
        tempPractice.status = action.openSectionData.status;
        tempPractice.statusDatetime = action.openSectionData.statusDatetime;
      }

      return {
        ...state,
        currentPractice: tempPractice,
      };
    case SET_INITIAL_PRACTICE_STATE:
      state.userTestList = [];
      state.nonUserTestList = [];
      state.currentTestId = '';
      state.currentSectionId = '';
      state.currentSection = '';
      state.currentSectionIndex = 0;
      state.currentPractice = [];
      state.practiceQuestions = [];
      state.currentQuestionIndex = 0;
      state.currentQuestion = {};
      state.currentSectionInfo = {};
      state.sectionsList = [];
      state.partitionIndex = 0;
      state.sectionsTimeLimit = [0, 0, 0, 0, 0, 0, 0, 0, 0];
      state.sectionsNames = [...PRACTICE_SECTIONS];
      state.openWritingSection = false;
      state.openNonWritingSection = false;
      state.openBreakTimeNotice = false;
      state.openFinishPracticeNotice = false;

      return state;
    case SET_OPEN_BREAK_SECTION:
      return {
        ...state,
        openWritingSection: false,
        openNonWritingSection: false,
        openBreakTimeNotice: true,
        openFinishPracticeNotice: false,
        currentQuestionIndex: 0,
        partitionIndex: 0,
        practiceQuestions: [],
        currentQuestion: {},
        currentSectionInfo: {},
        currentSection: action.sectionData.sectionName,
        currentSectionIndex: PRACTICE_SECTIONS.indexOf(
          action.sectionData.sectionName,
        ),
      };
    case SET_OPEN_FINISH_INSTRUCTION:
      practicesCollection.setFinishedPractices(state.currentPractice);
      return {
        ...state,
        openFinishPracticeNotice: true,
      };
    default:
      return state;
  }
};

export default practiceReducer;
