/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import {Box, Button, styled} from '@mui/material';

import AppGridContainer from '@crema/core/AppGridContainer';
import Grid from '@mui/material/Grid';

import IntlMessages from '@crema/utility/IntlMessages';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from 'react-country-region-selector';

import {useSelector, useDispatch} from 'react-redux';
import {usersCollection} from '../../../../services/usersCollection';
import {getAuth} from 'firebase/auth';
import AppInfoView from '@crema/core/AppInfoView';

import {showMessage, fetchError} from 'redux/actions';
import {Form} from 'formik';
import AppTextField from '@crema/core/AppFormComponents/AppTextField';
import AppSelectField from '@crema/core/AppFormComponents/AppSelectField';
import './BillingDetailsStyle.css';
import Alert from '@mui/material/Alert';

const BillingInformationForm = ({values, setFieldValue, errors}) => {
  return (
    <Form noValidate autoComplete='off'>
      {errors && JSON.stringify(errors) !== '{}' && (
        <Box mb={5}>
          <Alert severity='error'>{JSON.stringify(errors)}</Alert>
        </Box>
      )}

      <AppGridContainer spacing={4}>
        <Grid item xs={12} md={6}>
          <AppTextField
            name='firstName'
            fullWidth
            label={<IntlMessages id='common.firstName' />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AppTextField
            name='lastName'
            fullWidth
            label={<IntlMessages id='common.lastName' />}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <AppTextField
            name='address'
            fullWidth
            label={<IntlMessages id='common.address' />}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <AppTextField
            name='address2'
            fullWidth
            label={<IntlMessages id='common.address2' />}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <AppTextField
            name='city'
            fullWidth
            label={<IntlMessages id='common.city' />}
          />
        </Grid>
      </AppGridContainer>
      <Box mt={4}>
        <CountryDropdown
          className='selectBoxStyle'
          value={values.country}
          name='country'
          onChange={(value) => {
            setFieldValue('country', value);
          }}
        />
        {errors.country && <div style={{color: 'red'}}> {errors.country}</div>}
      </Box>
      <Box mt={4}>
        <RegionDropdown
          className='selectBoxStyle'
          country={values.country}
          value={values.province}
          name='province'
          onChange={(value) => {
            setFieldValue('province', value);
          }}
        />
        {errors.province && <div style={{color: 'red'}}>{errors.province}</div>}
      </Box>
      <Box mt={4}>
        <AppTextField
          name='zipCode'
          fullWidth
          label={<IntlMessages id='common.zipCode' />}
        />
      </Box>

      <AppGridContainer spacing={4}>
        <Grid item xs={12} md={12}>
          <Box
            mt={5}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              sx={{
                position: 'relative',
                minWidth: 100,
              }}
              color='primary'
              variant='contained'
              type='submit'
            >
              <IntlMessages id='common.saveChanges' />
            </Button>
            {/* <Button
            sx={{
              position: 'relative',
              minWidth: 100,
              ml: 2.5,
            }}
            color='primary'
            variant='outlined'
            type='cancel'
          >
            <IntlMessages id='common.cancel' />
          </Button> */}
          </Box>
        </Grid>
      </AppGridContainer>
      <AppInfoView />
    </Form>
  );
};

BillingInformationForm.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.object,
};

export default BillingInformationForm;
